import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { saveInitialQuestions } from "Api";
import { ApplicantContext } from "contexts/contexts";
import { sanitizeData } from "lib/Utils";
import { useMutation, useQueryClient } from "react-query";
import getTaskConfig from "taskConfigs/TaskConfigs";
import { Button } from "./Button";
import { CenterModal } from "./CenterModal";
import LoadingSpinner from "./LoadingSpinner";
import { QuestionnaireSection } from "./QuestionnaireSection";

export default function InitialQuestionsModal({
  isOpen,
  setIsOpen,
  application,
  formData,
}) {
  const queryClient = useQueryClient();
  const applicationId = application.application_id;
  const taskConfig = getTaskConfig(application.initial_questions_key);

  const fields = taskConfig?.sections.flatMap((section) => section.body) || [];

  const saveTaskMutation = useMutation(
    ({ data }) => saveInitialQuestions(applicationId, data),
    {
      onError: (error) => {
        // TODO: Make this a toast
        alert(
          "Error: We were unable to save the contents of this page. Please contact Path's Support Team for help:\n\nsupport@pathimmigration.ca\n1-888-817-0079"
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(["application", applicationId]);
      },
    }
  );

  const handleConfirm = async () => {
    const sanitizedData = sanitizeData(formData, fields);
    await saveTaskMutation.mutate({ data: sanitizedData });
    setIsOpen(false);
  };

  return (
    <CenterModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      width={"w-full max-w-4xl"}
    >
      {saveTaskMutation.isLoading && (
        <>
          <LoadingSpinner />
          <div className="flex justify-center">
            <p>Generating application materials...</p>
          </div>
        </>
      )}

      {!saveTaskMutation.isLoading && (
        <>
          <div className="flex justify-center">
            <div className="bg-teal-100 p-4 rounded-full">
              <MagnifyingGlassIcon className="h-12 w-12 text-teal-600" />
            </div>
          </div>

          <div className="mx-6">
            <h3 className="text-xl font-medium leading-6 text-gray-900 mt-6">
              Review your answers
            </h3>

            <p className="text-sm text-gray-500 mt-2">
              Verify the accuracy of the following information. Once submitted,
              it cannot be changed, without contacting customer support.
            </p>

            <div className="mt-12">
              <hr className="w-full h-[1px] bg-neutral-400" />
              <QuestionnaireSection
                task={null}
                taskConfig={taskConfig}
                formData={formData}
                type="bare"
                nextTask={null}
              />
            </div>

            <div className="w-full flex flex-row gap-2">
              <Button
                onClick={() => {
                  setIsOpen(false);
                }}
                style="primary-outline"
              >
                Edit
              </Button>
              <Button onClick={handleConfirm} style="primary">
                Confirm
              </Button>
            </div>
          </div>
        </>
      )}
    </CenterModal>
  );
}
