import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import { Button } from "./Button";
import { CSVLink } from "react-csv";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { getApplicantTasks, getApplication } from "Api";
import * as moment from "moment";
import { FieldConfig, Heading } from "taskConfigs/Types";
import { AdmissibilityQuestion } from "./fields/AdmissibilityQuestionField";
import { TableField } from "./fields/TableField";
import { ModalTableField } from "./fields/ModalTableField";
import { DateInput } from "./fields/DateField";
import { getChildrenForTableField } from "lib/Utils";
import { SilentErrorBoundary } from "./ErrorBoundary";
import getTaskConfig from "taskConfigs/TaskConfigs";

export function ExportQuestionnaire({ applicant }) {
  const csvLink = useRef();
  const [csvData, setCsvData] = useState([[]]);

  const handleQuestionnaireExport = async () => {
    if (csvLink && csvLink.current && csvLink.current) {
      csvLink.current.link.click();
    }
  };

  const { isLoading: isTaskLoading, data: tasks } = useQuery(
    ["tasks", applicant.id],
    () => getApplicantTasks(applicant.id)
  );
  const { isLoading: isApplicationLoading, data: application } = useQuery(
    ["initial_questions", applicant.id],
    () => getApplication(applicant.id)
  );
  const isLoading = isTaskLoading || isApplicationLoading;

  // Todo(Awais): This code is duplicated in Profile. We should clean this up.
  const combinedTasks = (application, tasks) => {
    if (application.applicant.id !== applicant.id) {
      return tasks;
    }

    const initialQuestions = {
      applicant_id: application.applicant.id,
      task_id: "T_initial-questions",
      key: application.initial_questions_key,
      title: "Initial questions",
      description: null,
      status: "COMPLETE",
      values: application.values || {},
      when_created: application.when_created,
    };
    return [initialQuestions, ...tasks];
  };

  const generateExport = () => {
    let result = [];
    result.push([`${applicant.name}'s questionnaire`]);
    result.push([`Exported at: ${moment().format("YYYY-MM-DD, HH:mm")}`]);

    // Sorry future me. This code is all super ugly for now.
    combinedTasks(application, tasks).forEach((task) => {
      const taskConfig = getTaskConfig(task.key);
      result.push([]);
      result.push([]);
      result.push([taskConfig.title?.toUpperCase()]);
      const formData = task.values;
      taskConfig.sections
        .flatMap((section) => section.body)
        .map((element) => {
          const label =
            typeof element.label === "function"
              ? element.label(formData)
              : element.label;
          const instructions =
            typeof element.instructions === "function"
              ? element.instructions(formData)
              : element.instructions;

          if (element instanceof Heading) {
            result.push([element.text?.toUpperCase()]);
          }
          if (element instanceof FieldConfig) {
            if (!element.shouldBeVisible(formData)) {
              // This is for consistency with the lawyer view. If there is data, we should show it.
              result.push([label, formData[element.name] || "Not asked"]);
            } else if (element.type === AdmissibilityQuestion) {
              let value = { value: "", details: "" };
              if (typeof formData[element.name] === "string") {
                value = { value: formData[element.name], details: "" };
              } else if (formData[element.name] instanceof Object) {
                value = formData[element.name];
              }

              result.push([label, value.value || "Not entered"]);
              if (value.value === "Yes") {
                result.push([
                  "Please provide more information",
                  value.details || "Not entered",
                ]);
              }
            } else if (element.type === DateInput) {
              result.push([
                label,
                formData[element.name]
                  ? formData[element.name].replaceAll("?", "X")
                  : "Not entered",
              ]);
            } else if (element.type === TableField) {
              const data = formData[element.name];
              if (!data || data.length == 0) {
                result.push([label, "Not entered"]);
              } else {
                let headings = getChildrenForTableField(element).map(
                  (child) => {
                    return child.props.label;
                  }
                );
                result.push([label].concat(headings));
                data.forEach((row) => {
                  let rowValues = [];
                  getChildrenForTableField(element).forEach((child) => {
                    rowValues.push(row[child.props.name] || "Not entered");
                  });
                  result.push([""].concat(rowValues));
                });
                result.push([]);
              }
            } else if (element.type === ModalTableField) {
              const data = formData[element.name];
              if (!data || data.length == 0) {
                result.push([label, "Not entered"]);
              } else {
                let headings = element.fields
                  .filter((field) => {
                    return field instanceof FieldConfig;
                  })
                  .map((field) => {
                    return field.label;
                  });
                result.push([label].concat(headings));
                data.forEach((row) => {
                  let rowValues = [];
                  element.fields.forEach((field) => {
                    if (field instanceof FieldConfig) {
                      if (!field.shouldBeVisible(row)) {
                        rowValues.push("Not asked");
                      } else {
                        const value = row[field.name]
                          ? field.type === DateInput
                            ? row[field.name].replaceAll("?", "X")
                            : row[field.name]
                          : "Not entered";
                        rowValues.push(value);
                      }
                    }
                  });
                  result.push([""].concat(rowValues));
                });
                result.push([]);
              }
            } else {
              result.push([label, formData[element.name] || "Not entered"]);
            }
          }
        });
    });

    return result;
  };

  useEffect(() => {
    if (!tasks || !application) {
      return;
    }
    const csvData = generateExport();
    setCsvData(csvData);
  }, [tasks, application]);

  return (
    <SilentErrorBoundary>
      {!isLoading && tasks && application && (
        <>
          <Button fullWidth={false} onClick={handleQuestionnaireExport}>
            <ArrowDownTrayIcon className="h-5 h-5" />
            <div className="text-sm">Download questionnaire</div>
          </Button>

          <CSVLink
            data={csvData}
            filename={`${applicant.name} Questionnaire Export.csv`}
            className="hidden"
            ref={csvLink}
            target="_blank"
          />
        </>
      )}
    </SilentErrorBoundary>
  );
}
