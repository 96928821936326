import { AdmissibilityQuestion } from "components/fields/AdmissibilityQuestionField";
import { FieldConfig, TaskConfig, SectionConfig } from "../Types";

export const portOfEntryPrimaryAdmissibilityConfig = new TaskConfig({
  title: "Admissibility",
  description:
    "These questions help us identify issues that may impact your ability to work in Canada.",
  sections: [
    new SectionConfig({
      title: "Admissibility",
      body: [
        new FieldConfig({
          type: AdmissibilityQuestion,
          name: "has_tuberculosis",
          label:
            "Within the past two years have you or a family member ever had tuberculosis or been in close contact with a person with tuberculosis?",
        }),
        new FieldConfig({
          type: AdmissibilityQuestion,
          name: "has_health_status",
          label:
            "Have you or an accompanying family member had any physical or mental condition for which that person required social and/or health services during the last five years (e.g. cancer, autism spectrum disorder, HIV, etc.)?",
        }),
        new FieldConfig({
          type: AdmissibilityQuestion,
          name: "has_overstayed",
          label:
            "Have you or any family member ever remained beyond the validity of your status, attended school without authorization or worked without authorization in Canada?",
        }),
        new FieldConfig({
          type: AdmissibilityQuestion,
          name: "has_been_refused_visa_to_canada",
          label:
            "Have you ever been refused a visa or permit, denied entry or been ordered to leave Canada (even if you were subsequently approved)?",
        }),
        new FieldConfig({
          type: AdmissibilityQuestion,
          name: "has_been_refused_visa",
          label:
            "Have you ever been refused a visa or permit, denied entry or been ordered to leave any other country (even if you were subsequently approved)?",
        }),
        new FieldConfig({
          type: AdmissibilityQuestion,
          name: "has_previously_applied",
          label:
            "Have you or any family member applied for any Canadian immigration status or visas previously, including work permits, study permits, visitor status and permanent residence?",
        }),
        new FieldConfig({
          type: AdmissibilityQuestion,
          name: "has_criminal_record",
          label:
            "Have you or any family member committed, been arrested or charged with any criminal offence in any country, including driving under the influence?",
        }),
        new FieldConfig({
          type: AdmissibilityQuestion,
          name: "has_served_in_military",
          label:
            "Have you or any family members served in any military, militia, or civil defence unit or serve in a security organization or police force? This includes conscription, mandatory military service, non-obligatory national service, reserve or volunteer units.",
        }),
      ],
    }),
  ],
});
