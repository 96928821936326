import { Button } from "./Button";
import { CenterModal } from "./CenterModal";
import { useNavigate } from "react-router-dom";

export function DocumentsCompleteModal({ isOpen, setIsOpen, applicantId }) {
  const navigate = useNavigate();

  const handleContinue = () => {
    navigate(`/`);
    setIsOpen(false);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <CenterModal isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="flex flex-col items-center gap-4">
          <div className="">
            <img
              src={`${process.env.PUBLIC_URL}/static/envelope_check.png`}
              className="w-36 h-36 noselect"
              draggable={false}
            />
          </div>
          <div>
            <h3 className="text-xl font-medium text-gray-900">
              Documents uploaded
            </h3>
            <p>You're all done! Your lawyer will be in touch soon.</p>
          </div>
          <Button style="primary" onClick={handleContinue}>
            Continue
          </Button>
        </div>
      </CenterModal>
    </>
  );
}
