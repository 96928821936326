export class TaskConfig {
  constructor({ title, description, sections }) {
    this.title = title;
    this.description = description;
    this.sections = sections;
  }
}

// Todo(Awais): Consider removing section configs entirely. They don't seem to add much value.
// If they were reusable components, that would be useful.
export class SectionConfig {
  constructor({ title, body }) {
    this.title = title;
    this.body = body;
  }
}

export class FieldConfig {
  constructor({
    type,
    name,
    label,
    instructions = undefined,
    width = "w-64",
    options = undefined,
    shouldBeVisible = undefined,
    tableColumns = undefined,
    itemName = undefined,
    fields = undefined,
    dateType = undefined,
    checkbox = undefined,
    children = null,
  }) {
    this.type = type;
    this.name = name;
    this.label = label;
    this.instructions = instructions;
    this.width = width;
    this.options = options;
    this.shouldBeVisible = shouldBeVisible || ((data) => true);
    this.tableColumns = tableColumns;
    this.itemName = itemName;
    this.fields = fields || [];
    this.dateType = dateType;
    this.checkbox = checkbox;
    this.children = children || <></>;
  }
}

export class Heading {
  constructor({ text, shouldBeVisible = null }) {
    this.text = text;
    this.shouldBeVisible = shouldBeVisible || ((data) => true);
  }
}

export class Text {
  constructor({ text, shouldBeVisible = null }) {
    this.text = text;
    this.shouldBeVisible = shouldBeVisible || ((data) => true);
  }
}

export class HorizontalRule {
  constructor({ shouldBeVisible = null }) {
    this.shouldBeVisible = shouldBeVisible || ((data) => true);
  }
}
