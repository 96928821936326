import { AdmissibilityQuestion } from "components/fields/AdmissibilityQuestionField";
import { FieldConfig, TaskConfig, SectionConfig } from "../Types";

export const workPermitChildAdmissibilityConfig = new TaskConfig({
  title: "Admissibility",
  description:
    "These questions help us identify issues that may impact your application.",
  sections: [
    new SectionConfig({
      title: "Admissibility",
      body: [
        new FieldConfig({
          type: AdmissibilityQuestion,
          name: "has_tuberculosis",
          label:
            "Within the past two years, have they or their family member(s) ever had tuberculosis or been in close contact with a person with tuberculosis?",
        }),
        new FieldConfig({
          type: AdmissibilityQuestion,
          name: "has_health_status",
          label:
            "Do they have any physical or mental disorder that would require social and/or health services, other than medication, during a stay in Canada?",
        }),
        new FieldConfig({
          type: AdmissibilityQuestion,
          name: "has_overstayed",
          label:
            "Have they ever remained beyond the validity of their status, attended school without authorization or worked without authorization in Canada?",
        }),
        new FieldConfig({
          type: AdmissibilityQuestion,
          name: "has_been_refused_visa_to_canada",
          label:
            "Have they ever been refused a visa or permit, denied entry or been ordered to leave Canada (even if they were subsequently approved)?",
        }),
        new FieldConfig({
          type: AdmissibilityQuestion,
          name: "has_been_refused_visa",
          label:
            "Have they ever been refused a visa or permit, denied entry or been ordered to leave any other country (even if they were subsequently approved)?",
        }),
        new FieldConfig({
          type: AdmissibilityQuestion,
          name: "has_previously_applied",
          label: "Have they previously applied to enter or remain in Canada?",
        }),
        new FieldConfig({
          type: AdmissibilityQuestion,
          name: "has_criminal_record",
          label:
            "Have they ever committed, been arrested for, been charged with or convicted of any criminal offence, including driving under the influence, in any country or territory?",
        }),
        new FieldConfig({
          type: AdmissibilityQuestion,
          name: "has_served_in_military",
          label:
            "Did they serve in any military, militia, or civil defence unit or serve in a security organization or police force? This includes conscription, mandatory military service, non-obligatory national service, reserve or volunteer units.",
        }),
        new FieldConfig({
          type: AdmissibilityQuestion,
          name: "has_political_affiliation",
          label:
            "Are they, or have they ever been, a member or associated with any political party, or other group or organization which has engaged in or advocated violence as a means to achieving a political or religious objective, or which has been associated with criminal activity at any time?",
        }),
        new FieldConfig({
          type: AdmissibilityQuestion,
          name: "has_war_crimes_history",
          label:
            "Have they ever witnessed or participated in the ill treatment of prisoners or civilians, looting or desecration of religious buildings?",
        }),
      ],
    }),
  ],
});
