import { useTabularData } from "lib/Hooks";
import { getChildrenForTableField } from "lib/Utils";
import * as React from "react";

export function TableField({
  name,
  label = null,
  defaultValue = null,
  instuctions = null,
  onChange,
  onSave,
  children,
}) {
  const childrenArray =
    children.type === React.Fragment
      ? children.props.children
      : React.Children.toArray(children);

  const columns = childrenArray.map((child) => {
    return { name: child.props.name, label: child.props.label };
  });
  const nullRow = Object.assign(
    {},
    ...columns.map((column) => ({ [column.name]: null }))
  );

  const { data, setTabularData, setTabularValue } = useTabularData(
    defaultValue || [nullRow]
  );

  React.useEffect(() => {
    setTabularData(defaultValue || [nullRow]);
  }, [defaultValue]);

  const addRow = () => {
    const result = data.concat(nullRow);
    setTabularData(result);
    onChange(name, result);
  };

  const updateRow = (index) => {
    return (rowName, value) => {
      const result = setTabularValue(index, rowName, value);
      onChange(name, result);
      onSave(name, result);
    };
  };

  const deleteRow = (index) => {
    const result = data
      .slice(0, index)
      .concat(data.slice(index + 1, data.length));
    setTabularData(result);
    onChange(name, result);
    onSave(name, result);
  };

  return (
    <div className="w-full">
      {label && childrenArray.length > 1 && (
        <p className="form-label">{label}</p>
      )}
      <table className="w-full border-b">
        <thead className="bg-neutral-300">
          <tr className="text-neutral-900 text-sm font-medium border-b">
            {columns.map((column) => {
              return (
                <td key={column.name} className="p-2">
                  <label htmlFor={column.name}>{column.label}</label>
                </td>
              );
            })}
            <td></td>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => {
            return (
              <tr
                key={`${index}.${name}`}
                className="text-gray-900 bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
              >
                {childrenArray.map((child) => {
                  return (
                    <td
                      key={`${index}.${child.props.name}`}
                      className="py-1 px-2 whitespace-nowrap"
                    >
                      {React.cloneElement(child, {
                        defaultValue: row[child.props.name],
                        label: null,
                        className: "w-full",
                        onChange: updateRow(index),
                        onSave: updateRow(index),
                      })}
                    </td>
                  );
                })}
                <td
                  className="text-sm text-neutral-500 underline hover:text-neutral-900 hover:font-medium cursor-pointer"
                  onClick={() => {
                    deleteRow(index);
                  }}
                >
                  Delete
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div
        className="text-sm inline-block mt-2 text-neutral-500 underline hover:text-neutral-900 hover:font-medium cursor-pointer"
        onClick={() => {
          addRow();
        }}
      >
        Add row
      </div>
    </div>
  );
}

export function TableFieldDisplay({
  element,
  label,
  instructions,
  value: tabularData,
}) {
  return (
    <div key={element.name} className="px-6 py-3">
      <div className=" text-gray-600 font-medium text-sm">{label}</div>

      {(!tabularData || tabularData.length == 0) && (
        <div className="mt-2 text-sm rounded-sm border-solid border border-slate-200 divide-y">
          <div className="p-2 text-sm text-neutral-500">No data entered</div>
        </div>
      )}

      {tabularData && tabularData.length > 0 && (
        <div className="mt-2 flex flex-col gap-y-4">
          {tabularData &&
            tabularData.map((row, index) => {
              return (
                <div
                  key={`${element.name}_${index}`}
                  className="text-sm rounded-sm border-solid border border-slate-200 divide-y"
                >
                  {getChildrenForTableField(element).map((child) => {
                    return (
                      <div
                        key={`${element.name}_${child.props.name}`}
                        className="flex flex-row py-2 text-sm"
                      >
                        <div className="w-1/2 px-2 text-gray-500 pr-6">
                          {child.props.label}
                        </div>
                        <div className="w-1/2 text-gray-800">
                          {row[child.props.name] || (
                            <span className="font-light text-neutral-400">
                              Not entered
                            </span>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}
