import { DateInput } from "components/fields/DateField";
import { CountryDropdown, Dropdown } from "components/fields/DropdownField";
import { ModalTableField } from "components/fields/ModalTableField";
import { Radio } from "components/fields/RadioField";
import { TextInput } from "components/fields/TextInput";
import {
  MARITAL_STATUS_OPTIONS,
  PARENT_TYPE_OPTIONS,
  RELATIONSHIP_TYPE_OPTIONS,
  SIBLING_TYPE_OPTIONS,
  toDropdownoptions,
} from "lib/Enums";
import {
  FieldConfig,
  Heading,
  TaskConfig,
  SectionConfig,
  Text,
} from "../Types";

const familyMemberFields = (relationshipOptions) => [
  new FieldConfig({
    type: TextInput,
    name: "full_name",
    label: "Full name",
    width: "w-full",
  }),
  new FieldConfig({
    type: TextInput,
    name: "full_name_in_native_script",
    label: "Full name in native script in unicode (if different than above)",
    width: "w-full",
  }),
  new FieldConfig({
    type: Dropdown,
    name: "relationship",
    label: "Relationship (to they)",
    options: toDropdownoptions(relationshipOptions),
  }),
  new FieldConfig({
    type: Dropdown,
    name: "marital_status",
    label: "Their marital status",
    options: toDropdownoptions(MARITAL_STATUS_OPTIONS),
  }),
  new FieldConfig({
    type: DateInput,
    name: "date_of_birth",
    label: "Date of birth",
  }),
  new FieldConfig({
    type: CountryDropdown,
    name: "country_of_birth",
    label: "Country of birth",
  }),
  new FieldConfig({
    type: Radio,
    name: "is_deceased",
    label: "Is this family member deceased?",
  }),
  new FieldConfig({
    type: DateInput,
    name: "date_of_death",
    label: "Date of death",
    shouldBeVisible: (data) => data.is_deceased === "Yes",
  }),
  new FieldConfig({
    type: TextInput,
    name: "city_of_death",
    label: "City / Town of death",
    shouldBeVisible: (data) => data.is_deceased === "Yes",
  }),
  new FieldConfig({
    type: CountryDropdown,
    name: "country_of_death",
    label: "Country of death",
    shouldBeVisible: (data) => data.is_deceased === "Yes",
  }),
  new FieldConfig({
    type: TextInput,
    name: "occupation",
    label: "Occupation",
    width: "w-96",
    shouldBeVisible: (data) => data.is_deceased === "No",
  }),
  new Heading({
    text: "Current address",
    shouldBeVisible: (data) => data.is_deceased === "No",
  }),
  new FieldConfig({
    type: TextInput,
    name: "street_address",
    label: "Street address",
    width: "w-96",
    shouldBeVisible: (data) => data.is_deceased === "No",
  }),
  new FieldConfig({
    type: TextInput,
    name: "apt_number",
    label: "Apartment / Unit number (if applicable)",
    width: "w-48",
    shouldBeVisible: (data) => data.is_deceased === "No",
  }),
  new FieldConfig({
    type: TextInput,
    name: "city",
    label: "City / Town",
    shouldBeVisible: (data) => data.is_deceased === "No",
  }),
  new FieldConfig({
    type: TextInput,
    name: "region",
    label: "District / Region (if applicable)",
    shouldBeVisible: (data) => data.is_deceased === "No",
  }),
  new FieldConfig({
    type: TextInput,
    name: "province",
    label: "Province / State (if applicable)",
    shouldBeVisible: (data) => data.is_deceased === "No",
  }),
  new FieldConfig({
    type: CountryDropdown,
    name: "country",
    label: "Country / Territory",
    shouldBeVisible: (data) => data.is_deceased === "No",
  }),
  new FieldConfig({
    type: TextInput,
    name: "postcode",
    label: "Postal / ZIP code (if applicable)",
    width: "w-48",
    shouldBeVisible: (data) => data.is_deceased === "No",
  }),
];

export const workPermitPartnerFamilyConfig = new TaskConfig({
  title: "Family",
  description:
    "Tell us about your partner's immediate family and any past relationships.",
  sections: [
    new SectionConfig({
      title: "Family members",
      body: [
        new Heading({ text: "Parents" }),
        new FieldConfig({
          type: ModalTableField,
          name: "parents",
          label: null,
          instructions:
            "List each of their parents, including those who have passed away.",
          itemName: "parent",
          tableColumns: ["full_name", "relationship"],
          fields: familyMemberFields(PARENT_TYPE_OPTIONS),
        }),
        new Heading({ text: "Siblings (brothers and sisters)" }),
        new FieldConfig({
          type: ModalTableField,
          name: "siblings",
          label: null,
          instructions:
            "List each of their siblings, including half-brothers and sisters and step brothers or sisters.",
          itemName: "sibling",
          tableColumns: ["full_name", "relationship"],
          fields: familyMemberFields(SIBLING_TYPE_OPTIONS),
        }),
      ],
    }),
    new SectionConfig({
      title: "Past relationships",
      body: [
        new FieldConfig({
          type: Radio,
          name: "has_past_relationships",
          label:
            "Excluding any current relationship, have they previously been married or in a common-law relationship?",
        }),
        new Text({
          text: "Tell us about their past relationship(s).",
          shouldBeVisible: (data) => data.has_past_relationships === "Yes",
        }),
        new FieldConfig({
          type: ModalTableField,
          name: "past_relationships",
          label: "Details of past relationships",
          itemName: "relationship",
          shouldBeVisible: (data) => data["has_past_relationships"] === "Yes",
          tableColumns: [
            "marriage_start",
            "marriage_end",
            "full_name",
            "type_of_relationship",
          ],
          fields: [
            new FieldConfig({
              type: TextInput,
              name: "full_name",
              label: "Full name",
              width: "w-full",
            }),
            new FieldConfig({
              type: TextInput,
              name: "full_name_in_native_script",
              label:
                "Full name in native script in unicode (if different than above)",
              width: "w-full",
            }),
            new FieldConfig({
              type: Dropdown,
              name: "type_of_relationship",
              label: "Type of relationship",
              options: toDropdownoptions(RELATIONSHIP_TYPE_OPTIONS),
            }),
            new FieldConfig({
              type: DateInput,
              name: "marriage_start",
              label: "From (start date)",
            }),
            new FieldConfig({
              type: DateInput,
              name: "marriage_end",
              label: "To (end date)",
            }),
          ],
        }),
      ],
    }),
  ],
});
