import { useEffect, useState } from "react";

export function DateInput({
  name,
  label,
  defaultValue = null,
  onChange,
  onSave,
  dateType = "full", // "full" or "day-excluded", "day-optional"
  checkbox = undefined,
}) {
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  if (!["full", "day-excluded", "day-optional"].includes(dateType)) {
    console.log("ERROR: unknown dateType: ", dateType);
  }

  // Todo: add error handling
  const [errors, setErrors] = useState({});

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(
    defaultValue === checkbox?.value || false
  );

  const parseValue = (value) => {
    if (value === checkbox?.value) {
      setIsCheckboxChecked(true);
      return;
    }
    if (!value) {
      return;
    }
    const [year_part, month_part, day_part] = value.split("-");
    setYear(year_part === "????" ? "" : year_part);
    setMonth(month_part === "??" ? "" : month_part);
    setDay(day_part === "??" ? "" : day_part);
  };

  useEffect(() => {
    parseValue(defaultValue);
  }, [defaultValue]);

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setIsCheckboxChecked(true);
      setDay("");
      setMonth("");
      setYear("");
      onChange(name, checkbox.value);
      onSave(name, checkbox.value);
    } else {
      setIsCheckboxChecked(false);
      onChange(name, "");
      onSave(name, "");
    }
  };

  const saveNewValue = (day, month, year) => {
    let value = `${year || "????"}-${month || "??"}-${day || "??"}`;
    if (isCheckboxChecked) {
      value = checkbox.value;
    }
    onChange(name, value);
    onSave(name, value);
  };

  const handleDayChange = (event) => {
    const day = event.target.value.replace(/\D/g, "");
    if (day.length > 2) {
      return;
    }
    setDay(() => {
      saveNewValue(day, month, year);
      return day;
    });
  };

  const handleMonthChange = (event) => {
    const month = event.target.value.replace(/\D/g, "");
    if (month.length > 2) {
      return;
    }
    setMonth(() => {
      saveNewValue(day, month, year);
      return month;
    });
  };

  const handleYearChange = (event) => {
    const year = event.target.value.replace(/\D/g, "");
    if (year.length > 4) {
      return;
    }
    setYear(() => {
      saveNewValue(day, month, year);
      return year;
    });
  };

  return (
    <div>
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <div className="flex items-end">
        <div className="flex flex-row gap-2">
          {(dateType === "full" || dateType === "day-optional") && (
            <div className="flex flex-col">
              <label
                htmlFor={`${name}_day`}
                className="font-medium text-sm text-gray-400"
              >
                DD{dateType === "day-optional" && "*"}
              </label>
              <input
                name={`${name}_day`}
                id={`${name}_day`}
                type="number"
                min={1}
                max={31}
                step={1}
                maxLength={2}
                className="form-input w-10"
                disabled={isCheckboxChecked}
                value={day}
                onChange={handleDayChange}
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-", "."].includes(evt.key) &&
                  evt.preventDefault()
                }
              />
            </div>
          )}
          <div className="flex flex-col">
            <label
              htmlFor={`${name}_month`}
              className="font-medium text-sm text-gray-400"
            >
              MM
            </label>
            <input
              name={`${name}_month`}
              id={`${name}_month`}
              type="number"
              min={1}
              step={1}
              max={12}
              maxLength={2}
              className="form-input w-10"
              disabled={isCheckboxChecked}
              value={month}
              onChange={handleMonthChange}
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", "."].includes(evt.key) &&
                evt.preventDefault()
              }
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor={`${name}_year`}
              className="font-medium text-sm text-gray-400"
            >
              YYYY
            </label>
            <input
              name={`${name}_year`}
              id={`${name}_year`}
              type="number"
              max="9999"
              maxLength={4}
              className="form-input w-20"
              disabled={isCheckboxChecked}
              value={year}
              onChange={handleYearChange}
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", "."].includes(evt.key) &&
                evt.preventDefault()
              }
            />
          </div>
        </div>

        {checkbox && (
          <div className="h-10 flex items-center">
            <div>
              <input
                type="checkbox"
                className="ml-4 h-4 w-4 rounded border-gray-400 text-indigo-600 focus:ring-indigo-600"
                id={`${name}_checkbox`}
                name={`${name}_checkbox`}
                checked={isCheckboxChecked}
                onChange={handleCheckboxChange}
              />
              <label
                className="ml-2 text-sm text-gray-600"
                htmlFor={`${name}_checkbox`}
              >
                {checkbox.label}
              </label>
            </div>
          </div>
        )}
      </div>
      {dateType === "day-optional" && (
        <div className="mt-1 text-sm text-gray-400">
          * Leave blank if unknown
        </div>
      )}
    </div>
  );
}

export function DateInputDisplay({ element, label, instructions, value }) {
  return (
    <div key={element.name} className="px-6 flex flex-row py-3">
      <div className="w-1/2 text-gray-500 text-sm pr-6">{label}</div>
      <div className="w-1/2 text-gray-800 text-sm">
        {!value && (
          <span className="font-light text-neutral-400">Not entered</span>
        )}
        {value && <span>{value.replaceAll("?", "X")}</span>}
      </div>
    </div>
  );
}
