import { DateInput } from "components/fields/DateField";
import { CountryDropdown } from "components/fields/DropdownField";
import { ModalTableField } from "components/fields/ModalTableField";
import { Radio } from "components/fields/RadioField";
import { TableField } from "components/fields/TableField";
import { TextArea } from "components/fields/TextArea";
import { FieldConfig, SectionConfig, TaskConfig } from "taskConfigs/Types";

export const portOfEntryChildImmigrationHistoryConfig = new TaskConfig({
  title: "Immigration history",
  description: "Tell us about their citizenship and countries of residence.",
  sections: [
    new SectionConfig({
      title: "Immigration history",
      body: [
        new FieldConfig({
          type: Radio,
          name: "has_other_permanent_residences",
          label: "Are they a permanent resident of any other countries?",
        }),
        new FieldConfig({
          type: TableField,
          name: "other_permanent_residences",
          label:
            "List all of the countries where they have permanent residence",
          width: "w-full",
          children: <CountryDropdown name="country" label="Country" />,
          shouldBeVisible: (data) =>
            data["has_other_permanent_residences"] === "Yes",
        }),
        new FieldConfig({
          type: Radio,
          name: "has_lived_in_another_country",
          label:
            "Have they lived in any country other than their country of citizenship or Canada for more than 6 months?",
        }),
        new FieldConfig({
          type: ModalTableField,
          name: "past_countries_of_residence",
          label: "Which countries and when?",
          itemName: "country",
          tableColumns: ["start_date", "end_date", "country"],
          shouldBeVisible: (data) =>
            data.has_lived_in_another_country === "Yes",
          fields: [
            new FieldConfig({
              type: CountryDropdown,
              name: "country",
              label: "Country",
            }),
            new FieldConfig({
              type: DateInput,
              name: "start_date",
              label: "From",
              dateType: "day-optional",
            }),
            new FieldConfig({
              type: DateInput,
              name: "end_date",
              label: "To",
              dateType: "day-optional",
            }),
          ],
        }),
        new FieldConfig({
          type: Radio,
          name: "has_been_granted_canadian_permit",
          label:
            "Have they previously been granted a Canadian work permit, study permit, or permanent residence?",
        }),
        new FieldConfig({
          type: TextArea,
          name: "canadian_permit_details",
          label: "Please provide details",
          shouldBeVisible: (data) =>
            data.has_been_granted_canadian_permit === "Yes",
          width: "w-full",
        }),
      ],
    }),
  ],
});
