import { workPermitPrimaryPersonalInformationConfig } from "taskConfigs/workPermit/PersonalInformationConfig";
import { workPermitPrimaryLanguagesConfig } from "taskConfigs/workPermit/LanguagesConfig";
import { workPermitPrimaryAdmissibilityConfig } from "taskConfigs/workPermit/AdmissibilityConfig";
import { workPermitPrimaryFamilyConfig } from "taskConfigs/workPermit/FamilyConfig";
import { workPermitPrimaryEducationConfig } from "taskConfigs/workPermit/EducationConfig";
import { workPermitPrimaryEmploymentConfig } from "taskConfigs/workPermit/EmploymentConfig";
import { workPermitChildPersonalInformationConfig } from "taskConfigs/workPermit/ChildPersonalInformationConfig";
import { workPermitChildLanuagesConfig } from "taskConfigs/workPermit/ChildLanguagesConfig";
import { workPermitChildAdmissibilityConfig } from "taskConfigs/workPermit/ChildAdmissibilityConfig";
import { workPermitPartnerPersonalInformationConfig } from "taskConfigs/workPermit/PartnerPersonalInformationConfig";
import { workPermitPartnerLanguagesConfig } from "taskConfigs/workPermit/PartnerLanguagesConfig";
import { workPermitPartnerAdmissibilityConfig } from "taskConfigs/workPermit/PartnerAdmissibilityConfig";
import { workPermitPartnerFamilyConfig } from "taskConfigs/workPermit/PartnerFamilyConfig";
import { workPermitPartnerEducationConfig } from "taskConfigs/workPermit/PartnerEducationConfig";
import { workPermitPartnerEmploymentConfig } from "taskConfigs/workPermit/PartnerEmploymentConfig";
import { workPermitPrimaryInitialQuestionsConfig } from "taskConfigs/workPermit/InitialQuestionsConfig";

import { genericConfig } from "taskConfigs/GenericConfig";

import { portOfEntryPrimaryInitialQuestionsConfig } from "./portOfEntry/InitialQuestionsConfig";
import { portOfEntryPrimaryPersonalInformationConfig } from "./portOfEntry/PersonalInformationConfig";
import { portOfEntryPrimaryImmigrationHistoryConfig } from "./portOfEntry/ImmigrationHIstoryConfig";
import { portOfEntryPrimaryFamilyConfig } from "./portOfEntry/FamilyConfig";
import { portOfEntryPrimaryEmploymentConfig } from "./portOfEntry/EmploymentConfig";
import { portOfEntryPrimaryEducationConfig } from "./portOfEntry/EducationConfig";
import { portOfEntryPrimaryAdmissibilityConfig } from "./portOfEntry/AdmissibilityConfig";
import { portOfEntryPartnerPersonalInformationConfig } from "./portOfEntry/PartnerPersonalInformationConfig";
import { portOfEntryPartnerImmigrationHistoryConfig } from "./portOfEntry/PartnerImmigrationHIstoryConfig";
import { portOfEntryPartnerEmploymentConfig } from "./portOfEntry/PartnerEmploymentConfig";
import { portOfEntryChildPersonalInformationConfig } from "./portOfEntry/ChildPersonalInformationConfig";
import { portOfEntryChildImmigrationHistoryConfig } from "./portOfEntry/ChildImmigrationHIstoryConfig";
import { portOfEntryChildEmploymentConfig } from "./portOfEntry/ChildEmploymentConfig";

// Be careful with these keys, they have to match the task 'key' on the backend
const TASK_CONFIGS = {
  generic: genericConfig,
  "work-permit-primary-initial-questions":
    workPermitPrimaryInitialQuestionsConfig,
  "work-permit-primary-personal-information":
    workPermitPrimaryPersonalInformationConfig,
  "work-permit-primary-languages": workPermitPrimaryLanguagesConfig,
  "work-permit-primary-admissibility": workPermitPrimaryAdmissibilityConfig,
  "work-permit-primary-family": workPermitPrimaryFamilyConfig,
  "work-permit-primary-education": workPermitPrimaryEducationConfig,
  "work-permit-primary-employment": workPermitPrimaryEmploymentConfig,
  "work-permit-child-personal-information":
    workPermitChildPersonalInformationConfig,
  "work-permit-child-languages": workPermitChildLanuagesConfig,
  "work-permit-child-admissibility": workPermitChildAdmissibilityConfig,
  "work-permit-partner-personal-information":
    workPermitPartnerPersonalInformationConfig,
  "work-permit-partner-languages": workPermitPartnerLanguagesConfig,
  "work-permit-partner-admissibility": workPermitPartnerAdmissibilityConfig,
  "work-permit-partner-family": workPermitPartnerFamilyConfig,
  "work-permit-partner-education": workPermitPartnerEducationConfig,
  "work-permit-partner-employment": workPermitPartnerEmploymentConfig,

  "port-of-entry-primary-initial-questions":
    portOfEntryPrimaryInitialQuestionsConfig,
  "port-of-entry-primary-personal-information":
    portOfEntryPrimaryPersonalInformationConfig,
  "port-of-entry-primary-immigration-history":
    portOfEntryPrimaryImmigrationHistoryConfig,
  "port-of-entry-primary-family": portOfEntryPrimaryFamilyConfig,
  "port-of-entry-primary-employment": portOfEntryPrimaryEmploymentConfig,
  "port-of-entry-primary-education": portOfEntryPrimaryEducationConfig,
  "port-of-entry-primary-admissibility": portOfEntryPrimaryAdmissibilityConfig,
  "port-of-entry-partner-personal-information":
    portOfEntryPartnerPersonalInformationConfig,
  "port-of-entry-partner-immigration-history":
    portOfEntryPartnerImmigrationHistoryConfig,
  "port-of-entry-partner-employment": portOfEntryPartnerEmploymentConfig,
  "port-of-entry-child-personal-information":
    portOfEntryChildPersonalInformationConfig,
  "port-of-entry-child-immigration-history":
    portOfEntryChildImmigrationHistoryConfig,
  "port-of-entry-child-employment": portOfEntryChildEmploymentConfig,
};

export default function getTaskConfig(task_key) {
  const config = TASK_CONFIGS[task_key];
  if (!config) {
    throw new Error(`No task config found for task key: ${task_key}`);
  }
  return config;
}
