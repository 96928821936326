import { useScrollPosition } from "lib/Hooks";

export class SidebarItem {
  constructor({ id, label, status, isActive }) {
    this.id = id;
    this.label = label;
    this.status = status;
    this.isActive = isActive;
  }
}

export function Sidebar({ items }) {
  const scrollPosition = useScrollPosition();

  const renderSidebarItem = (label, status, index, length) => {
    const Icon = () => {
      return <div className="absolute w-3 h-3 bg-primary rounded-full"></div>;
    };
    const lineHeight = () => {
      if (index === 0) {
        return "h-1/2 top-1/2";
      } else if (index === length - 1) {
        return "h-1/2 top-0";
      } else {
        return "h-full";
      }
    };

    return (
      <>
        <div className="relative w-6 grow-0 shrink-0 basis-6 flex flex-col justify-center items-center">
          <div
            className={`absolute w-0 ${lineHeight()} left-1/2 outline outline-1 outline-gray-400`}
          ></div>

          <Icon />
        </div>
        <div className="py-2 text-gray-700 hover:text-primary hover:font-semibold cursor-pointer">
          {label}
        </div>
      </>
    );
  };

  return (
    <div className={`w-44  ${scrollPosition >= 210 ? "fixed top-[86px]" : ""}`}>
      {items.map((item, index) => {
        return (
          <div
            key={`sidebar_${item.id}`}
            className="flex flex-row align-middle gap-2"
            onClick={() =>
              document.getElementById(item.id).scrollIntoView({
                behavior: "smooth",
              })
            }
          >
            {renderSidebarItem(item.label, item.status, index, items.length)}
          </div>
        );
      })}
    </div>
  );
}
