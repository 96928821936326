import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPasswordRequest } from "../Api";
import { Button } from "../components/Button";

export default function ResetPasswordRequest({}) {
  const location = useLocation();

  const defaultEmail = location.state && location.state.email;
  const [email, setEmail] = useState(defaultEmail || "");
  const [emailError, setEmailError] = useState(null);

  const [resultMessage, setResultMessage] = useState(null);

  const validateEmail = () => {
    if (!email) {
      setEmailError("Please enter an email address.");
      return;
    }

    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setEmailError(
        "That doesn't look like an email address. Please try again."
      );
      return;
    }

    setEmailError(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    await resetPasswordRequest(email).then((result) => {
      setResultMessage(
        `Thanks! If we found an account, we sent an email to ${result.email} with a link to reset your password.`
      );
    });
  };

  const isValid = () => {
    return email && !emailError;
  };

  return (
    <div className="fullscreen bg-gradient-to-r from-[#BDE2A0] to-[#6CB7EC]">
      <div className="relative card p-14 max-w-md mx-auto mt-36">
        {resultMessage && (
          <>
            <p className="font-light text-center">{resultMessage}</p>
          </>
        )}

        {!resultMessage && (
          <>
            <h2 className="text-xl font-semibold">Reset your password</h2>
            <p className="text-sm mt-2 font-light">
              Enter the email address associated with your account and we’ll
              send you a link to reset your password.
            </p>
            <form className="mt-8">
              <div className="mb-6">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  className="shadow-sm rounded-md border border-gray-400 py-2 px-2 text-gray-700 focus:border- focus:outline-none focus:ring-blue-500 w-full disabled:text-neutral-400"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={() => validateEmail()}
                />
                {emailError && (
                  <p className="text-sm mt-1 text-red-500">{emailError}</p>
                )}
              </div>

              <Button onClick={handleSubmit} isDisabled={!isValid()}>
                Reset password
              </Button>
              <div className="mt-8"></div>
            </form>
          </>
        )}
      </div>
    </div>
  );
}
