import { logUserIn } from "lib/Utils";
import { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { lookupPasswordToken, resetPassword, signup } from "../Api";
import { Button } from "../components/Button";

export default function ResetPassword({}) {
  const navigate = useNavigate();

  const { params } = useMatch("/reset/:token");
  const { token } = params;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [confirm, setConfirm] = useState(null);
  const [confirmError, setConfirmError] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const request = async () => {
      let result = await lookupPasswordToken(token);
      setEmail(result.email);
    };

    request();
  }, []);

  const validatePassword = () => {
    if (!password) {
      setPasswordError("Please enter a password.");
      return;
    }
    if (password.length < 8) {
      setPasswordError("Your password must be at least 8 characters long.");
      return;
    }
    setPasswordError(null);
  };

  const validateConfirm = () => {
    if (!confirm) {
      setConfirmError("Please enter your password again.");
      return;
    }
    if (confirm != password) {
      setConfirmError("Both passwords should be the same.");
      return;
    }
    setConfirmError(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");

    validatePassword();
    validateConfirm();
    if (!isValid) {
      return;
    }

    await resetPassword(token, password).then(async (result) => {
      if (result["message"]) {
        setError(result["message"]);
        return;
      }

      logUserIn(result.s_id, result.role, result.logo_filename);
      navigate("/");
      window.location.reload();
    });
  };

  const isValid = () => {
    return (
      password &&
      confirm &&
      password === confirm &&
      !passwordError &&
      !confirmError
    );
  };

  return (
    <div className="fullscreen bg-gradient-to-r from-[#BDE2A0] to-[#6CB7EC]">
      <div className="relative card p-14 max-w-md mx-auto mt-36">
        <h2 className="text-xl font-semibold">Reset your password</h2>
        <p className="text-sm mt-2 font-light">
          {`Create a new password for ${email}.`}
        </p>
        <form className="mt-8">
          <div className="mb-6">
            <label htmlFor="password" className="form-label">
              New password
            </label>
            <input
              className={`shadow-sm rounded-md border ${
                passwordError ? "border-red-400" : "border-gray-400"
              }  py-2 px-2 text-gray-700 focus:border-blue-500 focus:outline-none focus:ring-blue-500 w-full`}
              name="password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              onBlur={() => validatePassword()}
            />
            {passwordError && (
              <p className="text-sm mt-1 text-red-500">{passwordError}</p>
            )}
          </div>

          <div className="mb-10">
            <label htmlFor="password" className="form-label">
              Confirm password
            </label>
            <input
              className={`shadow-sm rounded-md border ${
                confirmError ? "border-red-400" : "border-gray-400"
              }  py-2 px-2 text-gray-700 focus:border-blue-500 focus:outline-none focus:ring-blue-500 w-full`}
              name="confirm"
              type="password"
              onChange={(e) => {
                setConfirm(e.target.value);
              }}
              onBlur={() => validateConfirm()}
            />
            {confirmError && (
              <p className="text-sm mt-1 text-red-500">{confirmError}</p>
            )}
          </div>

          {error && (
            <div className="border-red-400 bg-red-50 text-sm border rounded py-2 px-4 my-4 text-red-600">
              {error}
            </div>
          )}

          <Button onClick={handleSubmit} isDisabled={!isValid()}>
            Reset password
          </Button>
          <div className="mt-8"></div>
        </form>
      </div>
    </div>
  );
}
