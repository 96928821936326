import { Dialog } from "@headlessui/react";
import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Button } from "./Button";
import { CenterModal } from "./CenterModal";

export function ConfirmDeleteModal({ isOpen, setIsOpen, onConfirm, children }) {
  const handleCancelClicked = (event) => {
    setIsOpen(false);
  };

  const handleConfirmClicked = async (event) => {
    await onConfirm();
    await new Promise((resolve) =>
      setTimeout(() => resolve(setIsOpen(false)), 500)
    );
  };

  return (
    <>
      <CenterModal isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="flex justify-center">
          <div className="bg-red-400 p-4 rounded-full">
            <ExclamationTriangleIcon className="h-12 w-12 text-white" />
          </div>
        </div>
        <h3 className="text-xl font-medium leading-6 text-gray-900 mt-6 mb-8">
          Are you sure?
        </h3>

        {children}
        <div className="flex flex-row gap-4 w-3/4 mx-auto">
          <Button onClick={handleCancelClicked} style="outline">
            Cancel
          </Button>
          <Button onClick={handleConfirmClicked} style="danger">
            Delete
          </Button>
        </div>
      </CenterModal>
    </>
  );
}
