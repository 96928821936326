import { getApplicant, getTask } from "Api";
import { useQuery } from "react-query";
import LoadingSpinner from "components/LoadingSpinner";
import { QuestionnaireSection } from "components/QuestionnaireSection";
import { useMatch } from "react-router-dom";
import ErrorBoundary from "components/ErrorBoundary";
import { useEffect, useState } from "react";
import TASK_CONFIGS from "taskConfigs/TaskConfigs";
import { ApplicantContext } from "contexts/contexts";
import { Header } from "components/Header";
import { BreadcrumbItem, Breadcrumbs } from "components/Breadcrumbs";
import { ApplicantIcon } from "components/ApplicantIcon";
import getTaskConfig from "taskConfigs/TaskConfigs";

export default function ViewTask() {
  const { params } = useMatch("/:applicant_id/tasks/view/:task_id");
  const TASK_ID = `T_${params.task_id}`;
  const CACHE_KEY = ["view_task", TASK_ID];

  const [formData, setFormData] = useState(null);
  const [taskConfig, setTaskConfig] = useState(null);

  const { isLoading, data: task } = useQuery(
    CACHE_KEY,
    async () => await getTask(TASK_ID),
    {
      onSuccess: (data) => {
        setFormData(data.values);
        setTaskConfig(getTaskConfig(data.key));
      },
    }
  );

  const { data: applicant } = useQuery(
    ["applicant", params.applicant_id],
    async () => await getApplicant(params.applicant_id)
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {applicant && taskConfig && (
            <ApplicantContext.Provider value={applicant}>
              <div className="container mx-auto">
                <Header>
                  <>
                    <Breadcrumbs
                      items={[
                        new BreadcrumbItem({
                          title: `${applicant.primary_applicant_name}'s application`,
                          url: "/",
                        }),
                      ]}
                    />
                    <div className="mt-6 flex flex-row gap-4 items-center">
                      <ApplicantIcon applicant={applicant} />

                      <h1 className="font-medium text-3xl">
                        {applicant.name}'s questionnaire
                      </h1>
                    </div>
                  </>
                </Header>

                <div className="flex flex-row justify-center">
                  <div className="w-full md:w-3/4 flex flex-col gap-4">
                    <ErrorBoundary>
                      <QuestionnaireSection
                        key={`view_task_${task.task_id}`}
                        task={task}
                        taskConfig={taskConfig}
                        formData={formData}
                        type="view"
                        nextTask={null}
                      />
                    </ErrorBoundary>
                  </div>
                </div>
              </div>
            </ApplicantContext.Provider>
          )}
        </>
      )}
    </>
  );
}
