import { TextInput } from "components/fields/TextInput";
import { FieldConfig, TaskConfig, SectionConfig, Heading } from "../Types";

export const portOfEntryPrimaryEmploymentConfig = new TaskConfig({
  title: "Employment",
  description: "Tell us about your current and prospective employers.",
  sections: [
    new SectionConfig({
      title: "Employment",
      body: [
        new FieldConfig({
          type: TextInput,
          name: "prospective_occupation",
          label: "Prospective occupation in Canada",
        }),
        new FieldConfig({
          type: TextInput,
          name: "current_employer_name",
          label: "Legal name of current employer",
        }),
        new FieldConfig({
          type: TextInput,
          name: "prospective_employer_name",
          label: "Legal name of prospective employer",
        }),
        new Heading({
          text: "Location of intended employer address",
        }),
        new FieldConfig({
          type: TextInput,
          name: "prospective_employer_address",
          label: "Street number and name",
        }),
        new FieldConfig({
          type: TextInput,
          name: "prospective_employer_city",
          label: "City or town",
        }),
        new FieldConfig({
          type: TextInput,
          name: "prospective_employer_province",
          label: "Province",
        }),
        new FieldConfig({
          type: TextInput,
          name: "prospective_occupation_salary",
          label: "Salary for prospective Canadian role",
        }),
      ],
    }),
  ],
});
