import { useFormik } from "formik";
import { signupSchema } from "lib/FormikSchemas";
import { logUserIn, logUserOut } from "lib/Utils";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { getUrlRoot, signup } from "../Api";
import { Button } from "../components/Button";

export default function Signup({ userEmail }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  useEffect(() => {
    logUserOut();
  }, []);

  const onSubmitMutation = useMutation(
    async ({ email, password, hasAgreedToTerms }) => {
      return await signup(email, password, hasAgreedToTerms);
    },
    {
      onSettled: () => queryClient.invalidateQueries("applicants"),
      onSuccess: async (data) => {
        logUserIn(data.s_id, data.role, data.logo_filename);
        navigate("/");
        window.location.reload();
      },
      onError: (error) => {
        setError(error.message);
      },
    }
  );

  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: userEmail,
      password: "",
      hasAgreedToTerms: false,
    },
    validationSchema: signupSchema,
    onSubmit: (values) => {
      onSubmitMutation.mutate(values);
    },
  });

  return (
    <div className="fullscreen bg-gradient-to-r from-[#BDE2A0] to-[#6CB7EC]">
      <div className="relative card p-14 max-w-md mx-auto mt-36">
        <h2 className="text-xl font-semibold">Create your account</h2>
        <p className="text-sm mt-2 font-light">
          Set up an account to begin your application.
        </p>
        <form className="mt-8">
          <div className="mb-6">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              className="shadow-sm rounded-md border border-gray-400 py-2 px-2 text-gray-700 focus:border- focus:outline-none focus:ring-blue-500 w-full disabled:text-neutral-400"
              name="email"
              type="email"
              disabled={true}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.email && touched.email && (
              <p className="text-sm mt-1 text-red-500">{errors.email}</p>
            )}
          </div>
          <div className="mb-10">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input
              className={`shadow-sm rounded-md border ${
                errors.password && touched.password
                  ? "border-red-400"
                  : "border-gray-400"
              }  py-2 px-2 text-gray-700 focus:border-blue-500 focus:outline-none focus:ring-blue-500 w-full`}
              name="password"
              type="password"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.password && touched.password && (
              <p className="text-sm mt-1 text-red-500">{errors.password}</p>
            )}
          </div>

          {error && (
            <div className="border-red-400 bg-red-50 text-sm border rounded py-2 px-4 my-4 text-red-600">
              {error}
            </div>
          )}

          <div className="flex gap-4 justify-start">
            <input
              name="hasAgreedToTerms"
              type="checkbox"
              className={
                errors.hasAgreedToTerms && touched.hasAgreedToTerms
                  ? "ring-red-500 ring-2 ring-offset-2"
                  : ""
              }
              checked={values.hasAgreedToTerms}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <p className="text-gray-600">
              I agree to Path's{" "}
              <a
                href={getUrlRoot() + "terms"}
                target="_blank"
                className="text-primary hover:underline"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href={getUrlRoot() + "privacy"}
                target="_blank"
                className="text-primary hover:underline"
              >
                Privacy Policy
              </a>
            </p>
          </div>
          <div className="ml-8">
            {errors.hasAgreedToTerms && touched.hasAgreedToTerms && (
              <p className="text-sm mt-1 text-red-500">
                {errors.hasAgreedToTerms}
              </p>
            )}
          </div>

          <Button onClick={handleSubmit} isDisabled={!isValid || !dirty}>
            Create account
          </Button>
          <div className="mt-8"></div>
        </form>
      </div>
    </div>
  );
}
