import { Button } from "components/Button";
import InitialQuestionsModal from "components/InitialQuestionsModal";
import { useFormData } from "lib/Hooks";
import * as React from "react";
import { useQueryClient } from "react-query";
import getTaskConfig from "taskConfigs/TaskConfigs";
import { renderElement } from "./PageRenderer";

export function InitialQuestions({ application }) {
  const CACHE_KEY = ["initial-questions"];

  const initialQuestionKey = application.initial_questions_key;
  const taskConfig = getTaskConfig(initialQuestionKey);

  const queryClient = useQueryClient();
  const initialData = queryClient.getQueryData(CACHE_KEY)?.values || {};
  const [formData, setFormData, updateField] = useFormData(initialData);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);

  const handleSubmit = () => {
    setIsConfirmModalOpen(true);
  };

  const isSubmitEnabled = () => {
    return (
      formData != null &&
      formData != undefined &&
      Object.keys(formData).length > 0
    );
  };

  return (
    <>
      <InitialQuestionsModal
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        application={application}
        formData={formData}
      />

      <div className="mt-6 flex flex-col border card p-6">
        <h3>Initial questions</h3>
        <p>
          We need to know a little about your immediate family to generate your
          application materials.
        </p>
        <div className="mt-6 autolayout">
          {taskConfig.sections.map((section) => {
            return (
              <React.Fragment key={section.title}>
                {section.body.map((element) => {
                  return renderElement(
                    element,
                    formData,
                    updateField,
                    () => {}
                  );
                })}
              </React.Fragment>
            );
          })}
        </div>
        <div className="flex justify-end px-6 mt-4 gap-2">
          <Button
            style="primary"
            isDisabled={!isSubmitEnabled()}
            onClick={handleSubmit}
            fullWidth={false}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );
}
