import { useRef, useState } from "react";
import { useMatch, useParams } from "react-router-dom";
import { getApplicant, getApplication, getApiUrlRoot } from "../Api";
import Profile from "../components/Profile.js";
import Documents from "../components/Documents.js";
import { classNames, getAuth } from "lib/Utils";
import { useQuery } from "react-query";
import LoadingSpinner from "components/LoadingSpinner";
import { Button } from "components/Button";
import { ArrowDownTrayIcon, PlusIcon } from "@heroicons/react/24/solid";
import { RequestDocumentModal } from "components/RequestDocumentModal";
import { ApplicantContext } from "contexts/contexts";
import ErrorBoundary from "components/ErrorBoundary";
import { ExportQuestionnaire } from "components/ExportQuestionnaire";
import { BreadcrumbItem, Breadcrumbs } from "components/Breadcrumbs";
import { Header } from "components/Header";
import { ApplicantIcon } from "components/ApplicantIcon";

export default function User() {
  const match1 = useMatch("/users/:user_id");
  const match2 = useMatch("/users/:user_id/:status");

  const applicantId = match1?.params?.user_id || match2?.params?.user_id;
  const status = match2?.params?.status || "profile";

  const navTo = status.toLowerCase() === "documents" ? "DOCUMENTS" : "PROFILE";
  const [nav, setNav] = useState(navTo);

  const [isRequestDocumentModalOpen, setIsRequestDocumentModalOpen] =
    useState(false);
  const isRequestDocumentVisible = nav === "DOCUMENTS";
  const isDocExportVisible = nav === "DOCUMENTS";
  const isQuestionnaireExportVisible = nav === "PROFILE";
  const isInviteVisible = nav === "PROFILE";

  const { isLoading: isApplicationLoading, data: application } = useQuery(
    ["application", applicantId],
    () => getApplication(applicantId)
  );

  const { isLoading: isApplicantLoading, data: applicant } = useQuery(
    ["applicant", applicantId],
    () => getApplicant(applicantId)
  );

  const isLoading = isApplicationLoading || isApplicantLoading;

  // Todo(Awais): It would be better if the bulk of this function lived in Api.js
  const handleDocExport = async () => {
    const url = `${getApiUrlRoot()}applicants/${applicantId}/export`;
    const { token, role } = getAuth();
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then(async (response) => {
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(await response.blob());
      a.download = `${applicant.name} Export.zip`;
      a.click();
    });
  };

  let Display = () => {
    if (nav === "DOCUMENTS") {
      return <Documents applicantId={applicantId} />;
    }
    return <Profile applicantId={applicantId} />;
  };

  const navigation = [
    { name: "Questionnaire", value: "PROFILE" },
    { name: "Documents", value: "DOCUMENTS" },
  ];

  if (isLoading) {
    return (
      <div className="container mx-auto">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <div className="container mx-auto">
        <ApplicantContext.Provider value={applicant}>
          <RequestDocumentModal
            isOpen={isRequestDocumentModalOpen}
            setIsOpen={setIsRequestDocumentModalOpen}
          />
          <ErrorBoundary>
            <Header>
              <>
                <div className="flex flex-col">
                  <Breadcrumbs
                    items={[
                      new BreadcrumbItem({
                        title: `${applicant.primary_applicant_name}'s application`,
                        url: `/applications/${application.application_id}`,
                      }),
                    ]}
                  />
                  <div className="w-full flex flex-row gap-4 items-center justify-start">
                    <ApplicantIcon applicant={applicant} />
                    <h1 className="mt-1">{applicant.name}</h1>
                    {applicant.invite_url && isInviteVisible && (
                      <p className="pb-[3px] text-sm text-gray-500">
                        <span className="font-medium text-gray-500">
                          Invite link:{" "}
                        </span>{" "}
                        {applicant.invite_url}
                      </p>
                    )}
                  </div>
                  <div className="w-full flex flex-row gap-4 -mb-4 items-end justify-between">
                    <div className="mt-4 h-12 flex space-x-8">
                      {navigation.map((item) => (
                        <div
                          key={item.name}
                          className={classNames(
                            item.value === nav
                              ? "border-primary text-primary"
                              : "border-transparent text-gray-600 hover:text-gray-700 hover:border-gray-300",
                            "inline-flex items-center px-1 pt-1 border-b-2 cursor-pointer"
                          )}
                          onClick={() => {
                            window.scrollTo({
                              top: Math.min(80, window.pageYOffset),
                              behavior: "smooth",
                            });
                            setNav(item.value);
                          }}
                        >
                          {item.name}
                        </div>
                      ))}
                    </div>
                    <div className="mb-4 flex flex-row gap-2">
                      {isRequestDocumentVisible && (
                        <Button
                          fullWidth={false}
                          onClick={() => setIsRequestDocumentModalOpen(true)}
                          style={"light"}
                        >
                          <PlusIcon className="h-5 h-5" />
                          <div className="text-sm">Request document</div>
                        </Button>
                      )}
                      {isDocExportVisible && (
                        <Button fullWidth={false} onClick={handleDocExport}>
                          <ArrowDownTrayIcon className="h-5 h-5" />
                          <div className="text-sm">Download documents</div>
                        </Button>
                      )}
                      {isQuestionnaireExportVisible && (
                        <ExportQuestionnaire applicant={applicant} />
                      )}
                    </div>
                  </div>
                </div>
              </>
            </Header>
          </ErrorBoundary>
          <ErrorBoundary>
            <Display />
          </ErrorBoundary>
        </ApplicantContext.Provider>
      </div>
    </>
  );
}
