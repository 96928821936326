import { useEffect, useState } from "react";
import { saveTask } from "Api";
import { useMutation } from "react-query";
import { sanitizeData } from "./Utils";

export function useSaveTask(taskId, data, taskConfig) {
  const fields = taskConfig?.sections.flatMap((section) => section.body) || [];

  const saveTaskMutation = useMutation(({ data }) => saveTask(taskId, data), {
    onError: (error) => {
      // TODO: Make this a toast
      alert(
        "Error: We were unable to save the contents of this page. Please contact Path's Support Team for help:\n\nsupport@pathimmigration.ca\n1-888-817-0079"
      );
    },
  });

  const onSave = async (fieldName, value) => {
    const mergedData = { ...data, [fieldName]: value };
    const sanitizedData = sanitizeData(mergedData, fields);
    await saveTaskMutation.mutate({ data: sanitizedData });
  };

  const submitForm = async () => {
    const sanitizedData = sanitizeData(data, fields);
    await saveTaskMutation.mutate({ data: sanitizedData });
  };

  return { onSave, submitForm };
}

export function useFormData(initialData) {
  const [formData, setState] = useState(initialData);

  const setFormData = (newData) => {
    setState(newData);
  };

  const updateField = (name, value) => {
    setState((state) => {
      return { ...state, [name]: value };
    });
  };

  return [formData, setFormData, updateField];
}

export function useTabularData(initialValue, fields) {
  // This keeps a record of all the data in this table. The data has the following format:
  // [
  //   { column_1_name: value, column_2_name: value },
  //   { column_1_name: value, column_2_name: value },
  // ]
  const [data, setData] = useState(initialValue);

  // Set the entire table data.
  const setTabularData = (newData) => {
    setData(newData);
    return newData;
  };

  // Set a single row in the table.
  const setTabularRow = (index, newRow) => {
    const sanitizedRow = sanitizeData(newRow, fields);
    setData((prevData) => {
      let result = [...prevData];
      result[index] = sanitizedRow;
      return result;
    });
    let result = [...data];
    result[index] = sanitizedRow;
    return result; // Todo(Awais): Is it an anti-pattern to return a value from a setter?
  };

  // Set a single value in a row within the table.
  const setTabularValue = (index, rowName, value) => {
    setData((prevData) => {
      let prevRow = prevData[index];
      let newRow = { ...prevRow, [rowName]: value };
      let result = [...prevData];
      result[index] = newRow;
      return result;
    });
    let prevRow = data[index];
    let newRow = { ...prevRow, [rowName]: value };
    let result = [...data];
    result[index] = newRow;
    return result; // Todo(Awais): Is it an anti-pattern to return a value from a setter?
  };

  return {
    data,
    setTabularData,
    setTabularRow,
    setTabularValue,
  };
}

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    };
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  return scrollPosition;
};
