import { getApplicantTasks, getApplication } from "Api";
import { useQuery } from "react-query";
import { Sidebar, SidebarItem } from "./Sidebar";
import ErrorBoundary from "./ErrorBoundary";
import { QuestionnaireSection } from "./QuestionnaireSection";
import getTaskConfig from "taskConfigs/TaskConfigs";

export default function Profile({ applicantId }) {
  const { isLoading: isTaskLoading, data: tasks } = useQuery(
    ["tasks", applicantId],
    () => getApplicantTasks(applicantId)
  );
  const { isLoading: isApplicationLoading, data: application } = useQuery(
    ["initial_questions", applicantId],
    () => getApplication(applicantId)
  );
  const isLoading = isTaskLoading || isApplicationLoading;

  // Todo(Awais): This code is duplicated in ExportQuestionnaire. We should clean this up.
  const combinedTasks = (application, tasks) => {
    if (application.applicant.id !== applicantId) {
      return tasks;
    }

    const initialQuestions = {
      applicant_id: application.applicant.id,
      task_id: "T_initial-questions",
      key: application.initial_questions_key,
      title: "Initial questions",
      description: null,
      status: "COMPLETE",
      values: application.values || {},
      when_created: application.when_created,
    };
    return [initialQuestions, ...tasks];
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <div className="container mx-auto">
      <div className="flex flex-row">
        <div className="w-1/4 mt-4 pr-8 hidden md:block">
          <Sidebar
            items={combinedTasks(application, tasks).map((task) => {
              const taskConfig = getTaskConfig(task.key);
              return new SidebarItem({
                id: task.key,
                label: taskConfig.title,
                status: null,
                isActive: false,
              });
            })}
          />
        </div>
        <div className="w-full md:w-3/4 flex flex-col gap-4">
          {combinedTasks(application, tasks).map((task) => {
            const taskConfig = getTaskConfig(task.key);
            return (
              <ErrorBoundary key={task.key}>
                <QuestionnaireSection
                  task={task}
                  taskConfig={taskConfig}
                  formData={task.values}
                  type="lawyer"
                  nextTask={null}
                />
              </ErrorBoundary>
            );
          })}
        </div>
      </div>
    </div>
  );
}
