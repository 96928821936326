import "./App.css";
import Login from "./pages/Login.js";
import ReviewerHome from "./pages/ReviewerHome";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import User from "./pages/User";
import { Invite } from "pages/Invite";
import ResetPasswordRequest from "pages/ResetPasswordRequest";
import ResetPassword from "pages/ResetPassword";
import { DocumentChecklist } from "pages/DocumentChecklist";
import { pdfjs } from "react-pdf";
import { Dashboard } from "pages/Dashboard";
import TaskList from "pages/TaskList";
import Nav from "components/Nav";
import { getAuth } from "lib/Utils";
import { Slide, ToastContainer } from "react-toastify";
import Task from "pages/Task";
import ReviewTask from "pages/ReviewTask";
import ViewTask from "pages/ViewTask";
import "react-toastify/dist/ReactToastify.css";
import TriggerIntentionalError from "pages/TriggerIntentionalError";
import { FullPageErrorBoundary } from "components/ErrorBoundary";
import { Application } from "pages/Application";
import { getUrlRoot } from "Api";

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/static/pdf.worker.js`;

function ReviewerRoute({ children }) {
  const { token, role } = getAuth();

  if (role === "reviewer") {
    return children;
  } else {
    return <Navigate to="/" />;
  }
}

function ApplicantRoute({ children }) {
  const { token, role } = getAuth();

  if (role === "applicant") {
    return children;
  } else {
    return <Navigate to="/" />;
  }
}

function App() {
  const { token, role, logo } = getAuth();
  return (
    <div className="w-screen min-h-screen">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        transition={Slide}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Nav logoFilename={logo} />
      <FullPageErrorBoundary>
        <div className="container mb-auto mx-auto px-2">
          <Routes>
            <Route
              path="/applications/:application_id"
              element={
                <ReviewerRoute>
                  <Application />
                </ReviewerRoute>
              }
            />
            <Route
              path="users/:id/profile"
              element={
                <ReviewerRoute>
                  <User />
                </ReviewerRoute>
              }
            />
            <Route
              path="/users/:id/documents"
              element={
                <ReviewerRoute>
                  <User />
                </ReviewerRoute>
              }
            />
            <Route
              path="/users/:id"
              element={
                <ReviewerRoute>
                  <User />
                </ReviewerRoute>
              }
            />
            <Route
              path="/:userId/tasks/:taskId"
              element={
                <ApplicantRoute>
                  <Task />
                </ApplicantRoute>
              }
            ></Route>
            <Route
              path="/:userId/tasks/review"
              element={
                <ApplicantRoute>
                  <ReviewTask />
                </ApplicantRoute>
              }
            />
            <Route
              path="/:userId/tasks/review/:taskId"
              element={
                <ApplicantRoute>
                  <ReviewTask />
                </ApplicantRoute>
              }
            />
            <Route
              path="/:userId/tasks/view/:taskId"
              element={
                <ApplicantRoute>
                  <ViewTask />
                </ApplicantRoute>
              }
            />
            <Route
              path="/:userId/tasks"
              element={
                <ApplicantRoute>
                  <TaskList />
                </ApplicantRoute>
              }
            />
            <Route
              path="/:userId/documents"
              element={
                <ApplicantRoute>
                  <DocumentChecklist />
                </ApplicantRoute>
              }
            />
            <Route path="/error" element={<TriggerIntentionalError />} />
            <Route path="/invite/:opaqueId" element={<Invite />} />
            <Route path="/reset-password" element={<ResetPasswordRequest />} />
            <Route path="/reset/:token" element={<ResetPassword />} />
            <Route
              path="/"
              element={
                role === "reviewer" ? (
                  <ReviewerRoute>
                    <ReviewerHome />
                  </ReviewerRoute>
                ) : role === "applicant" ? (
                  <ApplicantRoute>
                    <Dashboard />
                  </ApplicantRoute>
                ) : (
                  <Login />
                )
              }
            />
          </Routes>
        </div>
      </FullPageErrorBoundary>
      <div id="footer" className="z-10 h-20 sticky top-[100vh] mt-24">
        <div className="container px-2 py-6 mx-auto">
          <div className="flex justify-center gap-3 text-gray-600 text-sm font-light">
            <p>© Path Immigration Inc.</p>
            <div>•</div>
            <a
              href={getUrlRoot() + "privacy"}
              target="_blank"
              className="hover:underline"
            >
              Privacy policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
