import { DateInput } from "components/fields/DateField";
import { CountryDropdown, Dropdown } from "components/fields/DropdownField";
import { ModalTableField } from "components/fields/ModalTableField";
import { Radio } from "components/fields/RadioField";
import { TextInput } from "components/fields/TextInput";
import {
  FIELD_OF_STUDY_OPTIONS,
  LEVEL_OF_EDUCATION_OPTIONS,
  toDropdownoptions,
} from "lib/Enums";
import { FieldConfig, Text, TaskConfig, SectionConfig } from "../Types";

export const workPermitPrimaryEducationConfig = new TaskConfig({
  title: "Education",
  description: "Tell us about your post-secondary education and/or training.",
  sections: [
    new SectionConfig({
      title: "Education history",
      body: [
        new FieldConfig({
          type: ModalTableField,
          name: "education_history",
          label: null,
          instructions:
            "List any post-secondary education, including university, college or apprenticeship training. Please list your most recent education first.",
          itemName: "program of study",
          tableColumns: [
            "start_date",
            "end_date",
            "name_of_institution",
            "level_of_education",
          ],
          fields: [
            new FieldConfig({
              type: DateInput,
              name: "start_date",
              label: "From",
              dateType: "day-excluded",
            }),
            new FieldConfig({
              type: DateInput,
              name: "end_date",
              label: "To",
              dateType: "day-excluded",
              checkbox: {
                value: "Present",
                label: "I currently study here",
              },
            }),
            new FieldConfig({
              type: TextInput,
              name: "field_of_study",
              label: "Field of study",
              width: "w-full",
            }),
            new FieldConfig({
              type: Dropdown,
              name: "level_of_education",
              label: "Level of study",
              width: "w-full",
              options: toDropdownoptions(LEVEL_OF_EDUCATION_OPTIONS),
            }),
            new FieldConfig({
              type: Radio,
              name: "is_highest_level_of_education",
              label: "Is this your highest level of post-secondary education?",
            }),
            new FieldConfig({
              type: TextInput,
              name: "name_of_institution",
              label: "School / Facility name",
              width: "w-96",
            }),
            new FieldConfig({
              type: TextInput,
              name: "city_of_study",
              label: "City / Town",
            }),
            new FieldConfig({
              type: TextInput,
              name: "province_of_study",
              label: "Province / State (if applicable)",
            }),
            new FieldConfig({
              type: CountryDropdown,
              name: "country_of_study",
              label: "Country / Territory",
            }),
          ],
        }),
      ],
    }),
  ],
});
