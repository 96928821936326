import { Radio } from "components/fields/RadioField";
import { TextInput } from "components/fields/TextInput";
import { FieldConfig, TaskConfig, SectionConfig, Heading } from "../Types";

export const portOfEntryChildEmploymentConfig = new TaskConfig({
  title: "Employment",
  description: "Tell us about their intent to work in Canada.",
  sections: [
    new SectionConfig({
      title: "Employment",
      body: [
        new FieldConfig({
          type: Radio,
          name: "intends_to_work_in_canada",
          label: "Do they intend to work in Canada?",
        }),
        new FieldConfig({
          type: TextInput,
          name: "prospective_occupation",
          label: "Prospective occupation in Canada",
          shouldBeVisible: (data) => data.intends_to_work_in_canada === "Yes",
        }),
      ],
    }),
  ],
});
