import { DocumentMagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { CenterModal } from "components/CenterModal";
import { useFormik } from "formik";
import { loginSchema } from "lib/FormikSchemas";
import { logUserIn, logUserOut } from "lib/Utils";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { getUrlRoot, login } from "../Api";
import { Button } from "../components/Button";

const AcceptTermsModal = ({
  isOpen,
  setIsOpen,
  email,
  password,
  onSubmitMutation,
}) => {
  const handleCancelClicked = () => {
    setIsOpen(false);
  };

  const handleConfirmClicked = () => {
    onSubmitMutation.mutate({ email, password, hasAgreedToTerms: true });
  };

  return (
    <>
      <CenterModal isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="flex justify-center">
          <div className="bg-sky-600 p-4 rounded-full">
            <DocumentMagnifyingGlassIcon className="h-10 w-10 text-white" />
          </div>
        </div>
        <div className="p-6">
          <h3 className="text-xl font-medium leading-6 text-gray-900 mt-6 mb-8">
            New Terms of Service and updated Privacy Policy
          </h3>
          <p>
            We've introduced{" "}
            <a
              href={getUrlRoot() + "terms"}
              target="_blank"
              className="text-primary hover:underline"
            >
              Terms of Service
            </a>{" "}
            and updated our{" "}
            <a
              href={getUrlRoot() + "privacy"}
              target="_blank"
              className="text-primary hover:underline"
            >
              Privacy Policy
            </a>
            . By clicking 'Accept', you confirm that you agree to both
            documents.
          </p>

          <div className="flex flex-row gap-4 w-full mx-auto">
            <Button onClick={handleCancelClicked} style="outline">
              Cancel
            </Button>
            <Button onClick={handleConfirmClicked} style="primary">
              Accept
            </Button>
          </div>
        </div>
      </CenterModal>
    </>
  );
};

export default function Login({}) {
  const queryClient = useQueryClient();

  const [error, setError] = useState(null);
  const [acceptTermsIsOpen, setAcceptTermsIsOpen] = useState(false);

  useEffect(() => {
    logUserOut();
  }, []);

  const onSubmitMutation = useMutation(
    async ({ email, password, hasAgreedToTerms }) => {
      return await login(email, password, hasAgreedToTerms);
    },
    {
      onSettled: () => queryClient.invalidateQueries("applicants"),
      onSuccess: (data) => {
        logUserIn(data.s_id, data.role, data.logo_filename);
        window.location.reload();
      },
      onError: (error) => {
        if (error.code === "MUST_AGREE_TO_TERMS") {
          setAcceptTermsIsOpen(true);
        } else {
          setError(error.message);
        }
      },
    }
  );

  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      onSubmitMutation.mutate(values);
    },
  });

  return (
    <>
      <AcceptTermsModal
        setIsOpen={setAcceptTermsIsOpen}
        isOpen={acceptTermsIsOpen}
        email={values.email}
        password={values.password}
        onSubmitMutation={onSubmitMutation}
      />
      <div className="fullscreen bg-gradient-to-r from-[#BDE2A0] to-[#6CB7EC]">
        <div className="relative card p-14 max-w-md mx-auto mt-36">
          <h2 className="text-xl font-semibold">Log in</h2>
          <form className="mt-8">
            <div className="mb-6">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                className={`shadow-sm rounded-md border ${
                  errors.email && touched.email
                    ? "border-red-400"
                    : "border-gray-400"
                } py-2 px-2 text-gray-700 focus:border- focus:outline-none focus:ring-blue-500 w-full`}
                name="email"
                type="email"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && touched.email && (
                <p className="text-sm mt-1 text-red-500">
                  {errors.email.toString()}
                </p>
              )}
            </div>
            <div className="mb-10">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                className={`shadow-sm rounded-md border ${
                  errors.password && touched.password
                    ? "border-red-400"
                    : "border-gray-400"
                } py-2 px-2 text-gray-700 focus:border-blue-500 focus:outline-none focus:ring-blue-500 w-full`}
                name="password"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.password && touched.password && (
                <p className="text-sm mt-1 text-red-500">
                  {errors.password.toString()}
                </p>
              )}
              <p className="text-right text-sm mt-2 font-light text-blue-500">
                <Link
                  tabIndex={-1}
                  to={"/reset-password"}
                  state={{ email: values.email }}
                >
                  Forgot password?
                </Link>
              </p>
            </div>

            {error && (
              <div className="border-red-400 bg-red-50 text-sm border rounded py-2 px-4 my-4 text-red-600">
                {error}
              </div>
            )}

            <Button onClick={handleSubmit} isDisabled={!isValid || !dirty}>
              Continue
            </Button>
            <div className="mt-8"></div>
          </form>
        </div>
      </div>
    </>
  );
}
