import LoadingSpinner from "components/LoadingSpinner";
import { useFormData, useSaveTask } from "lib/Hooks";
import { useQuery, useQueryClient } from "react-query";
import { getApplicant, getApplicantTasks, getTask } from "../Api";
import { renderElement } from "./PageRenderer";
import { useMatch, useNavigate } from "react-router-dom";
import { useState } from "react";
import * as React from "react";
import TASK_CONFIGS from "taskConfigs/TaskConfigs";
import { Header } from "components/Header";
import { BreadcrumbItem, Breadcrumbs } from "components/Breadcrumbs";
import { removeTaskIdPrefix } from "lib/Utils";
import { HorizontalRule } from "components/HorizontalRule";
import { Button } from "components/Button";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { ApplicantIcon } from "components/ApplicantIcon";
import getTaskConfig from "taskConfigs/TaskConfigs";

export default function Task() {
  const { params } = useMatch(":applicant_id/tasks/:task_id");
  const { applicant_id } = params;
  const TASK_ID = `T_${params.task_id}`;
  const CACHE_KEY = ["task", TASK_ID];

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const initialData = queryClient.getQueryData(CACHE_KEY)?.values || null;
  const [formData, setFormData, updateField] = useFormData(initialData);
  const [taskConfig, setTaskConfig] = useState(null);

  const { isLoading: isApplicantLoading, data: applicant } = useQuery(
    ["applicant", applicant_id],
    async () => await getApplicant(applicant_id)
  );

  const { isLoading: isTaskLoading, data: task } = useQuery(
    CACHE_KEY,
    async () => await getTask(TASK_ID),
    {
      onSuccess: (data) => {
        setFormData(data.values);
        setTaskConfig(getTaskConfig(data.key));
      },
    }
  );

  const { isLoading: isTaskListLoading, data: taskList } = useQuery(
    ["tasks"],
    async () => await getApplicantTasks(applicant_id)
  );

  const isLoading = isApplicantLoading || isTaskLoading || isTaskListLoading;
  const { onSave, submitForm } = useSaveTask(TASK_ID, formData, taskConfig);

  const getNextTask = (taskId) => {
    const index = taskList.findIndex((task) => task.task_id === taskId);
    if (index === -1) {
      console.log(`Error: Unable to find task: ${taskId}}`);
      return null;
    }

    const nextTask = taskList[index + 1];
    if (!nextTask) {
      return {
        title: "Review",
        task_id: `review/${removeTaskIdPrefix(taskList[0].task_id)}`,
      };
    }

    const taskConfig = getTaskConfig(nextTask.key);
    return {
      title: taskConfig.title,
      task_id: removeTaskIdPrefix(nextTask.task_id),
    };
  };

  return (
    <div className="max-w-3xl mx-auto">
      {isLoading || formData === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <Header>
            {applicant && taskConfig && (
              <>
                <Breadcrumbs
                  items={[
                    new BreadcrumbItem({
                      title: `${applicant.primary_applicant_name}'s application`,
                      url: "/",
                    }),
                  ]}
                />
                <div className="mt-6 flex flex-row gap-4 items-center">
                  <ApplicantIcon applicant={applicant} />

                  <h1 className="font-medium text-3xl">
                    {applicant.name}'s questionnaire
                  </h1>
                </div>
              </>
            )}
          </Header>
          <div className="w-full md:max-w-3xl autolayout px-6 border card py-6 mx-auto">
            {taskConfig && formData && (
              <>
                <div>
                  <h1 className="text-2xl mb-6">{taskConfig.title}</h1>
                  <p className="hidden">
                    Your responses will be automatically saved as you progress.
                  </p>
                </div>

                {taskConfig.sections.map((section) => {
                  return (
                    <React.Fragment key={section.title}>
                      {section.body.map((element) => {
                        return renderElement(
                          element,
                          formData,
                          updateField,
                          onSave
                        );
                      })}
                    </React.Fragment>
                  );
                })}

                {task && taskList && (
                  <>
                    <HorizontalRule />
                    <div className="flex flex-row w-full justify-between">
                      <Button
                        style="primary-outline"
                        fullWidth={false}
                        onClick={async () => {
                          await submitForm();
                          navigate(`/${applicant_id}/tasks`);
                        }}
                      >
                        Save progress
                      </Button>

                      {getNextTask(task.task_id) && (
                        <Button
                          style="primary"
                          fullWidth={false}
                          onClick={async () => {
                            await submitForm();
                            navigate(
                              `/${applicant_id}/tasks/${
                                getNextTask(task.task_id).task_id
                              }`
                            );
                          }}
                        >
                          {`Next section: ${getNextTask(task.task_id).title} `}
                          <ArrowRightIcon className="w-4 h-4" />
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
