import { getApplication } from "Api";
import { ApplicantIcon } from "components/ApplicantIcon";
import { BreadcrumbItem, Breadcrumbs } from "components/Breadcrumbs";
import { Header } from "components/Header";
import LoadingSpinner from "components/LoadingSpinner";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

export function Application() {
  const { application_id } = useParams();
  const [applicant, setApplicant] = useState(null);
  const [dependents, setDependent] = useState([]);

  const { isLoading, data: application } = useQuery(
    ["application", application_id],
    () => getApplication(application_id),
    {
      onSuccess: (data) => {
        setApplicant(data.applicant);
        setDependent(data.dependents);
      },
    }
  );

  return (
    <>
      <div className="container mx-auto">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {applicant && (
              <>
                <Header>
                  <Breadcrumbs items={[]} />
                  <div className="mb-6">
                    <h1 className="font-medium text-3xl">
                      {applicant.name}'s application
                    </h1>
                  </div>
                </Header>

                <div className="border card w-full px-8 py-6">
                  <h3>Primary applicant</h3>
                  <div className="flex flex-col gap-2 mt-6">
                    <div className="flex flex-row items-center gap-4 border border-gray-200 p-4">
                      <ApplicantIcon applicant={applicant} />
                      <h4 className="font-medium">{applicant.name}</h4>
                      <div className="grow"></div>
                      <div className="flex flex-row divide-x divide-gray-300">
                        <a
                          className="text-sm px-2 text-primary hover:text-primary-dark hover:underline cursor-pointer"
                          href={`/users/${applicant.id}/profile`}
                        >
                          Questionnaire
                        </a>
                        <a
                          className="text-sm px-2 text-primary hover:text-primary-dark hover:underline cursor-pointer"
                          href={`/users/${applicant.id}/documents`}
                        >
                          Documents
                        </a>
                      </div>
                    </div>

                    {dependents && dependents.length > 0 && (
                      <>
                        <h3 className="mt-6">Accompanying dependents</h3>
                        {dependents.map((dependent) => (
                          <>
                            <div className="flex flex-row items-center gap-4 border border-gray-200 p-4">
                              <ApplicantIcon applicant={dependent} />
                              <h4 className="font-medium">{dependent.name}</h4>
                              <div className="grow"></div>
                              <div className="flex flex-row divide-x divide-gray-300">
                                <a
                                  className="text-sm px-2 text-primary hover:text-primary-dark hover:underline cursor-pointer"
                                  href={`/users/${dependent.id}/profile`}
                                >
                                  Questionnaire
                                </a>
                                <a
                                  className="text-sm px-2 text-primary hover:text-primary-dark hover:underline cursor-pointer"
                                  href={`/users/${dependent.id}/documents`}
                                >
                                  Documents
                                </a>
                              </div>
                            </div>
                          </>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
