import { ChevronRightIcon, PlusIcon } from "@heroicons/react/24/solid";
import { Button } from "components/Button";
import LoadingSpinner from "components/LoadingSpinner";
import { Pill } from "components/Pill";
import { getInitials } from "lib/Utils";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getMe, getApplications } from "../Api";
import { InviteModal } from "components/InviteModal";
import { UserIcon, UsersIcon } from "@heroicons/react/24/outline";
import ErrorBoundary from "components/ErrorBoundary";
import { ApplicantIcon } from "components/ApplicantIcon";

function Header({ user }) {
  return (
    <div className="flex flex-row gap-4">
      <div className="flex flex-col h-16 w-16 rounded-full bg-gray-400 text-center justify-center">
        <div className="text-4xl text-white">{getInitials(user.name)}</div>
      </div>
      <div>
        <div className="text-gray-700">Hello,</div>
        <div className="text-2xl text-gray-900 font-medium mt-1">
          {user.name}
        </div>
      </div>
    </div>
  );
}

export default function ReviewerHome() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [applicants, setApplicants] = useState([]);

  const navigate = useNavigate();

  const { isLoading: isUserLoading, data: user } = useQuery(
    "reviewer_home",
    getMe
  );

  const { isLoading: isApplicationsLoading, data: applications } = useQuery(
    "applications",
    getApplications,
    {
      onSuccess: (data) => {
        setApplicants(data.map((a) => a.applicant));
      },
    }
  );

  const isLoading = isUserLoading || isApplicationsLoading;

  const handleApplicantRowClicked = (application) => {
    if (
      application.dependents &&
      application.dependents.length === 0 &&
      application.applicant &&
      application.applicant.id
    ) {
      navigate(`/users/${application.applicant.id}/profile`);
      return;
    }
    navigate(`/applications/${application.application_id}`);
  };

  if (isLoading) {
    return (
      <>
        <div className="full-screen h-28 bg-gradient-to-r from-sky-700 to-sky-500"></div>
        <div className="border card mt-10 p-6 text-gray-700">
          <LoadingSpinner />
        </div>
      </>
    );
  }

  return (
    <>
      <InviteModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      <div className="full-screen h-28 bg-gradient-to-r from-sky-700 to-sky-500"></div>
      <div className="border card mt-10 p-6 text-gray-700">
        <ErrorBoundary>
          <Header user={user} />
        </ErrorBoundary>
      </div>

      <div className="border card p-6 mt-6">
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden mt-2">
                <div className="flex flex-row items-center justify-between">
                  <h1 className="mt-0 mb-6 text-3xl">Clients</h1>
                  {applications.length > 0 && (
                    <Button
                      onClick={() => setIsModalOpen(true)}
                      fullWidth={false}
                    >
                      <PlusIcon className="h-5 w-5 text-white" /> Invite client
                    </Button>
                  )}
                </div>

                <div className="my-8 rounded-lg border-solid border border-slate-300 overflow-hidden">
                  <table className="w-full divide-y divide-slate-300">
                    <thead className="bg-slate-100">
                      <tr>
                        <th scope="col" className="table-head">
                          Name
                        </th>
                        <th scope="col" className="table-head">
                          Questionnaire
                        </th>
                        <th scope="col" className="table-head">
                          Documents
                        </th>
                        <th></th>
                      </tr>
                    </thead>

                    {applications.length === 0 && (
                      <tbody>
                        <tr>
                          <td colSpan={3}>
                            <div className="w-full p-20 mb-12 flex flex-col items-center">
                              <UsersIcon className="h-12 w-12 mb-4 text-gray-400" />
                              <h3 className="text-gray-600">No clients yet</h3>
                              <p className="mb-6 text-gray-500">
                                Get started by inviting a client
                              </p>
                              <Button
                                onClick={() => setIsModalOpen(true)}
                                fullWidth={false}
                              >
                                <PlusIcon className="h-5 w-5 text-white" />{" "}
                                Invite client
                              </Button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    )}

                    {applications.length > 0 && (
                      <tbody>
                        {applications.map((application) => (
                          <tr
                            key={application.id}
                            className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100 cursor-pointer"
                            onClick={() =>
                              handleApplicantRowClicked(application)
                            }
                          >
                            <td className="px-4 py-4 whitespace-nowrap text-gray-900">
                              <div className="flex flex-row gap-4 items-center">
                                <div className="bg-purple-100 rounded-full h-12 w-12 flex items-center justify-center">
                                  <UserIcon className="h-7 w-7 text-purple-700" />
                                </div>
                                <div className="flex flex-col">
                                  <div>
                                    <span className="font-medium">
                                      {application.applicant.name}
                                    </span>
                                  </div>
                                  <p>{application.applicant.email}</p>
                                </div>
                                <div className="grow"></div>
                                <div className="flex flex-row gap-1">
                                  {application.dependents.map((dependent) => (
                                    <ApplicantIcon
                                      key={dependent.id}
                                      applicant={dependent}
                                      size={"small"}
                                    />
                                  ))}
                                </div>
                              </div>
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              <Pill title={application.questionnaire_status} />
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              <Pill title={application.documents_status} />
                            </td>
                            <td className="text-sm whitespace-nowrap">
                              <ChevronRightIcon className="h-6 w-6 text-gray-400" />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
