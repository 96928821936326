// A read only view of a questionnaire section
import { ApplicantContext } from "contexts/contexts";
import { removeTaskIdPrefix } from "lib/Utils";
import { renderViewElement } from "pages/PageRenderer";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "./Button";

export function QuestionnaireSection({
  task,
  taskConfig,
  formData,
  type = "bare", // One of 'bare', 'lawyer', 'view' or 'review'
  nextTask = null,
}) {
  const navigate = useNavigate();
  const applicant = React.useContext(ApplicantContext);

  if (type === "bare") {
    return (
      <div className="flex flex-col divide-y text-left">
        {taskConfig.sections
          .flatMap((section) => section.body)
          .map((element) => {
            return renderViewElement(element, formData, false);
          })}
      </div>
    );
  }

  return (
    <div className="py-6 border card mt-4">
      <div className="px-6 flex justify-between">
        <h2 id={task.key} className="scroll-mt-36">
          {taskConfig.title}
        </h2>
        {type === "view" && (
          <Button
            style="primary-outline"
            onClick={() =>
              navigate(
                `/${applicant.id}/tasks/${removeTaskIdPrefix(task.task_id)}`
              )
            }
            fullWidth={false}
          >
            Edit responses
          </Button>
        )}
      </div>
      <div className="flex flex-col divide-y">
        {taskConfig.sections
          .flatMap((section) => section.body)
          .map((element) => {
            if (type === "lawyer") {
              return renderViewElement(element, formData, true);
            } else {
              return renderViewElement(element, formData, false);
            }
          })}
      </div>
      {type === "review" && (
        <div className="flex justify-end px-6 mt-4 gap-2">
          <Button
            style="primary-outline"
            onClick={() =>
              navigate(
                `/${applicant.id}/tasks/${removeTaskIdPrefix(task.task_id)}`
              )
            }
            fullWidth={false}
          >
            Edit responses
          </Button>
          {nextTask && (
            <Button
              style="primary"
              onClick={() =>
                navigate(
                  `/${applicant.id}/tasks/review/${removeTaskIdPrefix(
                    nextTask.task_id
                  )}`
                )
              }
              fullWidth={false}
            >
              Confirm and continue
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
