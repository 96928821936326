import { Dialog } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { postInviteLink } from "Api";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Button } from "./Button";
import { CenterModal } from "./CenterModal";

export function InviteModal({ isOpen, setIsOpen }) {
  const queryClient = useQueryClient();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [applicationType, setApplicationType] = useState("");
  const [inviteURL, setInviteURL] = useState("");
  const [error, setError] = useState(null);

  const handleSubmitMutation = useMutation(
    async ({ name, email, application_kind }) => {
      return await postInviteLink(name, email, application_kind);
    },
    {
      onSettled: () => queryClient.invalidateQueries("applications"),
      onSuccess: (data) => {
        setInviteURL(data.invite_url);
      },
      onError: (error) => {
        setError(error.message);
      },
    }
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Todo(Awais): I can do better with the validation here. Maybe use Formik?
    if (!name) {
      return setError("Please enter a name");
    }
    if (!email) {
      return setError("Please enter an email");
    }
    if (!email.includes("@")) {
      return setError("Please enter a valid email");
    }
    if (!applicationType) {
      return setError("Please choose an application type");
    }

    handleSubmitMutation.mutate({
      name,
      email,
      application_kind: applicationType,
    });
  };

  const handleModalClose = () => {
    setIsOpen(false);
    setError(null);
    setInviteURL("");
    setName("");
    setEmail("");
  };

  return (
    <CenterModal
      isOpen={isOpen}
      setIsOpen={handleModalClose}
      width={"w-full max-w-md"}
    >
      {inviteURL && (
        <>
          <div className="flex justify-center">
            <div className="bg-green-100 p-4 rounded-full">
              <CheckIcon className="h-12 w-12 text-green-500" />
            </div>
          </div>
          <h3 className="text-xl font-medium leading-6 text-gray-900 mt-6 mb-8">
            Link created
          </h3>
          <div className="mt-6 mb-8 text-center space-y-6">
            <p>
              Please share this link with{" "}
              <a className="underline" href={"mailto:" + email}>
                {email}
              </a>{" "}
              so they can get started on their application.
            </p>
            <p className="font-medium underline text-blue-600">{inviteURL}</p>
          </div>
          <Button onClick={handleModalClose}>Return to dashboard</Button>
        </>
      )}

      {!inviteURL && (
        <>
          <div className="flex justify-center">
            <div className="bg-blue-100 p-4 rounded-full">
              <EnvelopeIcon className="h-12 w-12 text-blue-500" />
            </div>
          </div>
          <h3 className="text-xl font-medium leading-6 text-gray-900 mt-6 mb-8">
            Invite a new client
          </h3>
          <div className="mt-2 text-left">
            <form className="w-full">
              <label htmlFor="name" className="form-label mt-8">
                Full name
              </label>
              <input
                name="name"
                type="text"
                className={
                  "shadow-sm rounded-md border border-gray-400 py-2 px-2 text-gray-700 focus:border- focus:outline-none focus:ring-blue-500 w-full"
                }
                onChange={(e) => setName(e.target.value)}
              ></input>

              <label htmlFor="email" className="form-label mt-4">
                Email
              </label>
              <input
                name="email"
                type="email"
                className={
                  "shadow-sm rounded-md border border-gray-400 py-2 px-2 text-gray-700 focus:outline-none focus:ring-blue-500 w-full"
                }
                onChange={(e) => setEmail(e.target.value)}
              ></input>

              <label htmlFor="application_type" className="form-label mt-4">
                Application type
              </label>
              <select
                name="application_type"
                className={
                  "shadow-sm rounded-md border border-gray-400 py-2 px-2 text-gray-700 focus:outline-none focus:ring-blue-500 w-full"
                }
                onChange={(e) => setApplicationType(e.target.value)}
              >
                <option value="" disabled={true} selected={true}>
                  Select an option
                </option>
                <option value="WORK_PERMIT">Work permit (filed abroad)</option>
                <option value="PORT_OF_ENTRY">
                  Work permit (filed at a port of entry)
                </option>
              </select>
            </form>
            {error && (
              <div className="border-red-400 bg-red-50 text-sm border rounded py-2 px-4 my-4 text-red-600">
                {error}
              </div>
            )}
          </div>
          <div className="px-4 mt-12 sm:flex sm:flex-row-reverse sm:px-6 gap-4 justify-center">
            <Button onClick={(e) => handleSubmit(e)} fullWidth={false}>
              Generate link
            </Button>
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => handleModalClose()}
            >
              Cancel
            </button>
          </div>
        </>
      )}
    </CenterModal>
  );
}
