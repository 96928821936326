import {
  ArrowUpIcon,
  ChevronRightIcon,
  ClipboardDocumentIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/24/outline";
import { getApplication, getMe } from "Api";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getInitials } from "lib/Utils";
import LoadingSpinner from "components/LoadingSpinner";
import { useQuery } from "react-query";
import ErrorBoundary from "components/ErrorBoundary";
import { InitialQuestions } from "./InitialQuestions";
import { ApplicantIcon } from "components/ApplicantIcon";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

function Header({ user, application, showInitialQuestions }) {
  return (
    <div className="text-gray-700">
      <div className="flex flex-row gap-4">
        <div className="flex flex-col h-16 w-16 rounded-full bg-gray-400 text-center justify-center">
          <div className="text-4xl text-white">{getInitials(user.name)}</div>
        </div>
        <div>
          {showInitialQuestions && (
            <div className="text-gray-700">Welcome,</div>
          )}
          {!showInitialQuestions && <div className="text-gray-700">Hello,</div>}

          <div className="text-2xl text-gray-900 font-medium mt-1">
            {user.name}
          </div>
        </div>
      </div>
      {!showInitialQuestions &&
        application.dependents &&
        application.dependents.length === 0 && (
          <div className="mt-6 ml-20">
            <p>
              Complete the following tasks so that your lawyer can prepare your
              application.
            </p>
          </div>
        )}
    </div>
  );
}

function QuestionnaireTab({ applicantId }) {
  const navigate = useNavigate();
  return (
    <div
      className="w-5/12 p-8 text-gray-700 cursor-pointer"
      onClick={() => navigate(`/${applicantId}/tasks`)}
    >
      <div className="flex flex-row justify-between w-full">
        <ClipboardDocumentListIcon className="h-16 w-16 p-4 text-purple-700 bg-purple-100 rounded-lg" />
        <ArrowUpIcon className="h-10 w-10 text-gray-400 rotate-45" />
      </div>
      <div className="mt-10">
        <h3 className="text-gray-900 text-xl">Fill out questionnaire</h3>
        <p className="mt-2">
          You'll be asked about your background, including your family,
          schooling, and work history.
        </p>
      </div>
    </div>
  );
}

function DocumentsTab({ applicantId }) {
  const navigate = useNavigate();
  return (
    <div
      className="w-5/12 p-8 text-gray-700 cursor-pointer"
      onClick={() => navigate(`/${applicantId}/documents`)}
    >
      <div className="flex flex-row justify-between w-full">
        <ClipboardDocumentIcon className="h-16 w-16 p-4 text-green-700 bg-green-100 rounded-lg" />
        <ArrowUpIcon className="h-10 w-10 text-gray-400 rotate-45" />
      </div>
      <div className="mt-10">
        <h3 className="text-gray-900 text-xl">Collect documents</h3>
        <p className="mt-2">
          You’ll need to provide documents like a resume, copy of your passport,
          and a digital photo of yourself.
        </p>
      </div>
    </div>
  );
}

export function Dashboard() {
  const navigate = useNavigate();
  const { isLoading: isUserLoading, data: user } = useQuery("dashboard", getMe);
  const applicationId = user && user.application_id;
  const [showInitialQuestions, setShowInitialQuestions] = useState(null);

  const { isLoading: isApplicationLoading, data: application } = useQuery({
    queryKey: ["application", applicationId],
    queryFn: async () => getApplication(user.application_id),
    enabled: !!applicationId,
    onSuccess: (data) => {
      if (data.is_started) {
        setShowInitialQuestions(false);
      } else {
        setShowInitialQuestions(true);
      }
    },
  });

  const isLoading = isUserLoading || isApplicationLoading;

  const questionnaireBlock = (dependent) => {
    return (
      <Link to={`${dependent.id}/tasks`}>
        <div className="w-36 flex flex-col items-center cursor-pointer">
          {dependent.questionnaire_status == "COMPLETE" ? (
            <CheckCircleIcon className="h-12 w-12 p-2 text-green-700 bg-green-100 rounded-lg" />
          ) : (
            <ClipboardDocumentListIcon className="h-12 w-12 p-3 text-purple-700 bg-purple-100 rounded-lg" />
          )}

          <p className="text-sm mt-2 px-2 text-primary hover:text-primary-dark hover:underline cursor-pointer">
            Questionnaire
            {dependent.questionnaire_status !== "COMPLETE" && (
              <ArrowUpIcon className="inline ml-1 h-4 w-4 text-gray-500 rotate-45" />
            )}
          </p>
        </div>
      </Link>
    );
  };

  const documentsBlock = (dependent) => {
    return (
      <Link to={`${dependent.id}/documents`}>
        <div className="w-36 flex flex-col items-center cursor-pointer">
          {dependent.documents_status == "COMPLETE" ? (
            <CheckCircleIcon className="h-12 w-12 p-2 text-green-700 bg-green-100 rounded-lg" />
          ) : (
            <ClipboardDocumentIcon className="h-12 w-12 p-3  text-green-700 bg-green-100 rounded-lg" />
          )}

          <p className="text-sm mt-2 px-2 text-primary hover:text-primary-dark hover:underline cursor-pointer">
            Documents
            {dependent.documents_status !== "COMPLETE" && (
              <ArrowUpIcon className="inline ml-1 h-4 w-4 text-gray-500 rotate-45" />
            )}
          </p>
        </div>
      </Link>
    );
  };

  if (isLoading) {
    return (
      <>
        <div className="full-screen h-28 bg-gradient-to-r from-sky-700 to-sky-500"></div>
        <div className="border card mt-10 p-6">
          <LoadingSpinner />
        </div>
      </>
    );
  }

  return (
    <ErrorBoundary>
      <>
        <div className="full-screen h-28 bg-gradient-to-r from-sky-700 to-sky-500"></div>

        <div className="border card mt-10 p-6">
          <ErrorBoundary>
            <Header
              user={user}
              application={application}
              showInitialQuestions={showInitialQuestions}
            />
          </ErrorBoundary>
        </div>

        {showInitialQuestions && (
          <ErrorBoundary>
            <InitialQuestions application={application} />
          </ErrorBoundary>
        )}

        {!showInitialQuestions && application.dependents.length == 0 && (
          <div className="mt-6 flex flex-row border card justify-center divide-x-2">
            <ErrorBoundary>
              <QuestionnaireTab applicantId={user.id} />
            </ErrorBoundary>

            <ErrorBoundary>
              <DocumentsTab applicantId={user.id} />
            </ErrorBoundary>
          </div>
        )}

        {!showInitialQuestions && application.dependents.length > 0 && (
          <div className="mt-6 flex flex-col border card px-8 pt-6 pb-12 mx-auto">
            <h3>Task list</h3>
            <p>
              Complete the following profiles so that your lawyer can prepare
              your application.
            </p>
            <div className="flex flex-col gap-8 mt-6">
              {[application.applicant]
                .concat(application.dependents)
                .map((dependent) => (
                  <div key={dependent.id} className="flex flex-row gap-8">
                    <ApplicantIcon applicant={dependent} />
                    <div className="flex flex-col gap-2">
                      <h4 className="font-medium">{dependent.name}</h4>
                      <div className="w-40 flex flex-col gap-2">
                        <Link to={`${dependent.id}/tasks`}>
                          <div className="flex flex-row gap-2 items-center cursor-pointer">
                            {dependent.questionnaire_status == "COMPLETE" ? (
                              <CheckCircleIcon className="h-6 w-6 text-green-600 -mx-[2px]" />
                            ) : (
                              <div className="h-5 w-5 border border-gray-500 border-dashed rounded-full" />
                            )}

                            <p className="text-sm text-primary hover:text-primary-dark hover:underline cursor-pointer">
                              Questionnaire
                            </p>
                            <div className="grow"></div>
                            <ChevronRightIcon className="h-4 w-4 text-gray-500" />
                          </div>
                        </Link>
                        <Link to={`${dependent.id}/documents`}>
                          <div className="flex flex-row gap-2 items-center cursor-pointer">
                            {dependent.documents_status == "COMPLETE" ? (
                              <CheckCircleIcon className="h-6 w-6 text-green-600" />
                            ) : (
                              <div className="h-5 w-5 border border-gray-500 border-dashed rounded-full" />
                            )}

                            <p className="text-sm text-primary hover:text-primary-dark hover:underline cursor-pointer">
                              Documents
                            </p>
                            <div className="grow"></div>
                            <ChevronRightIcon className="h-4 w-4 text-gray-500" />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>

                  // View with more of a table layout
                  // <div className="flex flex-row items-center gap-4 border border-gray-200 p-4">
                  //   <ApplicantIcon applicant={dependent} />
                  //   <h4 className="font-medium">{dependent.name}</h4>
                  //   <div className="grow"></div>
                  //   <div className="flex flex-row divide-x divide-gray-300">
                  //     {questionnaireBlock(dependent)}
                  //     {documentsBlock(dependent)}
                  //   </div>
                  // </div>
                ))}
            </div>
          </div>
        )}
      </>
    </ErrorBoundary>
  );
}
