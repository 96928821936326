// A config which contains one of each field type. Used for testing.
// Todo(awais): Move this to a test folder?

import { DateInput } from "components/fields/DateField";
import { TextInput } from "components/fields/TextInput";
import { FieldConfig, TaskConfig, SectionConfig } from "./Types";
import { TextArea } from "components/fields/TextArea";
import { Radio } from "components/fields/RadioField";

export const genericConfig = new TaskConfig({
  title: "Generic",
  description: "Generic page for testing",
  sections: [
    new SectionConfig({
      title: "Home",
      body: [
        new FieldConfig({
          type: TextInput,
          name: "text_input_field",
          label: "Text input field",
        }),
        new FieldConfig({
          type: TextArea,
          name: "text_area_field",
          label: "Text area field",
        }),
        new FieldConfig({
          type: DateInput,
          name: "date_input_field",
          label: "Date input field",
        }),
        new FieldConfig({
          type: Radio,
          name: "radio_field",
          label: "Radio field",
        }),
        new FieldConfig({
          type: TextInput,
          name: "text_field_conditional_label",
          label: (data) =>
            data["radio_field"] === "Yes"
              ? "Radio field answer was YES"
              : "Radio field answer was NO",
        }),
      ],
    }),
  ],
});
