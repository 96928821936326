import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import { requestDocument, updateDocumentRequest } from "Api";
import { ApplicantContext } from "contexts/contexts";
import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Button } from "./Button";
import { TextArea } from "./fields/TextArea";
import { TextInput } from "./fields/TextInput";
import { CenterModal } from "./CenterModal";

export function RequestDocumentModal({ isOpen, setIsOpen, section = null }) {
  const queryClient = useQueryClient();

  const applicant = useContext(ApplicantContext);
  const applicantId = applicant.id;

  const oldTitle = section ? section.reviewer_title : "";
  const oldDescription = section ? section.description : "";

  const [title, setTitle] = useState(oldTitle);
  const [description, setDescription] = useState(oldDescription);

  const isEditing = section !== null;

  const modalTitle = isEditing
    ? "Edit a document request"
    : "Request a new document";
  const modalButtonText = isEditing ? "Update" : "Confirm";

  const resetModal = () => {
    setTitle("");
    setDescription("");
  };

  const handleModalClosed = () => {
    resetModal();
    setIsOpen(false);
  };

  const requestDocumentMutation = useMutation(
    async ({ applicantId, title, description }) =>
      await requestDocument(applicantId, title, description),
    {
      onSettled: () =>
        queryClient.invalidateQueries(["applicant_documents", applicantId]),
    }
  );

  const updateDocumentRequestMutation = useMutation(
    async ({ documentRequestId, title, description }) =>
      await updateDocumentRequest(documentRequestId, title, description),
    {
      onSettled: () =>
        queryClient.invalidateQueries(["applicant_documents", applicantId]),
    }
  );

  const submitRequest = async (event) => {
    if (isEditing) {
      await updateDocumentRequestMutation.mutate({
        documentRequestId: section.document_request_id,
        title: title,
        description: description,
      });
      // Todo(Awais): It's super confusing that we don't want to reset the modal when in edit mode.
      // But we do need to reset it in add mode because otherwise the text doesn't clear.
      await new Promise((resolve) =>
        setTimeout(() => resolve(setIsOpen(false)), 500)
      );
    } else {
      await requestDocumentMutation.mutate({
        applicantId,
        title: title,
        description: description,
      });
      await new Promise((resolve) =>
        setTimeout(() => resolve(handleModalClosed()), 500)
      );
    }
  };

  return (
    <>
      <CenterModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        width={"w-full max-w-lg"}
      >
        <div className="flex justify-center">
          <div className="bg-blue-100 p-4 rounded-full">
            <DocumentPlusIcon className="h-12 w-12 text-blue-600" />
          </div>
        </div>

        <h3 className="text-xl font-medium leading-6 text-gray-900 mt-6">
          {modalTitle}
        </h3>

        <div className="w-full text-left mt-8">
          <label
            className="text-left w-full text-gray-700 font-medium"
            htmlFor="document_title"
          >
            Document title
          </label>
          <p className="text-sm text-gray-400 -mt-1">Max. 50 characters</p>
          <TextInput
            name="document_title"
            defaultValue={title}
            onChange={(_, value) => setTitle(value)}
            onSave={() => {}}
            className="w-full mt-2"
            maxLength={50}
          />
        </div>

        <div className="w-full text-left mt-8">
          <label
            className="text-left w-full text-gray-700 font-medium"
            htmlFor="document_description"
          >
            Document description (optional)
          </label>
          <p className="text-sm text-gray-400 -mt-1">Max. 500 characters</p>
          <TextArea
            name="document_description"
            defaultValue={description}
            onChange={(_, value) => setDescription(value)}
            onSave={() => {}}
            className="w-full mt-2"
            maxLength={500}
          />
        </div>

        <div className="w-full">
          <Button
            onClick={submitRequest}
            isDisabled={
              !title || (title === oldTitle && description === oldDescription)
            }
          >
            {modalButtonText}
          </Button>
        </div>
      </CenterModal>
    </>
  );
}
