import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

function ErrorMessage() {
  return (
    <div className="p-6 flex flex-col items-center gap-4 text-center text-gray-600">
      <div className="bg-red-100 p-3 rounded-full">
        <ExclamationTriangleIcon className="h-10 w-10 text-red-600" />
      </div>
      <p className="font-medium text-gray-700">
        Sorry! Something went wrong while loading this part of the page.
      </p>
      <p className="text-sm">
        Someone on our team has been notified. If this issue persists, please
        contact us at{" "}
        <a
          href="mailto:support@pathimmigration.ca"
          className="font-medium hover:underline cursor-pointer"
        >
          support@pathimmigration.ca
        </a>
        .
      </p>
    </div>
  );
}

function FullPageErrorMessage() {
  return (
    <div className="border card mt-24 py-6 max-w-2xl mx-auto">
      <div className="p-6 flex flex-col items-center gap-4 text-center text-gray-600">
        <div className="bg-red-100 p-3 rounded-full">
          <ExclamationTriangleIcon className="h-10 w-10 text-red-600" />
        </div>
        <p className="font-medium text-gray-700">
          Sorry! Something went wrong while loading this page.
        </p>
        <p className="text-sm">
          Someone on our team has been notified. If this issue persists, please
          contact us at{" "}
          <a
            href="mailto:support@pathimmigration.ca"
            className="font-medium hover:underline cursor-pointer"
          >
            support@pathimmigration.ca
          </a>
          .
        </p>
      </div>
    </div>
  );
}

function Wrapper({ children }) {
  return <>{children}</>;
}

export default function ErrorBoundary({ children }) {
  return (
    <SentryErrorBoundary
      fallback={<ErrorMessage />}
      onError={() => console.log("Error boundary hit!")}
    >
      <Wrapper>{children}</Wrapper>
    </SentryErrorBoundary>
  );
}

// The same as an error boundary, except it causes the component to not render at all
export function SilentErrorBoundary({ children }) {
  return (
    <SentryErrorBoundary
      fallback={<></>}
      onError={() => console.log("Error boundary hit!")}
    >
      <Wrapper>{children}</Wrapper>
    </SentryErrorBoundary>
  );
}

export function FullPageErrorBoundary({ children }) {
  return (
    <SentryErrorBoundary
      fallback={<FullPageErrorMessage />}
      onError={() => console.log("Error boundary hit!")}
    >
      <Wrapper>{children}</Wrapper>
    </SentryErrorBoundary>
  );
}
