import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useScrollPosition } from "lib/Hooks";

export class SidebarItem {
  constructor({ id, label, status, isActive }) {
    this.id = id;
    this.label = label;
    this.status = status;
    this.isActive = isActive;
  }
}

export function Sidebar({ items }) {
  const scrollPosition = useScrollPosition();

  const renderSidebarItem = (label, status, index, length) => {
    const Icon = () => {
      if (status === "COMPLETE") {
        return (
          <>
            <div className="absolute w-4 h-4 bg-white rounded-full"></div>
            <CheckCircleIcon className="absolute w-6 h-6 text-primary" />
          </>
        );
      }
      if (status === "ACTIVE") {
        return <div className="absolute w-3 h-3 bg-primary rounded-full" />;
      }
      return <div className="absolute w-3 h-3 bg-gray-400 rounded-full" />;
    };
    const Text = () => {
      if (status === "ACTIVE") {
        return <div className="py-2 text-primary font-medium">{label}</div>;
      }
      return <div className="py-2 text-gray-700">{label}</div>;
    };
    const lineHeight = () => {
      if (index === 0) {
        return "h-1/2 top-1/2";
      } else if (index === length - 1) {
        return "h-1/2 top-0";
      } else {
        return "h-full";
      }
    };

    return (
      <>
        <div className="relative w-6 grow-0 shrink-0 basis-6 flex flex-col justify-center items-center">
          <div
            className={`absolute w-0 ${lineHeight()} left-1/2 outline outline-1 outline-gray-400`}
          ></div>

          <Icon />
        </div>
        <Text />
      </>
    );
  };

  return (
    <div className={`w-44 ${scrollPosition >= 80 ? "fixed top-[48px]" : ""}`}>
      {items.map((item, index) => {
        return (
          <div
            key={`sidebar_${item.id}`}
            className="flex flex-row align-middle gap-2"
          >
            {renderSidebarItem(item.label, item.status, index, items.length)}
          </div>
        );
      })}
    </div>
  );
}
