import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import { uploadFile } from "Api";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

export function FileUpload({
  name,
  label = null,
  className = "",
  documentRequestId,
  applicantId,
  onSuccess,
  onError,
}) {
  const queryClient = useQueryClient();

  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const uploadFileMutation = useMutation(
    async ({ file }) => uploadFile(documentRequestId, file),
    {
      onSuccess: (data, variables, context) => {
        stopProgressBar(variables.intervalId, variables.filename);
      },
      onError: (error) => onError(error.message),
      onSettled: async () => {
        // For some reason, invalidation alone doesn't work so I force a manual refetch. It was difficult to debug.
        await queryClient.invalidateQueries([
          "document_checklist",
          applicantId,
        ]);
        await queryClient.fetchQuery(["document_checklist", applicantId]);
      },
    }
  );

  const startProgressBar = () => {
    setUploadProgress(10);
    const intervalId = setInterval(
      () => setUploadProgress((progress) => Math.min(80, progress + 10)),
      100
    );
    setIsUploading(true);
    return intervalId;
  };

  const stopProgressBar = (intervalId, filename) => {
    clearInterval(intervalId);
    setUploadProgress(100);
    setTimeout(() => onSuccess(filename), 500);
  };

  const handleFileUpload = async (event) => {
    const intervalId = startProgressBar();
    uploadFileMutation.mutate({
      file: event.target.files[0],
      filename: event.target.files[0].name,
      intervalId: intervalId,
    });
  };

  return (
    <div className={className}>
      {label && (
        <>
          <label htmlFor={name} className="form-label">
            {label}
          </label>
        </>
      )}

      {isUploading && (
        <div className="mt-2 mb-7 w-full text-left">
          <div className=" text-gray-600">Uploading...</div>
          <div className="relative mt-2 h-2 overflow-hidden rounded-full">
            <div
              className="absolute top-0 left-0 z-10 h-full w-full bg-blue-400 transition-transform origin-left"
              style={{ width: `${uploadProgress}%` }}
            ></div>
            <div className="absolute top-0 left-0 z-11 h-full w-full bg-gray-300"></div>
          </div>
        </div>
      )}

      {!isUploading && (
        <>
          <div className="mt-2 flex flex-row items-center px-6 py-2 rounded-md border-2 border-dashed border-blue-400 bg-blue-50">
            <CloudArrowUpIcon className="h-12 w-12 text-blue-700" />
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer text-blue-700 focus-within:outline-none  hover:text-blue-600"
            >
              <p>Click here to upload (PDF, PNG, JPG).</p>
              <p className="text-sm -mt-1">Files must be less than 20MB.</p>
              <input
                id="file-upload"
                name="file-upload"
                type="file"
                accept=".jpg,.png,application/pdf"
                className="sr-only border"
                onChange={handleFileUpload}
              />
            </label>
          </div>
        </>
      )}
    </div>
  );
}
