import { useEffect, useState } from "react";

export function TextInput({
  name,
  label = null,
  defaultValue = null,
  instructions = null,
  onChange,
  onSave,
  className = "",
  maxLength = undefined,
}) {
  const [value, setValue] = useState(defaultValue || "");

  useEffect(() => {
    setValue(defaultValue || "");
  }, [defaultValue]);

  return (
    <div className="w-full">
      {label && (
        <label htmlFor={name} className="form-label">
          {label}
        </label>
      )}
      {instructions && (
        <p className="text-sm text-gray-500 mb-2 -mt-1">{instructions}</p>
      )}
      <input
        name={name}
        id={name}
        className={`form-input ${className}`}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(name, e.target.value);
        }}
        onBlur={() => {
          onSave(name, value);
        }}
        maxLength={maxLength}
      />
    </div>
  );
}
