// I realise these are not enums yet. I will change them to enums either when we move
// to Typescript, or when I sync schemas between the frontend and backend.

export const toRadioOptions = (options) => {
  return options.map((option) => {
    return { id: option, title: option };
  });
};

export const toDropdownoptions = (options) => {
  return options.map((option) => {
    return { name: option, value: option };
  });
};

export const YES_NO_OPTIONS = ["Yes", "No"];
export const SEX_OPTIONS = ["Male", "Female", "Unknown", "Another gender"];
export const EYE_COLOUR_OPTIONS = [
  "Black",
  "Brown",
  "Grey",
  "Green",
  "Blue",
  "Hazel",
  "Other",
];
export const ABBREVIATED_MARITAL_STATUS_OPTIONS = [
  "Single",
  "Common-law",
  "Married",
];
export const MARITAL_STATUS_OPTIONS = [
  "Married",
  "Legally separated",
  "Divorced",
  "Annulled marriage",
  "Widowed",
  "Common-law",
  "Never married / Single",
];
export const RELATIONSHIP_TYPE_OPTIONS = ["Common-law", "Married"];
export const FAMILY_RELATIONSHIP_TYPE_OPTIONS = [
  "Aunt",
  "Brother",
  "Daughter",
  "Father",
  "Granddaughter",
  "Grandfather",
  "Grandmother",
  "Grandson",
  "Mother",
  "Nephew",
  "Niece",
  "Partner",
  "Sister",
  "Son",
  "Spouse",
  "Uncle",
];
export const PARENT_TYPE_OPTIONS = [
  "Mother",
  "Father",
  "Stepmother",
  "Stepfather",
  "Adoptive parent",
  "Guardian",
];
export const PARENT_AND_INLAW_TYPE_OPTIONS = [
  "Mother",
  "Father",
  "Mother-in-law",
  "Father-in-law",
];
export const SIBLING_TYPE_OPTIONS = [
  "Brother",
  "Sister",
  "Half-brother",
  "Half-sister",
  "Stepbrother",
  "Stepsister",
  "Adoptive brother",
  "Adoptive sister",
];
export const CHILDREN_TYPE_OPTIONS = [
  "Son",
  "Daughter",
  "Step-son",
  "Step-daughter",
  "Adopted son",
  "Adopted daughter",
];
export const CANADA_PROVINCES_OPTIONS = [
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Northwest Territories",
  "Nova Scotia",
  "Nunavut",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
  "Yukon",
];
export const FIELD_OF_STUDY_OPTIONS = [
  "Agriculture, Agriculture Operations and Related Sciences",
  "Architecture and Related Services",
  "Arts, General (Humanities / Social Sciences)",
  "Arts, Fine / Visual / Performing",
  "Biological and Biomedical Sciences",
  "Business, Management, Marketing and Related Support Services",
  "Business / Commerce",
  "Computing / Information technology",
  "English or French as a second language",
  "Flight Training",
  "Hospitality / Tourism",
  "Law",
  "Medicine",
  "Science, Applied",
  "Sciences, General",
  "Sciences, Health",
  "Trades / Vocational",
  "Theology / Religious studies",
  "Other",
];
export const WORK_TIME_OPTIONS = ["Full-time", "Part-time", "Mixed"];
export const EDUCATION_STANDING_OPTIONS = [
  "In good standing but no certificate or diploma or degree awarded",
  "Certificate or diploma or degree awarded",
  "Not completed",
];
export const LEVEL_OF_EDUCATION_OPTIONS = [
  "One-year program at a university, college, trade or technical school or other institute",
  "Two-year program at a university, college, trade or technical school or other institute",
  "Bachelor's degree OR a three or more year program at a university, college, trade or technical school or other",
  "Master's degree, or professional degree needed to practice in a licensed profession",
  "Doctoral level university degree (PhD)",
];
export const ECA_ORGANIZATION_OPTIONS = [
  "Canadian Architectural Certification Board (CACB)",
  "Comparative Education Service - University of Toronto School of Continuing Studies",
  "International Credential Assessment Service of Canada",
  "International Credential Evaluation Service",
  "International Qualifications Assessment Service",
  "Medical Council of Canada",
  "Pharmacy Examining Board of Canada",
  "World Education Services (WES)",
];
export const LANGUAGES_AVAILABLE_OPTIONS = [
  "English",
  "French",
  "Both",
  "Neither",
];
export const ENGLISH_OR_FRENCH_OPTIONS = ["English", "French"];
export const ENGLISH_LANGUAGE_EXAM_OPTIONS = [
  "IELTS General Training",
  "CELPIP-G",
];
export const FRENCH_LANGUAGE_EXAM_OPTIONS = ["TEF", "TFC"];
export const PURPOSE_OF_TRAVEL_OPTIONS = [
  "Business",
  "Education",
  "Training",
  "Tourism",
  "Research",
  "Visiting family",
  "Other",
];
export const ACTIVITY_TYPE_OPTIONS = [
  "Affiliation with an organization",
  "Military / Paramilitary service",
  "Parental leave",
  "Study",
  "Unemployment",
  "Volunteering",
  "Work",
  "Other",
];
export const RESIDENCE_STATUS = [
  "Citizen",
  "Permanent resident",
  "Work permit",
  "Study permit",
  "Visitor",
  "No status",
  "Other",
];
export const NAME_TYPES = [
  "Legal",
  "Assumed",
  "Nickname",
  "Maiden name",
  "Name at birth",
];
