import { HomeIcon } from "@heroicons/react/24/solid";
import * as React from "react";

export class BreadcrumbItem {
  constructor({ title, url }) {
    this.title = title;
    this.url = url;
  }
}

export function Breadcrumbs({ items }) {
  return (
    <div className="flex flex-row items-start gap-4 mb-2">
      <a href="/">
        <HomeIcon className="h-5 w-5 text-gray-400 hover:text-gray-500" />
      </a>

      {items.map((item) => (
        <React.Fragment key={item.title}>
          <div className="text-gray-400">/</div>
          <a
            className="text-sm text-gray-500 hover:text-gray-700 hover:underline cursor-pointer"
            href={item.url}
          >
            {item.title}
          </a>
        </React.Fragment>
      ))}
    </div>
  );
}
