import {
  Dropdown,
  CountryDropdown,
  CitizenshipDropdown,
} from "components/fields/DropdownField";
import { DateInput } from "components/fields/DateField";
import { Radio } from "components/fields/RadioField";
import { TableField } from "components/fields/TableField";
import { TextInput } from "components/fields/TextInput";
import {
  FieldConfig,
  Text,
  TaskConfig,
  SectionConfig,
  Heading,
} from "../Types";
import { NAME_TYPES, RESIDENCE_STATUS, toDropdownoptions } from "lib/Enums";
import { ModalTableField } from "components/fields/ModalTableField";
import { TextArea } from "components/fields/TextArea";

export const workPermitPartnerPersonalInformationConfig = new TaskConfig({
  title: "Personal information",
  description:
    "Tell us some basic information (contact details, citizenship, etc…) about your partner.",
  sections: [
    new SectionConfig({
      title: "Residential address",
      body: [
        new Heading({
          text: "Residential address",
        }),
        new FieldConfig({
          type: TextInput,
          name: "residential_street_address",
          label: "Street address",
          width: "w-96",
        }),
        new FieldConfig({
          type: TextInput,
          name: "residential_apt_number",
          label: "Apartment / Unit number (if applicable)",
          width: "w-48",
        }),
        new FieldConfig({
          type: TextInput,
          name: "residential_city",
          label: "City / Town",
        }),
        new FieldConfig({
          type: TextInput,
          name: "residential_region",
          label: "District / Region (if applicable)",
        }),
        new FieldConfig({
          type: TextInput,
          name: "residential_province",
          label: "Province / State (if applicable)",
        }),
        new FieldConfig({
          type: CountryDropdown,
          name: "residential_country",
          label: "Country / Territory",
        }),
        new FieldConfig({
          type: TextInput,
          name: "residential_postcode",
          label: "Postal / ZIP code (if applicable)",
          width: "w-48",
        }),
      ],
    }),
    new SectionConfig({
      title: "Mailing address", // Maybe I'll decide to hide section headings on applicant view?
      body: [
        new FieldConfig({
          type: Radio,
          name: "mailing_address_same_as_residential",
          label: "Do they use this address as their mailing address?",
        }),
        new Heading({
          text: "Mailing address",
          shouldBeVisible: (data) =>
            data.mailing_address_same_as_residential === "No",
        }),
        new FieldConfig({
          type: TextInput,
          name: "street_address",
          label: "Street address",
          width: "w-96",
          shouldBeVisible: (data) =>
            data.mailing_address_same_as_residential === "No",
        }),
        new FieldConfig({
          type: TextInput,
          name: "apt_number",
          label: "Apartment / Unit number (if applicable)",
          width: "w-48",
          shouldBeVisible: (data) =>
            data.mailing_address_same_as_residential === "No",
        }),
        new FieldConfig({
          type: TextInput,
          name: "city",
          label: "City / Town",
          shouldBeVisible: (data) =>
            data.mailing_address_same_as_residential === "No",
        }),
        new FieldConfig({
          type: TextInput,
          name: "region",
          label: "District / Region (if applicable)",
          shouldBeVisible: (data) =>
            data.mailing_address_same_as_residential === "No",
        }),
        new FieldConfig({
          type: TextInput,
          name: "province",
          label: "Province / State (if applicable)",
          shouldBeVisible: (data) =>
            data.mailing_address_same_as_residential === "No",
        }),
        new FieldConfig({
          type: CountryDropdown,
          name: "country",
          label: "Country / Territory",
          shouldBeVisible: (data) =>
            data.mailing_address_same_as_residential === "No",
        }),
        new FieldConfig({
          type: TextInput,
          name: "postcode",
          label: "Postal / ZIP code (if applicable)",
          width: "w-48",
          shouldBeVisible: (data) =>
            data.mailing_address_same_as_residential === "No",
        }),
      ],
    }),
    new SectionConfig({
      title: "Telephone number",
      body: [
        new FieldConfig({
          type: TextInput,
          name: "phone_number",
          label: "Telephone number",
        }),
      ],
    }),
    new SectionConfig({
      title: "Occupation",
      body: [
        new FieldConfig({
          type: TextInput,
          name: "intended_occupation",
          label: "What occupation do they intend to have in Canada?",
        }),
      ],
    }),
    new SectionConfig({
      title: "Past residences",
      body: [
        new FieldConfig({
          type: Radio,
          name: "has_lived_in_another_country",
          label: "Have they ever lived in another country?",
        }),
        new FieldConfig({
          type: ModalTableField,
          name: "past_countries_of_residence",
          label: "Previous countryies of residence",
          itemName: "country",
          tableColumns: ["start_date", "end_date", "country", "status"],
          shouldBeVisible: (data) =>
            data.has_lived_in_another_country === "Yes",
          fields: [
            new FieldConfig({
              type: CountryDropdown,
              name: "country",
              label: "Country",
            }),
            new FieldConfig({
              type: Dropdown,
              name: "status",
              label: "Status in the country",
              options: toDropdownoptions(RESIDENCE_STATUS),
            }),
            new FieldConfig({
              type: TextArea,
              name: "status_other",
              label: "Please describe their status in the country",
              width: "w-full",
              shouldBeVisible: (data) => data.status === "Other",
            }),
            new Heading({
              text: "Duration of their stay",
            }),
            new FieldConfig({
              type: DateInput,
              name: "start_date",
              label: "From",
              dateType: "day-optional",
            }),
            new FieldConfig({
              type: DateInput,
              name: "end_date",
              label: "To",
              dateType: "day-optional",
            }),
          ],
        }),
      ],
    }),
    new SectionConfig({
      title: "Citizenships",
      body: [
        new FieldConfig({
          type: ModalTableField,
          label: "List their countries of citizenship",
          name: "citizenships",
          width: "w-full",
          itemName: "citizenship",
          tableColumns: ["start_date", "end_date", "country_of_citizenship"],
          fields: [
            new FieldConfig({
              type: CitizenshipDropdown,
              name: "country_of_citizenship",
              label: "Country / Territory",
            }),
            new FieldConfig({
              type: DateInput,
              name: "start_date",
              label: "From",
              dateType: "day-optional",
            }),
            new FieldConfig({
              type: DateInput,
              name: "end_date",
              label: "To",
              dateType: "day-optional",
              checkbox: {
                value: "Present",
                label: "They are currently a citizen here",
              },
            }),
          ],
        }),
        new FieldConfig({
          type: Radio,
          name: "eligible_for_other_citizenships",
          label:
            "Are they potentially eligible for citizenship elsewhere? (E.g. through ancestry, Israel's Law of Return etc.)",
        }),
        new FieldConfig({
          type: TextArea,
          name: "eligible_for_other_citizenships_detail",
          label: "Please elaborate",
          width: "w-full",
          shouldBeVisible: (data) =>
            data["eligible_for_other_citizenships"] === "Yes",
        }),
      ],
    }),
    new SectionConfig({
      title: "Past names",
      body: [
        new FieldConfig({
          type: Radio,
          name: "has_past_names",
          label:
            "Have they ever used any other name (nickname, maiden name, alias, etc.)",
        }),
        new FieldConfig({
          type: TableField,
          name: "past_names",
          label: "Other name(s)",
          width: "w-full",
          children: (
            <>
              <TextInput name="past_full_name" label="Other name(s)" />
              <Dropdown
                name="past_name_type"
                label="Type of name"
                options={toDropdownoptions(NAME_TYPES)}
              />
            </>
          ),
          shouldBeVisible: (data) => data["has_past_names"] === "Yes",
        }),
      ],
    }),
  ],
});
