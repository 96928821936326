import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Button } from "components/Button";
import { ConfirmDeleteModal } from "components/ConfirmDeleteModal";
import { CenterModal } from "components/CenterModal";
import { useTabularData } from "lib/Hooks";
import { renderElement } from "pages/PageRenderer";
import * as React from "react";
import { useState } from "react";
import { DateInput } from "./DateField";
import { FieldConfig } from "taskConfigs/Types";

export function ModalTableField({
  name,
  label,
  instructions = null,
  defaultValue = null,
  onChange,
  onSave,
  tableColumns,
  itemName,
  fields,
}) {
  const columns = fields.map((field) => {
    return {
      name: field.name,
      label: field.label,
      isDateField: field.type === DateInput,
    };
  });
  const columnsInTable = tableColumns.map((column) => {
    return columns.find((col) => col.name === column);
  });

  const nullRow = Object.assign(
    {},
    ...columns.map((column) => ({ [column.name]: null }))
  );
  const { data, setTabularData, setTabularRow } = useTabularData(
    defaultValue || [],
    fields
  );

  React.useEffect(() => {
    setTabularData(defaultValue || []);
  }, [defaultValue]);

  const [activeIndex, setActiveIndex] = useState(null);
  const [activeRow, setActiveRow] = useState(nullRow);
  const [modalHeading, setModalHeading] = useState("");
  const [modalSaveButtonLabel, setModalSaveButtonLabel] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const addRow = () => {
    setModalHeading(`Add ${itemName || label}`);
    setModalSaveButtonLabel("Add");
    setActiveRow(nullRow);
    setActiveIndex(data.length);
    setIsModalOpen(true);
  };

  const editRow = (index) => {
    setModalHeading(`Edit ${itemName || label}`);
    setModalSaveButtonLabel("Save");
    setActiveRow(data[index]);
    setActiveIndex(index);
    setIsModalOpen(true);
  };

  const saveRow = (index) => {
    const result = setTabularRow(index, activeRow);
    onChange(name, result);
    onSave(name, result);
    handleModalClose(false);
  };

  const initateDeleteRow = (index) => {
    setIndexToDelete(index);
    setIsDeleteModalOpen(true);
  };

  const deleteRow = () => {
    const result = data
      .slice(0, indexToDelete)
      .concat(data.slice(indexToDelete + 1, data.length));
    setTabularData(result);
    onChange(name, result);
    onSave(name, result);
  };

  const handleModalClose = (value) => {
    if (!value) {
      setActiveIndex(null);
    }
    setIsModalOpen(value);
  };

  const onFieldChange = (fieldName, value) => {
    setActiveRow((currRow) => {
      return { ...currRow, [fieldName]: value };
    });
  };

  const onFieldSave = (fieldName, value) => {
    return; // We don't react to individual fields being saved / blurred, only the entire row
  };

  return (
    <>
      {indexToDelete !== null && (
        <ConfirmDeleteModal
          isOpen={isDeleteModalOpen}
          setIsOpen={setIsDeleteModalOpen}
          onConfirm={deleteRow}
        >
          <p className="mt-4 text-gray-600">
            You won't be able to undo this action.
          </p>
        </ConfirmDeleteModal>
      )}
      <CenterModal
        isOpen={isModalOpen}
        setIsOpen={handleModalClose}
        width={"w-full max-w-2xl"}
      >
        <div className="autolayout bg-white pb-16 px-16 text-left">
          <div className="mt-3 sm:mt-0 sm:text-left text-center w-full">
            <Dialog.Title
              as="h3"
              className="w-full text-lg text-center font-medium leading-6 text-gray-900"
            >
              {modalHeading}
            </Dialog.Title>
          </div>
          {fields.map((field) => {
            return renderElement(field, activeRow, onFieldChange, onFieldSave);
          })}

          <div className="w-1/2 mx-auto flex justify-center">
            <Button onClick={() => saveRow(activeIndex)}>
              {modalSaveButtonLabel}
            </Button>
          </div>
        </div>
      </CenterModal>
      <div className="w-full">
        {instructions && (
          <p className="text-sm text-gray-800 mb-2">{instructions}</p>
        )}
        {label && <p className="form-label">{label}</p>}
        <table className="w-full border-b">
          <thead className="bg-neutral-300">
            <tr className="text-neutral-900 text-sm border-b">
              {columnsInTable.map((column) => {
                return (
                  <td key={`heading_${column.name}`} className="py-2 px-2">
                    {column.label}
                  </td>
                );
              })}
              <td></td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => {
              return (
                <tr
                  key={`${index}.${name}`}
                  className="text-gray-900 bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                >
                  {columnsInTable.map((column) => {
                    return (
                      <td
                        key={`${index}.${column.name}`}
                        className={`py-1 px-2 ${
                          column.isDateField
                            ? "whitespace-nowrap"
                            : "whitespace-normal"
                        } `}
                      >
                        {column.isDateField
                          ? row[column.name]?.replaceAll("?", "X") || ""
                          : row[column.name]}
                      </td>
                    );
                  })}
                  <td
                    className="text-sm pr-2 text-neutral-500 underline hover:text-neutral-900 hover:font-medium cursor-pointer"
                    onClick={() => {
                      editRow(index);
                    }}
                  >
                    Edit
                  </td>
                  <td
                    className="text-sm text-neutral-500 underline hover:text-neutral-900 hover:font-medium cursor-pointer"
                    onClick={() => {
                      initateDeleteRow(index);
                    }}
                  >
                    Delete
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div
          className="text-sm inline-block mt-2 text-neutral-500 underline hover:text-neutral-900 hover:font-medium cursor-pointer"
          onClick={() => {
            addRow();
          }}
        >
          Add row
        </div>
      </div>
    </>
  );
}

export function ModalTableFieldDisplay({
  element,
  label,
  instructions,
  value: tabularData,
  renderNulls = true,
}) {
  const renderNullField = (element, index, field) => {
    if (renderNulls) {
      <div
        key={`${element.name}_${index}_${field.name}`}
        className="flex flex-row py-2 text-sm"
      >
        <div className="w-1/2 px-2 text-gray-500 pr-6">{field.label}</div>
        <div className="w-1/2 text-neutral-400 font-light text-sm">
          Not asked
        </div>
        ;{" "}
      </div>;
    } else {
      return <></>;
    }
  };

  return (
    <div key={element.name} className="px-6 py-3">
      <div className=" text-gray-600 font-semibold text-sm">{label}</div>
      <div className="text-gray-500 text-sm">{element.instructions}</div>

      {(!tabularData || tabularData.length == 0) && (
        <div className="mt-2 text-sm rounded-sm border-solid border border-slate-200 divide-y">
          <div className="p-2 text-sm text-neutral-500">No data entered</div>
        </div>
      )}
      {tabularData && tabularData.length > 0 && (
        <div className="mt-2 flex flex-col gap-y-4">
          {tabularData.map((row, index) => {
            return (
              <div
                key={`${element.name}_${index}`}
                className="mt-2 text-sm rounded-sm border-solid border border-slate-200 divide-y"
              >
                {element.fields.map((field) => {
                  if (field instanceof FieldConfig) {
                    if (field.shouldBeVisible(tabularData[index])) {
                      return (
                        <div
                          key={`${element.name}_${index}_${field.name}`}
                          className="flex flex-row py-2 text-sm"
                        >
                          <div className="w-1/2 px-2 text-gray-500 pr-6">
                            {field.label}
                          </div>
                          <div className="w-1/2 text-gray-800">
                            {row[field.name] ? (
                              field.type === DateInput ? (
                                row[field.name].replaceAll("?", "X")
                              ) : (
                                row[field.name]
                              )
                            ) : (
                              <span className="font-light text-neutral-400">
                                Not entered
                              </span>
                            )}
                          </div>
                        </div>
                      );
                    } else {
                      renderNullField(element, index, field);
                    }
                  }
                })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
