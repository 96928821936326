import { DateInput } from "components/fields/DateField";
import { CountryDropdown } from "components/fields/DropdownField";
import { ModalTableField } from "components/fields/ModalTableField";
import { TextInput } from "components/fields/TextInput";
import { FieldConfig, Text, TaskConfig, SectionConfig } from "../Types";

export const workPermitPrimaryEmploymentConfig = new TaskConfig({
  title: "Employment",
  description:
    "Tell us about your work history in the last 10 years or since your 18th birthday, whichever is most recent.",
  sections: [
    new SectionConfig({
      title: "Employment history",
      body: [
        new FieldConfig({
          type: ModalTableField,
          name: "employment_history",
          label: null,
          instructions:
            "Provide details of your work history, including paid internships and any government positions, in the last 10 years (or since the age of 18 if this was less than 10 years ago).",
          itemName: "job",
          tableColumns: [
            "start_date",
            "end_date",
            "job_title",
            "employer_name",
            "city",
          ],
          fields: [
            new FieldConfig({
              type: DateInput,
              name: "start_date",
              label: "From",
              dateType: "day-excluded",
            }),
            new FieldConfig({
              type: DateInput,
              name: "end_date",
              label: "To",
              dateType: "day-excluded",
              checkbox: {
                value: "Present",
                label: "I currently work here",
              },
            }),
            new FieldConfig({
              type: TextInput,
              name: "job_title",
              label: "Job title",
              width: "w-96",
            }),
            new FieldConfig({
              type: TextInput,
              name: "employer_name",
              label: "Employer name",
              width: "w-96",
            }),
            new FieldConfig({
              type: TextInput,
              name: "city",
              label: "City / Town",
            }),
            new FieldConfig({
              type: TextInput,
              name: "province",
              label: "Province / State (if applicable)",
            }),
            new FieldConfig({
              type: CountryDropdown,
              name: "country",
              label: "Country / Territory",
            }),
          ],
        }),
      ],
    }),
  ],
});
