import {
  CheckBadgeIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

export function ApplicantIcon({ applicant, size = "normal", status = null }) {
  const bgSize = size === "small" ? "h-8 w-8" : "h-12 w-12";
  const iconSize = size === "small" ? "h-5 w-5" : "h-7 w-7";
  const cornerSize = size === "small" ? "rounded-full" : "rounded-md";

  if (status === "COMPLETE") {
    return <CheckBadgeIcon className={`${bgSize} text-green-600`} />;
  }

  switch (applicant.relation) {
    case "PRIMARY":
      return (
        <div
          className={`${bgSize} ${cornerSize} bg-purple-100 flex items-center justify-center`}
        >
          <UserIcon className={`${iconSize} text-purple-700`} />
        </div>
      );
    case "PARTNER":
      return (
        <div
          className={`${bgSize} ${cornerSize} bg-yellow-100 flex items-center justify-center`}
        >
          <UserIcon className={` ${iconSize} text-yellow-700`} />
        </div>
      );
    case "CHILD":
      return (
        <div
          className={`${bgSize} ${cornerSize} bg-green-100 flex items-center justify-center`}
        >
          <UsersIcon className={` ${iconSize} text-green-700`} />
        </div>
      );
  }
}
