import { toRadioOptions, YES_NO_OPTIONS } from "lib/Enums";
import { useEffect, useState } from "react";

export function Radio({
  name,
  label = null,
  defaultValue = null,
  instructions = null,
  onChange,
  onSave,
  options = toRadioOptions(YES_NO_OPTIONS),
}) {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div>
      {label && (
        <label className="form-label" htmlFor={name}>
          {label}
        </label>
      )}
      {instructions && (
        <p className="text-sm text-gray-500 mb-2 -mt-1">{instructions}</p>
      )}
      <fieldset className="mt-2">
        <div className="space-y-2">
          {options.map((option) => (
            <div key={`${name}_${option.id}`} className="flex items-center">
              <input
                id={option.id}
                name={name}
                type="radio"
                checked={option.id === value}
                onChange={(event) => {
                  setValue(event.currentTarget.id);
                  onChange(name, event.currentTarget.id);
                  onSave(name, event.currentTarget.id);
                }}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <label
                htmlFor={option.id}
                className="ml-3 block text-sm text-gray-700"
              >
                {option.title}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
}
