import {
  Dropdown,
  CountryDropdown,
  CitizenshipDropdown,
} from "components/fields/DropdownField";
import { DateInput } from "components/fields/DateField";
import { TextInput } from "components/fields/TextInput";
import { FieldConfig, TaskConfig, SectionConfig } from "../Types";
import { MARITAL_STATUS_OPTIONS, toDropdownoptions } from "lib/Enums";
import { ModalTableField } from "components/fields/ModalTableField";

export const portOfEntryPrimaryPersonalInformationConfig = new TaskConfig({
  title: "Personal information",
  description:
    "Tell us some basic information (contact details, citizenship, etc…) about yourself.",
  sections: [
    new SectionConfig({
      title: "Personal information",
      body: [
        new FieldConfig({
          type: TextInput,
          name: "full_name",
          label: "Full name",
          width: "w-96",
        }),
        new FieldConfig({
          type: TextInput,
          name: "full_name_in_native_script",
          label:
            "Full name in native script in unicode (if different than above)",
          width: "w-full",
        }),
        new FieldConfig({
          type: DateInput,
          name: "date_of_birth",
          label: "Date of birth",
        }),
        new FieldConfig({
          type: TextInput,
          name: "city_of_birth",
          label: "City of birth",
          width: "w-96",
        }),
        new FieldConfig({
          type: CountryDropdown,
          name: "country_of_birth",
          label: "Country of birth",
        }),
      ],
    }),
    new SectionConfig({
      title: "Marital status",
      body: [
        new FieldConfig({
          type: Dropdown,
          name: "marital_status",
          label: "Marital status",
          options: toDropdownoptions(MARITAL_STATUS_OPTIONS),
        }),
      ],
    }),
    new SectionConfig({
      title: "Citizenships",
      body: [
        new FieldConfig({
          type: ModalTableField,
          label: "List your countries of citizenship",
          name: "citizenships",
          width: "w-full",
          itemName: "citizenship",
          tableColumns: ["start_date", "end_date", "country_of_citizenship"],
          fields: [
            new FieldConfig({
              type: CitizenshipDropdown,
              name: "country_of_citizenship",
              label: "Country / Territory",
            }),
            new FieldConfig({
              type: DateInput,
              name: "start_date",
              label: "From",
              dateType: "day-optional",
              checkbox: {
                value: "Since birth",
                label: "Since birth",
              },
            }),
            new FieldConfig({
              type: DateInput,
              name: "end_date",
              label: "To",
              dateType: "day-optional",
              checkbox: {
                value: "Present",
                label: "I am currently a citizen here",
              },
            }),
          ],
        }),
      ],
    }),

    new SectionConfig({
      title: "Arrival in Canada",
      body: [
        new FieldConfig({
          type: DateInput,
          name: "intended_arrival_in_canada",
          label: "Intended date of arrival in Canada",
          dateType: "day-optional",
        }),
      ],
    }),
  ],
});
