import { Link, useLocation, useNavigate } from "react-router-dom";
import { classNames, getAuth, logUserOut } from "lib/Utils";
import { logout } from "Api";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";

const reviewerNavigation = [{ name: "Home", href: "/" }];

const applicantNavigation = [{ name: "Home", href: "/" }];

const nullNavigation = [];

export default function Nav({ logoFilename }) {
  const { token, role } = getAuth();
  const location = useLocation();
  const navigate = useNavigate();

  async function handleLogout(event, token) {
    event.preventDefault();
    try {
      await logout(token);
    } catch {}

    logUserOut();
    navigate("/");
    window.location.reload();
  }

  const navigation = () => {
    switch (role) {
      case "reviewer":
        return reviewerNavigation;
      case "applicant":
        return applicantNavigation;
      default:
        return nullNavigation;
    }
  };

  const activeTab = () => {
    if (location.pathname === "/") {
      return role === "reviewer" ? "Home" : "Home";
    } else if (location.pathname.startsWith("/tasks")) {
      return "Questionnaire";
    } else if (location.pathname.startsWith("/documents")) {
      return "Documents";
    }
  };

  return (
    <>
      <div className="w-full h-20 bg-gradient-to-r from-sky-700 to-sky-500">
        <div className="container mx-auto px-2">
          <div className="flex h-16 justify-between">
            <div className="w-full mt-4 flex">
              <div className="flex flex-shrink-0 items-center">
                <Link to="/">
                  <img
                    src={`${process.env.PUBLIC_URL}/static/${logoFilename}`}
                    className="w-28"
                  />
                </Link>
              </div>
              <div className="w-full flex justify-between">
                <div className="-my-px ml-6 flex space-x-8">
                  {navigation().map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.name === activeTab()
                          ? "border-white text-white"
                          : "border-transparent text-blue-200 hover:text-blue-100 hover:border-blue-100",
                        "inline-flex items-center px-1 pt-1 border-b-2"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="flex items-center gap-6">
                  <div>
                    <Tooltip
                      anchorSelect="#contact_info"
                      place={"bottom"}
                      variant={"light"}
                      openOnClick={true}
                      clickable={true}
                      className="max-w-md z-20 shadow-md border border-slate-200"
                    >
                      <div className="p-4 text-left flex flex-col gap-2">
                        <p className="">Customer support</p>
                        <div className="text-base font-medium">
                          <a
                            className="text-blue-600 underline"
                            href="mailto:support@pathimmigration.ca"
                          >
                            support@pathimmigration.ca
                          </a>
                          <p>1-888-817-0079</p>
                        </div>
                        <p>Monday - Friday, 9am - 8pm ET</p>
                      </div>
                    </Tooltip>
                    <QuestionMarkCircleIcon
                      id={"contact_info"}
                      className="w-8 h-8 text-blue-100 cursor-pointer noselect outline-none"
                    />
                  </div>
                  <a
                    href="/"
                    className="text-white"
                    onClick={(e) => {
                      handleLogout(e, token);
                    }}
                  >
                    Log out
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
