import * as yup from "yup";

export const signupSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address.")
    .required("Please enter an email address."),
  password: yup
    .string()
    .min(8, "Your password must be at least 8 characters long.")
    .required("Please enter a password."),
  hasAgreedToTerms: yup
    .boolean()
    .oneOf(
      [true],
      "You must agree to Path's Terms of Service and Privacy Policy in order to access this service."
    ),
});

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address.")
    .required("Please enter an email address."),
  password: yup.string().required("Please enter a password."),
  hasAgreedToTerms: yup
    .boolean()
    .oneOf(
      [true],
      "You must agree to Path's Terms of Service and Privacy Policy in order to access this service."
    ),
});
