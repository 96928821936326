import { toRadioOptions, YES_NO_OPTIONS } from "lib/Enums";
import { useEffect, useState } from "react";
import { TextArea } from "./TextArea";

// Handle both the old and new format of values for this field
const processDefaultValue = (defaultValue) => {
  if (typeof defaultValue === "string") {
    return { value: defaultValue, details: "" };
  } else if (defaultValue instanceof Object) {
    return defaultValue;
  }
  return { value: "", details: "" };
};

export function AdmissibilityQuestion({
  name,
  label = null,
  defaultValue = null,
  instructions = null,
  onChange,
  onSave,
}) {
  const options = toRadioOptions(YES_NO_OPTIONS);

  const [value, setValue] = useState(processDefaultValue(defaultValue).value);
  const [details, setDetails] = useState(
    processDefaultValue(defaultValue).details
  );

  useEffect(() => {
    setValue(processDefaultValue(defaultValue).value);
    setDetails(processDefaultValue(defaultValue).details);
  }, [defaultValue]);

  const handleRadioButtonChange = (event) => {
    setValue(event.currentTarget.id);
    if (event.currentTarget.id === "Yes") {
      onChange(name, { value: event.currentTarget.id, details: details });
      onSave(name, { value: event.currentTarget.id, details: details });
    } else if (event.currentTarget.id === "No") {
      onChange(name, { value: event.currentTarget.id, details: "" });
      onSave(name, { value: event.currentTarget.id, details: "" });
    } else {
      throw new Error(`Invalid value \"${event.target.id}\" for radio button`);
    }
  };

  const handleDetailsChange = (fieldName, text) => {
    console.assert(
      value == "Yes",
      "Details should only be provided if value is Yes"
    );
    setDetails(text);
    onChange(name, { value: value, details: text });
  };

  const handleDetailsSave = (fieldName, text) => {
    onSave(name, { value: value, details: text });
  };

  return (
    <div className="mb-8">
      <div className="flex justify-between">
        {label && (
          <label className="form-label" htmlFor={name}>
            {label}
          </label>
        )}
        {instructions && (
          <p className="text-sm text-gray-500 mb-2 -mt-1">{instructions}</p>
        )}
        <fieldset className="ml-4">
          <div className="flex justify-between space-x-6">
            {options.map((option) => (
              <div key={`${name}_${option.id}`} className="flex items-center">
                <input
                  id={option.id}
                  name={name}
                  type="radio"
                  checked={option.id === value}
                  onChange={(event) => handleRadioButtonChange(event)}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <label
                  htmlFor={option.id}
                  className="ml-2 block text-sm text-gray-700"
                >
                  {option.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
      </div>
      {value === "Yes" && (
        <>
          <p className="mt-2 text-sm text-gray-600">
            Please provide your lawyer with more information.
          </p>
          <TextArea
            name={`${name}_details`}
            defaultValue={details}
            onChange={handleDetailsChange}
            onSave={handleDetailsSave}
            className="w-full mt-2"
          />
        </>
      )}
    </div>
  );
}

// Render function to display this field in the lawyer view
export function AdmissibilityQuestionDisplay({
  element,
  label,
  instructions,
  value,
}) {
  const _value = processDefaultValue(value);

  return (
    <div>
      <div key={element.name} className="px-6 flex flex-row py-3">
        <div className="w-1/2 text-gray-500 text-sm pr-6">{label}</div>
        <div className="w-1/2 text-gray-800 text-sm">
          {_value?.value?.toString() || (
            <span className="font-light text-neutral-400">Not entered</span>
          )}
        </div>
      </div>
      {_value.value === "Yes" && (
        <div
          key={`${element.name}_details`}
          className="px-6 flex flex-row py-3"
        >
          <div className="w-1/2 text-gray-500 text-sm pr-6">
            Please provide more information
          </div>
          <div className="w-1/2 text-gray-800 text-sm">
            {_value?.details?.toString() || (
              <span className="font-light text-neutral-400">Not entered</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
