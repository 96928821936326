import { useEffect, useState } from "react";

export function HeightField({
  name,
  label,
  defaultValue = null,
  onChange,
  onSave,
}) {
  const nullValue = {
    feet: "",
    inches: "",
  };
  const [value, setValue] = useState(defaultValue || nullValue);

  useEffect(() => {
    setValue(defaultValue || nullValue);
  }, [defaultValue]);

  const updateSubfield = (subfield, score) => {
    setValue((prevValue) => {
      return { ...prevValue, [subfield]: score };
    });

    let result = value;
    result[subfield] = score;
    onChange(name, result);
    onSave(name, result);
  };

  return (
    <div>
      <label htmlFor={name} className="form-label">
        {label}
      </label>

      <div className="flex items-end">
        <input
          name={name}
          id={name}
          className={`form-input w-8`}
          value={value["feet"]}
          onChange={(e) => {
            updateSubfield("feet", e.target.value);
          }}
        />
        <div className="ml-1 mr-3 text-sm">ft</div>
        <input
          name={name}
          id={name}
          className={`form-input w-8`}
          value={value["inches"]}
          onChange={(e) => {
            updateSubfield("inches", e.target.value);
          }}
        />
        <div className="ml-1 mr-3 text-sm">in</div>
        <div className="ml-1 mr-3 text-sm">e.g. 5ft, 8in</div>
      </div>
    </div>
  );
}

export function HeightFieldDisplay({ element, label, instructions, value }) {
  return (
    <div key={element.name} className="px-6 py-3">
      <div className=" text-gray-600 font-semibold text-sm">{label}</div>

      {(!value || value.length == 0) && (
        <div className="mt-2 text-sm rounded-sm border-solid border border-slate-200 divide-y">
          <div className="p-2 text-sm text-neutral-500">No data entered</div>
        </div>
      )}

      <div className="mt-2 text-sm rounded-sm border-solid border border-slate-200 divide-y">
        <div className="flex flex-row py-2">
          <div className="w-1/2 px-2 text-gray-500 text-sm pr-6">Feet</div>
          <div className="w-1/2 px-2 text-gray-800">
            {(value && value?.feet) || "Not entered"}
          </div>
        </div>
        <div className="flex flex-row py-2">
          <div className="w-1/2 px-2 text-gray-500 text-sm pr-6">Inches</div>
          <div className="w-1/2 px-2 text-gray-800">
            {(value && value?.inches) || "Not entered"}
          </div>
        </div>
      </div>
    </div>
  );
}
