import {
  CheckBadgeIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import * as React from "react";
import { FieldConfig } from "taskConfigs/Types";

export const snakeToTitle = (text) => {
  return (text[0].toUpperCase() + text.slice(1).toLowerCase())
    .replaceAll("-", " ")
    .replaceAll("_", " ");
};

export const logUserIn = (s_id, role, logo) => {
  localStorage.setItem("s_id", s_id.toString());
  localStorage.setItem("role", role.toString());
  localStorage.setItem("logo", logo.toString());
};

export const logUserOut = () => {
  localStorage.removeItem("s_id");
  localStorage.removeItem("role");
  localStorage.removeItem("logo");
};

export const getAuth = () => {
  const token = localStorage.getItem("s_id");
  const role = localStorage.getItem("role");
  const logo = localStorage.getItem("logo");
  return { token, role, logo };
};

export const getInitials = (name) => {
  if (!name) return "";
  return name
    .trim()
    .split(" ")
    .map((n) => (n[0] ? n[0].toUpperCase() : ""))
    .slice(0, 2)
    .join("");
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const getChildrenForTableField = (element) => {
  return element.children.type === React.Fragment
    ? element.children.props.children
    : React.Children.toArray(element.children);
};

export const removeTaskIdPrefix = (taskId) => {
  // Remove the "T_" prefix from the task ID. This is to make the URL nicer
  if (!taskId.startsWith("T_")) {
    return taskId;
  }
  return taskId.slice(2);
};

export const sanitizeData = (data, fields) => {
  // Remove any fields which are not visible to the user. We do not want to save
  // these fields to the database, to avoid mis-recording data.
  const result = Object.fromEntries(
    Object.entries(data).map(([key, value]) => {
      const fieldConfig = fields.find(
        (field) => field instanceof FieldConfig && field.name === key
      );

      if (
        fieldConfig instanceof FieldConfig &&
        fieldConfig.shouldBeVisible(data)
      ) {
        return [key, value];
      } else {
        return [key, null];
      }
    })
  );
  return result;
};
