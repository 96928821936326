import { DateInput } from "components/fields/DateField";
import { CountryDropdown, Dropdown } from "components/fields/DropdownField";
import { ModalTableField } from "components/fields/ModalTableField";
import { Radio } from "components/fields/RadioField";
import { TableField } from "components/fields/TableField";
import { TextInput } from "components/fields/TextInput";
import { PARENT_AND_INLAW_TYPE_OPTIONS, toDropdownoptions } from "lib/Enums";
import { FieldConfig, TaskConfig, SectionConfig } from "../Types";

export const portOfEntryPrimaryFamilyConfig = new TaskConfig({
  title: "Family",
  description:
    "Tell us about your immediate family and any current relationships.",
  sections: [
    new SectionConfig({
      title: "Relationship",
      body: [
        new FieldConfig({
          type: Radio,
          name: "has_current_partner",
          label:
            "Are you in a relationship with anyone who may become a spouse or common law partner (someone with whom you’ve lived for a year), or who will require immigration support in the future?",
        }),
      ],
    }),
    new SectionConfig({
      title: "Parents",
      body: [
        new FieldConfig({
          type: ModalTableField,
          name: "parents",
          label: "Parents",
          instructions:
            "Tell us about your parents’ citizenship. If you are married, tell us about the citizenship of your parents and in-laws.",
          itemName: "parent",
          tableColumns: ["full_name", "relationship"],
          fields: [
            new FieldConfig({
              type: TextInput,
              name: "full_name",
              label: "Full name",
              width: "w-full",
            }),
            new FieldConfig({
              type: TextInput,
              name: "full_name_in_native_script",
              label:
                "Full name in native script in unicode (if different than above)",
              width: "w-full",
            }),
            new FieldConfig({
              type: Dropdown,
              name: "relationship",
              label: "Relationship (to you)",
              options: toDropdownoptions(PARENT_AND_INLAW_TYPE_OPTIONS),
            }),
            new FieldConfig({
              type: DateInput,
              name: "date_of_birth",
              label: "Date of birth",
            }),
            new FieldConfig({
              type: TableField,
              name: "parent_citizenships",
              label: "Citizenship(s)",
              width: "w-full",
              children: <TextInput name="country" label="Country" />,
            }),
            new FieldConfig({
              type: TextInput,
              name: "city_of_birth",
              label: "City of birth",
            }),
            new FieldConfig({
              type: CountryDropdown,
              name: "country_of_birth",
              label: "Country of birth",
            }),
          ],
        }),
      ],
    }),
  ],
});
