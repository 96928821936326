import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { getApplicant } from "Api";
import LoadingSpinner from "components/LoadingSpinner";
import { logUserOut } from "lib/Utils";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Navigate, useMatch, useNavigate } from "react-router-dom";
import Signup from "./Signup";

export function Invite(props) {
  const { params } = useMatch("/invite/:opaque_id");
  const { opaque_id } = params;

  const {
    isLoading,
    error,
    data: user,
  } = useQuery(["applicant", opaque_id], () => getApplicant(opaque_id));

  useEffect(() => {
    logUserOut();
  }, []);

  if (isLoading) {
    return (
      <div className="fullscreen bg-gradient-to-r from-[#BDE2A0] to-[#6CB7EC]">
        <div className="relative card p-14 max-w-md mx-auto mt-36">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  if (error || user.is_registered) {
    return (
      <div className="fullscreen bg-gradient-to-r from-[#BDE2A0] to-[#6CB7EC]">
        <div className="relative card p-14 max-w-md mx-auto mt-36">
          <div className="flex flex-col items-center gap-8 text-center text-gray-600">
            <div className="bg-red-100 p-3 rounded-full">
              <ExclamationTriangleIcon className="h-10 w-10 text-red-600" />
            </div>
            <p className="text-base text-gray-700">
              Sorry! This invite link is invalid or expired.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return <Signup userEmail={user["email"]} />;
}
