import { Dialog } from "@headlessui/react";
import {
  ArrowDownCircleIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { Button } from "./Button";
import { Dropdown } from "./fields/DropdownField";
import { FileUpload } from "./fields/FileUpload";
import { CenterModal } from "./CenterModal";

export function AddDocumentModal({
  isOpen,
  setIsOpen,
  options,
  applicantId,
  section = null,
}) {
  const [documentRequestId, setDocumentRequestId] = useState();
  const [filename, setFilename] = useState("");

  // One of "DEFAULT", "SUCCESS", "ERROR"
  const [modalState, setModalState] = useState("DEFAULT");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    resetModal();
  }, [section]);

  const resetModal = () => {
    setDocumentRequestId(section?.document_request_id || "");
    setFilename("");
    setModalState("DEFAULT");
  };

  const handleModalClosed = () => {
    setTimeout(resetModal, 200);
    setIsOpen(false);
  };

  // Todo(awais): Remove this hack once we have better typing
  const lookupSectionName = (documentRequestId) => {
    return options.filter((option) => option.value == documentRequestId)[0]
      .name;
  };

  return (
    <>
      <CenterModal isOpen={isOpen} setIsOpen={handleModalClosed}>
        <div className="flex flex-col gap-6 items-center">
          {modalState === "SUCCESS" && (
            <>
              <CheckCircleIcon className="h-24 w-24 text-green-300" />
              <div>
                <h3 className="text-xl font-medium leading-6 text-gray-900">
                  Document upload successful
                </h3>
                <p className="text-gray-600 mt-1">
                  We've added your file{" "}
                  <span className="font-semibold">"{filename}"</span> to the{" "}
                  <span className="font-semibold">
                    {lookupSectionName(documentRequestId)}
                  </span>{" "}
                  section of your checklist.
                </p>
              </div>
              <div className="w-full">
                <Button onClick={() => resetModal()}>
                  Add another document
                </Button>
                <p
                  className="mt-4 text-gray-600 underline underline-offset-4 cursor-pointer"
                  onClick={() => handleModalClosed()}
                >
                  Return to checklist
                </p>
              </div>
            </>
          )}

          {modalState === "ERROR" && (
            <>
              <ExclamationCircleIcon className="h-24 w-24 text-red-400" />
              <div>
                <h3 className="text-xl font-medium leading-6 text-gray-900">
                  Document upload failed
                </h3>
                <p className="text-gray-600 mt-1">{errorMessage}</p>
              </div>
              <div className="w-full">
                <Button onClick={() => resetModal()}>
                  Add another document
                </Button>
                <p
                  className="mt-4 text-gray-600 underline underline-offset-4 cursor-pointer"
                  onClick={() => handleModalClosed()}
                >
                  Return to checklist
                </p>
              </div>
            </>
          )}

          {modalState === "DEFAULT" && (
            <>
              <ArrowDownCircleIcon className="h-24 w-24 text-yellow-300" />
              <div>
                <h3 className="text-xl font-medium leading-6 text-gray-900">
                  Add file
                </h3>
                {section && (
                  <p className="text-gray-600 mt-1">
                    Upload a document the{" "}
                    <span className="font-medium">
                      "{section.applicant_title}"
                    </span>{" "}
                    section of the checklist.
                  </p>
                )}

                {!section && (
                  <p className="text-gray-600 mt-1">
                    Upload a document and we’ll add it to the checklist.
                  </p>
                )}
              </div>
              <div className="w-full mb-2">
                {!section && (
                  <Dropdown
                    name={"document_type"}
                    onChange={(name, value) => {
                      setDocumentRequestId(value);
                    }}
                    onSave={() => {}}
                    className={"w-full"}
                    options={options}
                  />
                )}
                {documentRequestId && (
                  <FileUpload
                    name="add_document"
                    className="w-full"
                    documentRequestId={documentRequestId}
                    applicantId={applicantId}
                    onSuccess={(filename) => {
                      setFilename(filename);
                      setModalState("SUCCESS");
                    }}
                    onError={(message) => {
                      setErrorMessage(message);
                      setModalState("ERROR");
                    }}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </CenterModal>
    </>
  );
}
