import {
  getApplicant,
  getApplicantTasks,
  getTask,
  markAllTasksAsComplete,
} from "Api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import LoadingSpinner from "components/LoadingSpinner";
import { QuestionnaireSection } from "components/QuestionnaireSection";
import { useMatch } from "react-router-dom";
import { Sidebar, SidebarItem } from "components/ReviewSidebar";
import { Button } from "components/Button";
import { useEffect, useState } from "react";
import { QuestionnaireCompleteModal } from "components/QuestionnaireCompleteModal";
import ErrorBoundary from "components/ErrorBoundary";
import TASK_CONFIGS from "taskConfigs/TaskConfigs";
import { ApplicantContext } from "contexts/contexts";
import getTaskConfig from "taskConfigs/TaskConfigs";

export default function ReviewTask() {
  const queryClient = useQueryClient();

  const { params } = useMatch("/:applicant_id/tasks/review/:task_id");
  const { applicant_id } = params;
  const TASK_ID = `T_${params.task_id}`;

  const [taskConfig, setTaskConfig] = useState(null);
  const [formData, setFormData] = useState(null);

  const [isCompletedModalOpen, setIsCompletedModalOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { isLoading: isTaskLoading, data: task } = useQuery(
    ["task", applicant_id, TASK_ID],
    async () => await getTask(TASK_ID),
    {
      onSuccess: (data) => {
        setFormData(data.values);
        setTaskConfig(getTaskConfig(data.key));
      },
    }
  );

  const { isLoading: isListLoading, data: tasks } = useQuery(
    ["tasks", applicant_id],
    async () => await getApplicantTasks(applicant_id)
  );
  const { isLoading: isUserLoading, data: applicant } = useQuery(
    ["applicant", applicant_id],
    async () => await getApplicant(applicant_id)
  );

  const isLoading = isTaskLoading || isListLoading || isUserLoading;

  const getTaskStatus = (taskId) => {
    if (taskId === TASK_ID) {
      return "ACTIVE";
    }

    const itemIndex = tasks.findIndex((task) => task.task_id === taskId);
    const pageIndex = tasks.findIndex((task) => task.task_id === TASK_ID);
    if (itemIndex < pageIndex) {
      return "COMPLETE";
    }
  };

  const getNextTask = (key) => {
    const index = tasks.findIndex((task) => task.key === key);
    if (index === -1) {
      console.log(`Error: Unable to find task: ${key}}`);
      return null;
    }
    return tasks[index + 1] || null;
  };

  const isFinalTask = () => {
    return getNextTask(task.key) === null;
  };

  const markAllTasksAsCompleteMutation = useMutation(async () => {
    queryClient.invalidateQueries("tasks");
    return await markAllTasksAsComplete(applicant_id);
  });

  const handleSubmit = async () => {
    await markAllTasksAsCompleteMutation.mutate();
    setIsCompletedModalOpen(true);
  };

  return (
    <>
      <ApplicantContext.Provider value={applicant}>
        <QuestionnaireCompleteModal
          isOpen={isCompletedModalOpen}
          setIsOpen={setIsCompletedModalOpen}
        />

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {formData && taskConfig && (
              <div className="container mx-auto">
                <div className="flex flex-row">
                  <div className="w-1/4 mt-12 pr-8 hidden md:block">
                    <Sidebar
                      items={tasks.map((task) => {
                        const taskConfig = getTaskConfig(task.key);
                        return new SidebarItem({
                          id: task.key,
                          label: taskConfig.title,
                          status: getTaskStatus(task.key),
                          isActive: false,
                        });
                      })}
                    />
                  </div>
                  <div className="w-full md:w-3/4 flex mt-12 flex-col gap-4">
                    <div className="">
                      <h2 className="font-medium text-3xl">
                        Review {applicant.name}'s answers
                      </h2>
                      <p className="mt-1">
                        Please confirm that the information below is accurate.
                      </p>
                    </div>
                    <ErrorBoundary>
                      <QuestionnaireSection
                        task={task}
                        key={taskConfig.id}
                        taskConfig={taskConfig}
                        formData={formData}
                        type="review"
                        nextTask={getNextTask(task.key)}
                      />
                    </ErrorBoundary>
                  </div>
                </div>
                {isFinalTask() && (
                  <div className="w-full mt-4 flex justify-end">
                    <Button
                      style="primary"
                      onClick={handleSubmit}
                      fullWidth={false}
                    >
                      Submit to my lawyer
                    </Button>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </ApplicantContext.Provider>
    </>
  );
}
