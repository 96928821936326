import { DateInput } from "components/fields/DateField";
import { Dropdown } from "components/fields/DropdownField";
import { ModalTableField } from "components/fields/ModalTableField";
import { Radio } from "components/fields/RadioField";
import { TextArea } from "components/fields/TextArea";
import { TextInput } from "components/fields/TextInput";
import {
  ABBREVIATED_MARITAL_STATUS_OPTIONS,
  CHILDREN_TYPE_OPTIONS,
  toDropdownoptions,
  toRadioOptions,
} from "lib/Enums";
import { FieldConfig, TaskConfig, SectionConfig } from "../Types";

export const portOfEntryPrimaryInitialQuestionsConfig = new TaskConfig({
  title: "Initial questions",
  description: "",
  sections: [
    new SectionConfig({
      title: "Marital status",
      body: [
        new FieldConfig({
          type: Radio,
          name: "marital_status",
          label: "What is your current marital status?",
          instructions:
            "Note: A common-law relationship is a marriage-like relationship where you have lived together for at least one year.",
          options: toRadioOptions(ABBREVIATED_MARITAL_STATUS_OPTIONS),
        }),
      ],
    }),
    new SectionConfig({
      title: "Partner",
      body: [
        new FieldConfig({
          type: TextInput,
          name: "partner_full_name",
          label: (data) =>
            data.marital_status === "Common-law"
              ? "What is your common-law partner's full name?"
              : "What is your spouse's full name?",
          width: "w-96",
          shouldBeVisible: (data) =>
            data.marital_status === "Common-law" ||
            data.marital_status === "Married",
        }),
        new FieldConfig({
          type: Radio,
          name: "is_partner_accompanying",
          label: "Will they accompany you to Canada?",
          shouldBeVisible: (data) =>
            data.marital_status === "Common-law" ||
            data.marital_status === "Married",
        }),
        new FieldConfig({
          type: DateInput,
          name: "date_of_marriage",
          label: (data) =>
            data.marital_status === "Common-law"
              ? "On what date did your common-law relationship start?"
              : "On what date did your marriage start?",
          shouldBeVisible: (data) =>
            data.marital_status === "Married" ||
            data.marital_status === "Common-law",
        }),
        // new FieldConfig({
        //   type: Radio,
        //   name: "has_partner_been_refused",
        //   label: (data) =>
        //     data.marital_status === "Common-law"
        //       ? "Has your common-law partner ever been refused a Canadian visa or permit, denied entry to Canada or been ordered to leave the country?"
        //       : "Has your spouse ever been refused a Canadian visa or permit, denied entry to Canada or been ordered to leave the country?",
        //   shouldBeVisible: (data) =>
        //     data.marital_status === "Common-law" ||
        //     data.marital_status === "Married",
        // }),
        // new FieldConfig({
        //   type: TextArea,
        //   name: "partner_refusal_details",
        //   label: "Please provide details",
        //   width: "w-full",
        //   shouldBeVisible: (data) =>
        //     data.has_partner_been_refused === "Yes" &&
        //     (data.marital_status === "Married" ||
        //       data.marital_status === "Common-law"),
        // }),
      ],
    }),
    new SectionConfig({
      title: "Children",
      body: [
        new FieldConfig({
          type: Radio,
          name: "has_children",
          label: (data) => {
            if (data.marital_status === "Married") {
              return "Do you or your spouse have any children?";
            } else if (data.marital_status === "Common-law") {
              return "Do you or your common-law partner have any children?";
            } else {
              return "Do you have any children?";
            }
          },
          instructions: (data) => {
            if (data.marital_status === "Married") {
              return "Note: Answer 'Yes', if you and/or your spouse has a son or daughter, including adopted children or stepchildren, regardless of their age or place of residence.";
            } else if (data.marital_status === "Common-law") {
              return "Note: Answer 'Yes', if you and/or your partner has a son or daughter, including adopted children or stepchildren, regardless of their age or place of residence.";
            } else {
              return "Note: Answer 'Yes', if you have a son or daughter, including adopted children or stepchildren, regardless of their age or place of residence.";
            }
          },
        }),
        new FieldConfig({
          type: ModalTableField,
          name: "children",
          label: "Details of children",
          itemName: "child",
          shouldBeVisible: (data) => data.has_children === "Yes",
          tableColumns: ["full_name", "relation", "is_accompanying"],
          fields: [
            new FieldConfig({
              type: TextInput,
              name: "full_name",
              label: "Full name",
              width: "w-96",
            }),
            new FieldConfig({
              type: TextInput,
              name: "full_name_in_native_script",
              label:
                "Full name in native script in unicode (if different than above)",
              width: "w-full",
            }),
            new FieldConfig({
              type: Dropdown,
              name: "relation",
              label: "Relationship to you",
              options: toDropdownoptions(CHILDREN_TYPE_OPTIONS),
            }),
            new FieldConfig({
              type: Radio,
              name: "is_accompanying",
              label: "Will they accompany you to Canada?",
            }),
          ],
        }),
      ],
    }),
  ],
});
