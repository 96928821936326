import {
  FieldConfig,
  Heading,
  HorizontalRule as HRule,
  Text,
} from "taskConfigs/Types";
import { HorizontalRule } from "components/HorizontalRule";
import {
  AdmissibilityQuestion,
  AdmissibilityQuestionDisplay,
} from "components/fields/AdmissibilityQuestionField";
import * as React from "react";
import { TableField, TableFieldDisplay } from "components/fields/TableField";
import {
  ModalTableField,
  ModalTableFieldDisplay,
} from "components/fields/ModalTableField";
import { HeightField, HeightFieldDisplay } from "components/fields/HeightField";
import { DateInput, DateInputDisplay } from "components/fields/DateField";
import ErrorBoundary from "components/ErrorBoundary";

export const renderElement = (element, formData, updateField, onSave) => {
  const label =
    typeof element.label === "function"
      ? element.label(formData)
      : element.label;
  const instructions =
    typeof element.instructions === "function"
      ? element.instructions(formData)
      : element.instructions;

  if (element.shouldBeVisible(formData)) {
    if (element instanceof HRule) {
      return <HorizontalRule key={Math.random().toString()} />;
    }
    if (element instanceof Heading) {
      return (
        <h3 key={element.text} className="mt-4 -mb-4">
          {element.text}
        </h3>
      );
    }
    if (element instanceof Text) {
      return (
        <div key={element.text} className="text-sm">
          {element.text}
        </div>
      );
    }
    return (
      <div key={element.name} className="w-full">
        {React.createElement(
          element.type,
          {
            key: element.name,
            name: element.name,
            label: label,
            instructions: instructions,
            className: element.width,
            options: element.options,
            tableColumns: element.tableColumns,
            fields: element.fields,
            itemName: element.itemName,
            dateType: element.dateType,
            checkbox: element.checkbox,
            defaultValue: formData[element.name],
            onChange: updateField,
            onSave: onSave,
          },
          element.children
        )}
      </div>
    );
  }
  return <React.Fragment key={Math.random().toString()}></React.Fragment>;
};

const displayElementFromType = (element) => {
  switch (element.type) {
    case AdmissibilityQuestion:
      return AdmissibilityQuestionDisplay;
    case TableField:
      return TableFieldDisplay;
    case ModalTableField:
      return ModalTableFieldDisplay;
    case HeightField:
      return HeightFieldDisplay;
    case DateInput:
      return DateInputDisplay;
  }
  return null;
};

export const renderViewElement = (
  element,
  formData,
  includeNotAsked = true
) => {
  if (!formData) {
    throw new Error(`formData is required but not provided: ${formData}`);
  }

  const label =
    typeof element.label === "function"
      ? element.label(formData)
      : element.label;
  const instructions =
    typeof element.instructions === "function"
      ? element.instructions(formData)
      : element.instructions;

  const renderNullElement = (element) => {
    if (element instanceof FieldConfig && includeNotAsked) {
      return (
        <div key={element.name} className="px-6 flex flex-row py-3">
          <div className="w-1/2 text-gray-500 text-sm pr-6">{label}</div>
          <div className="w-1/2 text-gray-800 text-sm">
            {formData[element.name]?.toString() || (
              <span className="font-light text-neutral-400">Not asked</span>
            )}
          </div>
        </div>
      );
    }

    return <React.Fragment key={element.text} />;
  };

  if (element instanceof Heading) {
    return (
      <h3 key={element.text} className="pt-8 pb-2 px-6">
        {element.text}
      </h3>
    );
  }

  if (!element.shouldBeVisible(formData)) {
    return renderNullElement(element);
  }

  if (element instanceof FieldConfig) {
    let displayElement = displayElementFromType(element);

    if (displayElement === null) {
      return (
        <div key={element.name} className="px-6 flex flex-row py-3">
          <div className="w-1/2 text-gray-500 text-sm pr-6">{label}</div>
          <div className="w-1/2 text-gray-800 text-sm">
            {formData[element.name]?.toString() || (
              <span className="font-light text-neutral-400">Not entered</span>
            )}
          </div>
        </div>
      );
    }
    return (
      <ErrorBoundary>
        {React.createElement(displayElement, {
          key: element.name,
          element: element,
          label: label,
          instructions: instructions,
          value: formData[element.name],
        })}
      </ErrorBoundary>
    );
  }
};
