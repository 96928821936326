import { snakeToTitle } from "lib/Utils";

export function Pill({ title }) {
  const getColor = () => {
    if (title === undefined) {
      return "text-gray-600 bg-gray-100";
    }

    switch (title.toLowerCase().replaceAll("_", " ").replaceAll("-", " ")) {
      case "ready to file":
        return "text-green-600 bg-green-100";
      case "complete":
        return "text-green-600 bg-green-100";
      case "approved":
        return "text-green-600 bg-green-100";
      case "in review":
        return "text-orange-600 bg-orange-100";
      case "in progress":
        return "text-orange-600 bg-orange-100";
      case "missing documents":
        return "text-orange-600 bg-orange-100";
      case "not started":
        return "text-red-600 bg-red-100";
      case "rejected":
        return "text-red-600 bg-red-100";
      default:
        return "text-gray-600 bg-gray-100";
    }
  };

  return (
    <>
      {title && (
        <>
          <div
            className={`inline-block max-h-[32px] py-1 px-3 rounded-full font-medium whitespace-nowrap ${getColor()}`}
          >
            {snakeToTitle(title)}
          </div>
        </>
      )}
    </>
  );
}
