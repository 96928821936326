import { Dialog } from "@headlessui/react";
import { ArrowDownTrayIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { CenterModal } from "./CenterModal";
import { downloadFile, fetchDocument } from "Api";
import { useQuery } from "react-query";
import { DocumentIcon } from "@heroicons/react/24/outline";
import LoadingSpinner from "./LoadingSpinner";
import { Modal } from "./Modal";

export function ReviewDocumentModal({ isOpen, setIsOpen, section, document }) {
  const [objectUrl, setObjectUrl] = useState(null);

  const { isLoading, data } = useQuery(
    ["document", document.document_id],
    async () => {
      return await fetchDocument(document.document_id);
    },
    {
      onSuccess: (data) => {
        setObjectUrl(URL.createObjectURL(data));
      },
    }
  );

  return (
    <>
      {document && (
        <>
          <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <Dialog.Panel className="fixed transform overflow-hidden bg-white shadow-xl transition-all right-0 w-[800px] h-screen">
              <div className="flex flex-col h-full">
                <div className="bg-white p-6">
                  <div className="flex">
                    <div className="flex gap-2">
                      <DocumentIcon className="h-12 w-12 text-gray-400" />
                      <div className="text-left">
                        <div className=" text-gray-700">
                          {document.filename}
                        </div>
                        <p className="text-sm text-gray-400">
                          Section: {section.reviewer_title}
                        </p>
                        <p className="text-sm text-gray-400">
                          Uploaded on {document.when_created}
                        </p>
                      </div>
                    </div>

                    <div className="flex-grow" />
                    <ArrowDownTrayIcon
                      className="h-8 w-8 cursor-pointer text-gray-600 mr-4"
                      title="Download"
                      onClick={() => downloadFile(document)}
                    />
                    <XMarkIcon
                      className="h-8 w-8 cursor-pointer text-gray-600"
                      title="Close"
                      onClick={() => setIsOpen(false)}
                    />
                  </div>
                </div>
                <div className="grow bg-gray-200 p-6 overflow-y-scroll">
                  {isLoading ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      {document.mimetype === "application/pdf" && (
                        <object
                          data={objectUrl}
                          type="application/pdf"
                          className="w-full h-full"
                        >
                          <embed src={objectUrl} className="w-full h-full" />
                          <p>
                            This browser does not support PDFs. Please download
                            the PDF to view it:
                            <a href={objectUrl}>Download PDF</a>.
                          </p>
                        </object>
                      )}

                      {document.mimetype !== "application/pdf" && (
                        <div className="border border-black border-1 w-full">
                          <img src={objectUrl} className="w-full"></img>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Dialog.Panel>
          </Modal>
        </>
      )}
    </>
  );
}
