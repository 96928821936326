import { Popover } from "@headlessui/react";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/20/solid";
import { MinusCircleIcon } from "@heroicons/react/24/outline";
import {
  InformationCircleIcon,
  PaperClipIcon,
} from "@heroicons/react/24/solid";
import * as moment from "moment";
import {
  deleteDocumentRequest,
  downloadFile,
  getApplicantDocuments,
} from "Api";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Tooltip } from "react-tooltip";
import { ConfirmDeleteModal } from "./ConfirmDeleteModal";
import ErrorBoundary from "./ErrorBoundary";
import LoadingSpinner from "./LoadingSpinner";
import { RequestDocumentModal } from "./RequestDocumentModal";
import { ReviewDocumentModal } from "./ReviewDocumentModal";
import { Sidebar, SidebarItem } from "./Sidebar";

function DocumentSection({ applicantId, section }) {
  const queryClient = useQueryClient();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const [activeDocument, setActiveDocument] = useState(null);
  const [sectionToDelete, setSectionToDelete] = useState(null);

  const [isRequestDocumentModalOpen, setIsRequestDocumentModalOpen] =
    useState(false);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const EmptyTable = ({ text }) => {
    return (
      <div className="mt-2 mx-6 text-sm rounded-sm border-solid border border-slate-300 divide-y">
        <div className="flex flex-row py-2 text-sm">
          <div className="w-1/2 px-2 text-gray-400 pr-6">{text}</div>
          <div className="w-1/2 px-2 text-gray-400">{text}</div>
        </div>
      </div>
    );
  };

  const reviewDocument = (section, document) => {
    setActiveSection(section);
    setActiveDocument(document);
    setIsModalOpen(true);
  };

  const editDocumentRequest = (section) => {
    setActiveSection(section);
    setActiveDocument(null);
    setIsRequestDocumentModalOpen(true);
  };

  const deleteDocumentRequestMutation = useMutation(
    async ({ documentRequestId }) => {
      return await deleteDocumentRequest(documentRequestId);
    },
    {
      onSettled: () =>
        queryClient.invalidateQueries(["applicant_documents", applicantId]),
    }
  );

  const handleDeleteDocumentRequest = async (sectionToDelete) => {
    deleteDocumentRequestMutation.mutate({
      documentRequestId: sectionToDelete.document_request_id,
    });
  };

  const handleDeleteDocumentRequestClicked = (section) => {
    setSectionToDelete(section);
    setIsDeleteModalOpen(true);
  };

  const renderDocumentsTable = () => {
    if (section.status === "NOT_APPLICABLE") {
      return <EmptyTable text="Not applicable" />;
    } else if (section.documents.length === 0) {
      return <EmptyTable text="No files uploaded" />;
    }

    return (
      <>
        <div className="mt-2 mx-6 text-sm rounded-sm border-solid border border-slate-300 divide-y">
          {section.documents.map((document) => (
            <div
              key={document.document_id}
              className="flex items-center py-2 px-2"
            >
              <div className="flex flex-row items-center w-1/2 lg:w-7/12">
                <div>
                  <PaperClipIcon className="w-5 h-5 text-gray-600" />
                </div>
                <div className="ml-2 pr-6 whitespace-normal overflow-hidden">
                  {document.filename}
                </div>
              </div>
              <div className="grow" />
              <div className="text-gray-500 mr-4">
                <>
                  <Tooltip
                    id={`${document.document_id}_when_uploaded`}
                    className="max-w-xs z-20"
                  >
                    <div className="text-sm">
                      Uploaded at{" "}
                      {moment
                        .utc(document.when_created)
                        .local()
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </div>
                  </Tooltip>
                  <p
                    data-tooltip-id={`${document.document_id}_when_uploaded`}
                    className="cursor-pointer"
                  >
                    {moment.utc(document.when_created).fromNow()}
                  </p>
                </>
              </div>
              <div className="flex items-center divide-x divide-gray-500">
                <div
                  className="px-2 text-primary hover:text-primary-dark hover:underline cursor-pointer"
                  onClick={() => reviewDocument(section, document)}
                >
                  View
                </div>
                <div
                  className="px-2 text-primary hover:text-primary-dark hover:underline cursor-pointer"
                  onClick={() => downloadFile(document)}
                >
                  Download
                </div>
              </div>
            </div>
          ))}
          {section.deleted_documents.map((document) => (
            <div
              key={document.document_id}
              className="flex items-center py-2 px-2 text-gray-400"
            >
              <div className="flex flex-row items-center w-1/2 lg:w-7/12">
                <div>
                  <MinusCircleIcon className="w-5 h-5" />
                </div>
                <div className="ml-2 pr-6 line-through">
                  {document.filename}
                </div>
              </div>
              <div className="grow" />
              <div className="text-gray-400 mr-4">
                <>
                  <Tooltip
                    id={`${document.document_id}_when_uploaded`}
                    className="max-w-xs z-20"
                  >
                    <div className="text-sm">
                      Uploaded at{" "}
                      {moment
                        .utc(document.when_created)
                        .local()
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </div>
                  </Tooltip>
                  <p
                    data-tooltip-id={`${document.document_id}_when_uploaded`}
                    className="cursor-pointer line-through"
                  >
                    {moment.utc(document.when_created).fromNow()}
                  </p>
                </>
              </div>
              <div className="flex items-center divide-x divide-gray-500">
                <div
                  className="px-2 hover:underline cursor-pointer"
                  onClick={() => reviewDocument(section, document)}
                >
                  View
                </div>
                <div
                  className="px-2 hover:underline cursor-pointer"
                  onClick={() => downloadFile(document)}
                >
                  Download
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <>
      {activeDocument && (
        <ReviewDocumentModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          document={activeDocument}
          section={activeSection}
        />
      )}
      {activeSection && (
        <RequestDocumentModal
          isOpen={isRequestDocumentModalOpen}
          setIsOpen={setIsRequestDocumentModalOpen}
          section={activeSection}
        />
      )}
      {sectionToDelete && (
        <ConfirmDeleteModal
          isOpen={isDeleteModalOpen}
          setIsOpen={setIsDeleteModalOpen}
          onConfirm={async () =>
            await handleDeleteDocumentRequest(sectionToDelete)
          }
        >
          <p>
            Are you sure you want to delete{" "}
            <span className="font-medium">
              {sectionToDelete.reviewer_title}
            </span>
            ?
          </p>
          <p className="mt-4">
            This will also delete any documents your client has uploaded in this
            section.
          </p>
          <p className="mt-4">You won't be able to undo this action.</p>
        </ConfirmDeleteModal>
      )}
      <div className="py-6 border card mt-4">
        <div className="px-6 flex justify-between">
          <div className="flex flex-row items-center">
            <h2 id={section.document_request_id} className="scroll-mt-36">
              {section.reviewer_title}
            </h2>
            {section.description && (
              <>
                <Tooltip
                  anchorId={`help_text_${section.document_request_id}`}
                  content={section.description}
                  className="max-w-xs z-20"
                />
                <InformationCircleIcon
                  id={`help_text_${section.document_request_id}`}
                  className="w-6 h-6 ml-2 text-slate-400 cursor-pointer noselect outline-none"
                />
              </>
            )}
          </div>
        </div>
        <hr className="my-6 w-full h-[1px] bg-gray-300" />
        <div className="-mt-4 px-6 flex justify-end gap-6 text-sm text-primary">
          <div
            className="flex items-center align-baseline gap-1 cursor-pointer hover:text-primary-dark"
            onClick={() => {
              editDocumentRequest(section);
            }}
          >
            <PencilSquareIcon className="h-5 w-5" />
            <div>Edit</div>
          </div>
          <div
            className="flex items-center align-baseline gap-1 cursor-pointer hover:text-primary-dark"
            onClick={() => {
              handleDeleteDocumentRequestClicked(section);
            }}
          >
            <TrashIcon className="h-5 w-5" />
            <div>Delete</div>
          </div>
        </div>
        <div className="px-6">
          <p className="text-gray-500">Files</p>
        </div>

        {renderDocumentsTable()}
      </div>
    </>
  );
}

export default function Documents({ applicantId }) {
  const { isLoading, data: sections } = useQuery(
    ["applicant_documents", applicantId],
    () => getApplicantDocuments(applicantId)
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="container mx-auto">
      <div className="flex flex-row">
        <div className="w-1/4 mt-4 pr-8 hidden md:block">
          <Sidebar
            items={sections.map((section) => {
              return new SidebarItem({
                id: section.document_request_id,
                label: section.reviewer_title,
                status: section.status,
                isActive: false,
              });
            })}
          />
        </div>
        <div className="w-full md:w-3/4 flex flex-col gap-4">
          {sections.map((section) => {
            return (
              <ErrorBoundary key={section.document_request_id}>
                <DocumentSection applicantId={applicantId} section={section} />
              </ErrorBoundary>
            );
          })}
        </div>
      </div>
    </div>
  );
}
