import { useEffect, useState } from "react";

function SmallSpinner() {
  return (
    <div className="inline-block text-center h-6 w-6 -mb-2" role="status">
      <svg
        aria-hidden="true"
        className="w-8 h-8 text-gray-200 animate-spin dark:text-neutral-700 fill-white"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="https://www.w3.org/2000/svg"
      >
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  );
}

export function Button({
  onClick,
  isDisabled = false,
  style = "default",
  fullWidth = true,
  children,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (event) => {
    setIsLoading(true);
    await onClick(event);
    setIsLoading(false);
  };

  const getColorStyles = () => {
    switch (style) {
      case "light":
        return "text-primary-dark bg-primary-light hover:bg-primary-medium";
      case "outline":
        return "text-gray-600 border-gray-400 hover:border-primary hover:text-primary";
      case "primary-outline":
        return "text-primary border-primary hover:border-primary-dark hover:text-primary-dark";
      case "danger":
        return "text-white font-medium bg-red-500 hover:bg-red-600";
      case "grey":
        return "bg-neutral-300 hover:bg-neutral-400";
      default:
        return "text-white bg-primary hover:bg-primary-dark";
    }
  };

  const getWidth = () => {
    if (fullWidth) {
      return "w-full mt-6";
    }
    return "";
  };

  return (
    <>
      <button
        className={`border block disabled:bg-neutral-400 rounded-md px-4 py-2 h-10 ${getColorStyles()} ${getWidth()}`}
        disabled={isDisabled}
        onClick={handleClick}
      >
        <div aria-hidden={true} className="invisible  h-0">
          <div className="flex flex-row items-center justify-center gap-2">
            {children}
          </div>
        </div>
        {isLoading ? (
          <SmallSpinner />
        ) : (
          <div className="flex flex-row items-center justify-center gap-2">
            {children}
          </div>
        )}
      </button>
    </>
  );
}
