import { Button } from "./Button";
import { CenterModal } from "./CenterModal";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ApplicantContext } from "contexts/contexts";

export function QuestionnaireCompleteModal({ isOpen, setIsOpen }) {
  const applicant = useContext(ApplicantContext);
  const navigate = useNavigate();
  return (
    <>
      <CenterModal isOpen={isOpen} setIsOpen={setIsOpen}>
        {applicant && (
          <div className="flex flex-col items-center gap-4">
            <div className="">
              <img
                src={`${process.env.PUBLIC_URL}/static/clipboard_check.png`}
                className="w-36 h-36 noselect"
                draggable={false}
              />
            </div>
            <div>
              <h3 className="text-xl font-medium text-gray-900">
                Questionnaire complete
              </h3>
              <p>You're making great progress!</p>
            </div>
            <Button
              style="primary"
              onClick={() => navigate(`/${applicant.id}/documents`)}
            >
              Continue to document checklist
            </Button>
          </div>
        )}
      </CenterModal>
    </>
  );
}
