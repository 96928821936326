import {
  CheckCircleIcon,
  EllipsisHorizontalCircleIcon,
  MinusCircleIcon,
} from "@heroicons/react/24/outline";
import { getApplicant, getApplicantTasks } from "Api";
import { ApplicantIcon } from "components/ApplicantIcon";
import { BreadcrumbItem, Breadcrumbs } from "components/Breadcrumbs";
import { Button } from "components/Button";
import ErrorBoundary from "components/ErrorBoundary";
import { Header } from "components/Header";
import LoadingSpinner from "components/LoadingSpinner";
import { removeTaskIdPrefix } from "lib/Utils";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useMatch, useNavigate } from "react-router-dom";
import getTaskConfig from "taskConfigs/TaskConfigs";
import TASK_CONFIGS from "taskConfigs/TaskConfigs";

function TaskSnippet({ status, title, description, navigateTo = "" }) {
  const navigate = useNavigate();

  const sectionIcon = () => {
    if (status === "COMPLETE") {
      return <CheckCircleIcon className="w-6 h-6 text-green-500" />;
    }
    if (status === "IN_PROGRESS") {
      return <EllipsisHorizontalCircleIcon className="w-6 h-6 text-primary" />;
    }
    return <MinusCircleIcon className="w-6 h-6 text-gray-500" />;
  };

  const getButtonInfo = () => {
    if (status === "COMPLETE") {
      return { label: "View", style: "primary-outline" };
    }
    if (status === "IN_PROGRESS") {
      return { label: "Edit", style: "primary" };
    }
    return { label: "Start", style: "primary" };
  };

  return (
    <div className="border card w-full px-8 pt-6 pb-2">
      <ErrorBoundary>
        <div className="flex flex-row gap-4 justify-between">
          <div>
            <div className="flex flex-row gap-2 items-center">
              {sectionIcon()}
              <h3>{title}</h3>
            </div>
            <p className="text-gray-600 mt-4">{description}</p>
          </div>
        </div>
        <hr className="my-2 w-full h-[1px] bg-neutral-400" />
        <div className="flex flex-row justify-end">
          <Button
            style={getButtonInfo().style}
            fullWidth={false}
            onClick={() => navigate("" + navigateTo)}
          >
            {getButtonInfo().label}
          </Button>
        </div>
      </ErrorBoundary>
    </div>
  );
}

export default function TaskList({}) {
  const { params } = useMatch("/:applicant_id/tasks");
  const { applicant_id } = params;

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { isLoading: isTasksLoading, data: tasks } = useQuery(
    ["tasks", applicant_id],
    async () => await getApplicantTasks(applicant_id)
  );
  const { isLoading: isApplicantLoading, data: applicant } = useQuery(
    ["applicant", applicant_id],
    async () => await getApplicant(applicant_id)
  );

  const areAllTasksComplete = () => {
    return tasks.every((task) => task.status === "COMPLETE");
  };

  const isLoading = isTasksLoading || isApplicantLoading;

  if (isLoading) {
    return (
      <div className="container mx-auto max-w-3xl">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <Header>
        <Breadcrumbs
          items={[
            new BreadcrumbItem({
              title: `${applicant.primary_applicant_name}'s application`,
              url: "/",
            }),
          ]}
        />
        <div className="mt-6 flex flex-row gap-4 items-center">
          <ApplicantIcon
            applicant={applicant}
            status={applicant.questionnaire_status}
          />

          <h1 className="font-medium text-3xl">
            {applicant.name}'s questionnaire
          </h1>
        </div>
        <p className="mt-1 ml-16">
          {areAllTasksComplete()
            ? "This questionnaire is complete. You can still view your responses below."
            : "Complete the following questionnaire sections so that we can prepare your application."}
        </p>
      </Header>
      <div className="flex flex-col gap-8 mx-auto">
        {tasks.map((task) => {
          const taskConfig = getTaskConfig(task.key);
          return (
            <TaskSnippet
              key={`task_${task.task_id}`}
              status={task.status}
              title={taskConfig.title}
              description={taskConfig.description}
              navigateTo={
                areAllTasksComplete()
                  ? `/${applicant_id}/tasks/view/${removeTaskIdPrefix(
                      task.task_id
                    )}`
                  : `/${applicant_id}/tasks/${removeTaskIdPrefix(task.task_id)}`
              }
            />
          );
        })}
      </div>

      {!areAllTasksComplete() && (
        <div className="mt-12 mx-auto">
          <h2>Final review</h2>
          <p className="mt-1 mb-8">
            Review your answers before submitting them to your lawyer.
          </p>

          <TaskSnippet
            key={`task_review`}
            status={"NOT_STARTED"}
            title={"Review"}
            description={
              "Complete the sections above before beginning your review."
            }
            navigateTo={`/${applicant_id}/tasks/review/${removeTaskIdPrefix(
              tasks[0].task_id
            )}`}
          />
        </div>
      )}
    </>
  );
}
