import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import ErrorBoundary from "./ErrorBoundary";

export function CenterModal({
  isOpen,
  setIsOpen,
  width = "w-full max-w-md",
  ...props
}) {
  return (
    <ErrorBoundary>
      <Transition.Root show={isOpen} as={Fragment} unmount={true}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-start justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={`relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all mx-4 mt-24 mb-auto ${width}`}
                >
                  <div className="w-full bg-white px-8 py-8">
                    <div className="w-full flex justify-end">
                      <XMarkIcon
                        className="h-8 w-8 cursor-pointer text-gray-600"
                        title="Close"
                        onClick={() => setIsOpen(false)}
                      />
                    </div>
                    {props.children}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
            <div className="invisible h-24"></div>
          </div>
        </Dialog>
      </Transition.Root>
    </ErrorBoundary>
  );
}
