import { Radio } from "components/fields/RadioField";
import { TextInput } from "components/fields/TextInput";
import { FieldConfig, TaskConfig, SectionConfig } from "../Types";
import {
  ENGLISH_OR_FRENCH_OPTIONS,
  LANGUAGES_AVAILABLE_OPTIONS,
  toRadioOptions,
} from "lib/Enums";

export const workPermitPrimaryLanguagesConfig = new TaskConfig({
  title: "Languages",
  description: "Tell us about the languages you speak.",
  sections: [
    new SectionConfig({
      title: "Languages",
      body: [
        new FieldConfig({
          type: TextInput,
          name: "native_language",
          label: "What is your native language / mother tongue?",
        }),
        new FieldConfig({
          type: Radio,
          name: "languages_available",
          label: "Are you able to communicate in English and/or French?",
          options: toRadioOptions(LANGUAGES_AVAILABLE_OPTIONS),
        }),
        new FieldConfig({
          type: Radio,
          name: "language_preference",
          label:
            "Between French and English, which language are you most comfortable using?",
          shouldBeVisible: (data) => data.languages_available === "Both",
          options: toRadioOptions(ENGLISH_OR_FRENCH_OPTIONS),
        }),
        new FieldConfig({
          type: Radio,
          name: "has_language_test",
          label:
            "Have you ever taken the CELPIP, IELTS, TEF or TCF Canada exams to assess your proficiency in English or French?",
        }),
      ],
    }),
  ],
});
