import { CheckBadgeIcon, DocumentTextIcon } from "@heroicons/react/24/outline";
import { PaperClipIcon } from "@heroicons/react/24/solid";
import {
  deleteDocument,
  markAllDocumentsAsComplete,
  downloadFile,
  getApplicantDocuments,
  getApplicant,
} from "Api";
import { AddDocumentModal } from "components/AddDocumentModal";
import { ApplicantIcon } from "components/ApplicantIcon";
import { BreadcrumbItem, Breadcrumbs } from "components/Breadcrumbs";
import { Button } from "components/Button";
import { ConfirmDeleteModal } from "components/ConfirmDeleteModal";
import { DocumentsCompleteModal } from "components/DocumentsCompleteModal";
import { Header } from "components/Header";
import LoadingSpinner from "components/LoadingSpinner";
import { ViewDocumentModal } from "components/ViewDocumentModal";
import * as React from "react";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useMatch } from "react-router-dom";

export function DocumentChecklist() {
  const queryClient = useQueryClient();
  const { params } = useMatch("/:applicant_id/documents");
  const { applicant_id } = params;

  const [isCompletedModalOpen, setIsCompletedModalOpen] = useState(false);

  const [addToSection, setAddToSection] = useState();
  const [isAddDocModalOpen, setIsAddDocModalOpen] = useState(false);

  const [activeSection, setActiveSection] = useState();
  const [activeDocument, setActiveDocument] = useState();
  const [isDocModalOpen, setIsDocModalOpen] = useState(false);

  const [docToDelete, setDocToDelete] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { isLoading: isListLoading, data: sections } = useQuery(
    ["document_checklist", applicant_id],
    async () => await getApplicantDocuments(applicant_id)
  );

  const { isLoading: isUserLoading, data: applicant } = useQuery(
    ["applicant", applicant_id],
    async () => await getApplicant(applicant_id)
  );

  const isLoading = isListLoading || isUserLoading;

  const getOptions = () => {
    return sections.map((section) => {
      return {
        name: section.applicant_title,
        value: section.document_request_id,
      };
    });
  };

  const addDocumentToSection = (section) => {
    setAddToSection(section);
    setIsAddDocModalOpen(true);
  };

  const viewDocument = (section, document) => {
    setActiveSection(section);
    setActiveDocument(document);
    setIsDocModalOpen(true);
  };

  const deleteDocumentMutation = useMutation(
    async (variables) => {
      return await deleteDocument(variables.documentId);
    },
    {
      onSettled: () =>
        queryClient.invalidateQueries(["document_checklist", applicant_id]),
    }
  );

  const handleDeleteDocument = async (document) => {
    deleteDocumentMutation.mutate({ documentId: document.document_id });
  };

  const handleDeleteClicked = (document) => {
    setDocToDelete(document);
    setIsDeleteModalOpen(true);
  };

  const handleSubmitMutation = useMutation(
    async () => {
      return await markAllDocumentsAsComplete(applicant_id);
    },
    {
      onSettled: () =>
        queryClient.invalidateQueries(["document_checklist", applicant_id]),
    }
  );

  const handleSubmit = async () => {
    handleSubmitMutation.mutate();
    setIsCompletedModalOpen(true);
  };

  const areAllSectionsComplete = () => {
    return sections.every((section) => section.status === "COMPLETE");
  };

  return (
    <>
      <DocumentsCompleteModal
        isOpen={isCompletedModalOpen}
        setIsOpen={setIsCompletedModalOpen}
        applicantId={applicant_id}
      />
      {sections && (
        <AddDocumentModal
          isOpen={isAddDocModalOpen}
          setIsOpen={setIsAddDocModalOpen}
          applicantId={applicant_id}
          options={getOptions()}
          section={addToSection}
        />
      )}
      {activeDocument && activeSection && (
        <ViewDocumentModal
          isOpen={isDocModalOpen}
          setIsOpen={setIsDocModalOpen}
          document={activeDocument}
          section={activeSection}
        />
      )}
      {docToDelete && (
        <ConfirmDeleteModal
          isOpen={isDeleteModalOpen}
          setIsOpen={setIsDeleteModalOpen}
          onConfirm={async () => {
            await handleDeleteDocument(docToDelete);
          }}
        >
          <p>
            Are you sure you want to delete{" "}
            <span className="font-medium">{docToDelete.filename}</span>?
          </p>
          <p className="text-sm text-gray-600">
            You won't be able to undo this action. Your lawyer will still be
            able to see the document. Please contact support if you would like
            to permanently delete this document.
          </p>
        </ConfirmDeleteModal>
      )}

      <div className="flex flex-col gap-4 mx-auto">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Header>
              {applicant && (
                <>
                  <Breadcrumbs
                    items={[
                      new BreadcrumbItem({
                        title: `${applicant.primary_applicant_name}'s application`,
                        url: "/",
                      }),
                    ]}
                  />
                  <div className="mt-6 flex flex-row gap-4 items-center">
                    <ApplicantIcon
                      applicant={applicant}
                      status={applicant.documents_status}
                    />

                    <h1 className="font-medium text-3xl">
                      {applicant.name}'s documents
                    </h1>
                  </div>
                  {areAllSectionsComplete() && (
                    <p className="mt-1 ml-16">
                      You have completed the document checklist. You can still
                      view your documents below.
                    </p>
                  )}
                </>
              )}
            </Header>

            {sections &&
              sections.map((section) => (
                <React.Fragment key={section.document_request_id}>
                  <div className="flex flex-row">
                    <div className="w-1/3 pr-10">
                      <div className="flex gap-2 items-start">
                        <div className="mt-1">
                          <DocumentTextIcon className="w-6 h-6 text-sky-600" />
                        </div>
                        <h3 className="text-gray-700">
                          {section.applicant_title}
                        </h3>
                      </div>

                      <div className="mt-1 ml-8 text-gray-600 text-sm whitespace-pre-wrap">
                        {section.description}
                      </div>
                    </div>
                    <div className="w-2/3">
                      <div className="border card w-full">
                        <div className="p-6">
                          <h3 className="text-base text-gray-600">Files</h3>
                          <div className="text-gray-600 text-sm rounded-md border-solid border border-slate-300 overflow-hidden flex flex-col mt-4 divide-y">
                            {(!section.documents ||
                              section.documents.length === 0) && (
                              <div className="flex p-2">No files uploaded</div>
                            )}

                            {section.documents.map((document) => (
                              <div
                                key={document.document_id}
                                className="flex p-2"
                              >
                                <div className="flex flex-row items-center w-7/12 grow">
                                  <div className="h-5 w-5">
                                    <PaperClipIcon className="w-5 h-5 text-gray-600" />
                                  </div>
                                  <div className="ml-2 pr-6 truncate">
                                    {document.filename}
                                  </div>
                                </div>
                                <div className="flex flex-row items-center gap-2">
                                  <a
                                    className="text-blue-700 hover:underline cursor-pointer"
                                    onClick={() =>
                                      viewDocument(section, document)
                                    }
                                  >
                                    View
                                  </a>
                                  <span className="text-gray-400">|</span>
                                  <a
                                    className=" text-blue-700 hover:underline cursor-pointer"
                                    onClick={() => downloadFile(document)}
                                  >
                                    Download
                                  </a>
                                  {!areAllSectionsComplete() && (
                                    <>
                                      <span className="text-gray-400">|</span>
                                      <a
                                        className=" text-blue-700 hover:underline cursor-pointer"
                                        onClick={() =>
                                          handleDeleteClicked(document)
                                        }
                                      >
                                        Delete
                                      </a>
                                    </>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        {!areAllSectionsComplete() && (
                          <div className="text-sm rounded-b-md bg-slate-50 flex justify-end px-6 py-2">
                            <Button
                              onClick={() => addDocumentToSection(section)}
                              fullWidth={false}
                            >
                              Add file
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr className="mt-20 mb-4 w-full h-[2px] bg-gray-300" />
                </React.Fragment>
              ))}

            {!areAllSectionsComplete() && applicant && (
              <div className="flex flex-col items-end gap-2">
                <Button onClick={handleSubmit} fullWidth={false}>
                  Submit to my lawyer
                </Button>
                <p className="text-sm text-gray-600">
                  Click here once you have uploaded all documents for{" "}
                  {applicant.name}
                </p>
              </div>
            )}

            {areAllSectionsComplete() && (
              <div className="flex flex-col items-center">
                <Button
                  onClick={() => addDocumentToSection(null)}
                  fullWidth={false}
                >
                  Add an additional file
                </Button>
                <p className="text-sm mt-2 text-gray-600">
                  We will notify your lawyer about the new file.
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
