import { getAuth, logUserOut } from "lib/Utils";
import { toast } from "react-toastify";

export function getUrlRoot() {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:5000/";
  } else if (process.env.NODE_ENV === "production") {
    return "https://app.pathimmigration.ca/";
  }
}

export function getApiUrlRoot() {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:5000/api/";
  } else if (process.env.NODE_ENV === "production") {
    return "https://app.pathimmigration.ca/api/";
  }
}

class UserFacingError extends Error {
  constructor(code, message) {
    super(code + ": " + message);
    this.name = "UserFacingError";
    this.code = code || "unknown";
    this.message = message;
  }
}

const handleErrors = async (response) => {
  if (response.status === 401) {
    logUserOut();
    window.location.reload();
    return;
  }

  if (response.status === 403) {
    const result = await response.json();
    throw new UserFacingError(result.error_code, result.message);
  }

  if (!response.ok) {
    const result = await response.json();
    if (result.message) {
      toast.error(result.message);
    }
    throw new UserFacingError(result.error_code, result.message);
  }
};

async function makeGetRequest(endpoint) {
  const { token, role } = getAuth();
  const response = await fetch(`${getApiUrlRoot()}${endpoint}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  await handleErrors(response);
  return response.json();
}

async function makeDeleteRequest(endpoint) {
  const { token, role } = getAuth();
  const response = await fetch(`${getApiUrlRoot()}${endpoint}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  await handleErrors(response);
  return response.json();
}

async function makePostRequest(endpoint, body) {
  const { token, role } = getAuth();
  const response = await fetch(`${getApiUrlRoot()}${endpoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  await handleErrors(response);
  return response.json();
}

export async function uploadFile(documentRequestId, file) {
  const { token, role } = getAuth();
  const formData = new FormData();
  formData.append("file", file);

  return fetch(`${getApiUrlRoot()}documents/${documentRequestId}/upload`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  }).then((response) => {
    if (response.ok) {
      return Response;
    }
    if (response.status === 413) {
      throw new Error(
        "Sorry, we were unable to upload your file because it is too large."
      );
    }
    throw new Error("Sorry, we were unable to upload your file.");
  });
}

export async function fetchDocument(documentId) {
  const { token, role } = getAuth();
  const response = await fetch(`${getApiUrlRoot()}documents/${documentId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.blob();
}

export async function downloadFile(doc) {
  const data = await fetchDocument(doc.document_id);
  const a = document.createElement("a");
  a.href = window.URL.createObjectURL(data);
  a.download = doc.filename;
  a.click();
}

export async function getMe() {
  return makeGetRequest("me");
}

export async function getApplication(applicationId) {
  return makeGetRequest(`applications/${applicationId} `);
}

export async function getApplications() {
  return makeGetRequest("applications");
}

export async function getApplicants() {
  return makeGetRequest("applicants");
}

export async function saveInitialQuestions(applicationId, data) {
  return makePostRequest(`applications/${applicationId}`, data);
}

export async function getApplicant(applicantId) {
  return makeGetRequest(`applicants/${applicantId}`);
}

export async function getApplicantTasks(applicantId) {
  return makeGetRequest(`applicants/${applicantId}/tasks`);
}

export async function getTask(taskId) {
  return makeGetRequest(`tasks/${taskId}`);
}

export async function saveTask(taskId, data) {
  return makePostRequest(`tasks/${taskId}`, data);
}

export async function markAllTasksAsComplete(applicantId) {
  return makePostRequest(`applicants/${applicantId}/tasks/complete`, {});
}

export async function getApplicantDocuments(applicantId) {
  return makeGetRequest(`applicants/${applicantId}/documents`);
}

export async function markAllDocumentsAsComplete(applicantId) {
  return makePostRequest(`applicants/${applicantId}/documents/complete`, {});
}

export async function getExportProfile(applicantId) {
  return makeGetRequest(`applicants/${applicantId}/export`);
}

export async function postInviteLink(name, email, application_kind) {
  return makePostRequest("invite", { name, email, application_kind });
}

export async function login(email, password, has_agreed_to_terms) {
  return makePostRequest(`login`, { email, password, has_agreed_to_terms });
}

export async function logout(s_id) {
  return makePostRequest(`logout`, { s_id });
}

export async function signup(email, password, hasAgreedToTerms) {
  return makePostRequest(`signup`, {
    email,
    password,
    has_agreed_to_terms: hasAgreedToTerms,
  });
}

export async function resetPasswordRequest(email) {
  return makePostRequest(`reset-password-request`, { email });
}

export async function resetPassword(token, password) {
  return makePostRequest(`reset-password`, { token, password });
}

export async function lookupPasswordToken(token) {
  return makeGetRequest(`reset-password-token/${token}`);
}

export async function updateDocumentStatus(documentId, status) {
  return makePostRequest(`documents/${documentId}/status`, { status });
}

export async function deleteDocument(documentId) {
  return makeDeleteRequest(`documents/${documentId}`);
}

export async function requestDocument(applicantId, title, description) {
  return makePostRequest(`applicants/${applicantId}/document_requests`, {
    title,
    description,
  });
}

export async function updateDocumentRequest(
  documentRequestId,
  newTitle,
  newDescription
) {
  return makePostRequest(`document_requests/${documentRequestId}`, {
    title: newTitle,
    description: newDescription,
  });
}

export async function deleteDocumentRequest(documentRequestId) {
  return makeDeleteRequest(`document_requests/${documentRequestId}`);
}
